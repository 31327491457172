import React, { useState } from "react";
import Welcome from "../../assets/welcome.png";
import AppStore from "../../assets/logos/app-store.png";
import PlayStore from "../../assets/logos/google-play.png";
import Button from "../../components/Button";
import Input from "react-phone-number-input/input";
import { Auth } from "aws-amplify";
import Modal from "../../components/Modal";
import LoadingOverlay from "react-loading-overlay";
import InputBox from '../../components/InputBox';
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./PromoPage.scss";

const PromoPage = ({ history }) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  });
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [eye, setEye] = useState("password");
  const [code, setCode] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      state.firstName !== "" ||
      state.lastName !== "" ||
      state.email !== "" ||
      state.password !== "" ||
      state.phone !== ""
    ) {
      setLoader(true);
      Auth.signUp({
        username: state.email,
        password: state.password,
        attributes: {
          email: state.email,
          given_name: state.firstName,
          family_name: state.lastName,
          phone_number: state.phone,
        },
      })
        .then((response) => {
          console.log("Signup response ", response);
          setModal(true);
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          toast.error(err.message);
        });
    } else {
      toast.error("Please fill in all the details.");
    }
  };

  const handlePhone = (e) => {
    setState({ ...state, phone: e });
  };
  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };
  const confirmSignUpCode = (e) => {
    e.preventDefault();
    if (code !== "") {
      setLoader(true);
      Auth.confirmSignUp(state.email, code)
        .then((response) => {
          console.log("confirm code", response);
          setModal(false);
          setLoader(false);
          history.push("/login", { success: true });
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
          toast.error(err.message);
        });
    } else {
      toast.error("Please type the code.");
    }
  };

  const onEyeClick = () => {
    if (eye === "password") {
      setEye("text");
    } else {
      setEye("password");
    }
  };
  
  return (
    <div className="promo">
      <div className="search-flight">
        <div className="search-img">
          <div className="left-section-promo">
            <img className="logo" src={Welcome} alt="Airbook one logo" />
            <h5>
              Sign up for a free account with AIRBOOK ONE to receive $1,500 in
              AB1 flight rewards on your first flight and another $1,500 on
              future flights.
            </h5>
            <h5>
              *Registrant must sign up for a member account to receive the promotion.  One promotion per person will be honored. The First Flight's Trip cost must be at least $20,000 USD. This offer may be discontinued at any time. Other rules may apply. Please contact {" "}<a target="_blank" href="/concierge">Flight Concierge</a> for further details. See{" "}
              <a target="_blank" href="/terms-of-sale">
                AB1 Terms & Conditions.
              </a>
            </h5>
            <h4>Download the AIRBOOK ONE App:</h4>
            <div className="download">
              <a
                className="app-img-link"
                href="https://apps.apple.com/us/app/airbook-one/id1553278305"
              >
                <img
                  className="app-img button-1"
                  src={AppStore}
                  alt="app store"
                />
              </a>
              <a
                className="app-img-link"
                href="https://play.google.com/store/apps/details?id=com.airbookone"
              >
                <img
                  className="app-img button-2"
                  src={PlayStore}
                  alt="google play store"
                />
              </a>
            </div>
          </div>
          <div className="promo-signup-form">
            <h2>Sign Up</h2>
            <form onSubmit={onSubmit}>
              <input
                className="full-width-container"
                type="text"
                required
                placeholder="First Name"
                value={state.firstName}
                onChange={handleChange("firstName")}
              />
              <input
                className="full-width-container"
                type="text"
                required
                placeholder="Last Name"
                value={state.lastName}
                onChange={handleChange("lastName")}
              />
              <Input
                required
                defaultCountry="US"
                className="full-width-container"
                placeholder="Phone Number"
                value={state.phone}
                onChange={handlePhone}
              />
              <input
                className="full-width-container"
                type="email"
                required
                placeholder="Email"
                value={state.email}
                onChange={handleChange("email")}
              />
              <div className="password-container">
                <input
                  className="full-width-container"
                  type={eye}
                  required
                  placeholder="Password"
                  value={state.password}
                  onChange={handleChange("password")}
                />
                <div className="eye-container">
                  {eye === "password" && (
                    <FaEyeSlash size={20} onClick={onEyeClick} />
                  )}
                  {eye === "text" && <FaEye size={20} onClick={onEyeClick} />}
                </div>
              </div>
              <Button label={"Sign Up"} type={"yellow"} btnAction={"submit"} />
            </form>
          </div>
        </div>
      </div>
      <Modal show={modal} close={()=>setModal(false)}>
        <div>
          <p>A confirmation code has been sent to your email {state.email}</p>
          <form>
            <InputBox
              theme={"light"}
              type="text"
              title="Please type the Code"
              placeholder="Verification Code"
              className="input-grp"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Button
              btnAction={"submit"}
              label={"Confirm"}
              type={"yellow"}
              size={"small"}
              onClick={(e) => confirmSignUpCode(e)}
            />
          </form>
        </div>
      </Modal>
      <LoadingOverlay active={loader} spinner text="Loading..." />
    </div>
  );
};

export default PromoPage;
