import {APPLE_PAY_DESCRIPTIOR, PAYMENT_TYPE_PAY_NOW} from "../../middleware/constants/payment.constants";
import {API_GET_APPLE_MERCHANT_SESSION, API_PROCESS_TOKEN} from "../../middleware/constants/url.constants";
import {GET_UN_AUTH, POST_AUTH} from "../../utils/HTTP.utils";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import {toast} from "react-toastify";

export const addAcceptScript = async url => {
  const script = document.createElement("script");
  script.src = url;
  script.charset = "utf-8";
  script.async = true;
  script.id = "auth-accept-script";
  document.head.appendChild(script);
}

export const validateCCDetails = details => {
  return !!(
    details.name &&
    details.number &&
    details.expiry.length === 5 &&
    details.expiry.includes("/") &&
    (details.ccv.length === 3 || details.ccv.length === 4)
  );
}

export const authenticationToken = (
  clientKey = "",
  apiLoginId = "",
  details = {}
) => {
  return new Promise((resolve, reject) => {

    let authData = {};
    authData.clientKey = clientKey;
    authData.apiLoginID = apiLoginId;

    let cardData = {};
    cardData.cardNumber = details.number.toString();
    cardData.month = details.expiry.slice(0, 2);
    cardData.year = details.expiry.slice(-2);
    cardData.cardCode = details.ccv;
    let secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;
    window.Accept.dispatchData(secureData, responseHandler);

    function responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        let i = 0;
        let errorMessage = "";
        while (i < response.messages.message.length) {
          errorMessage = errorMessage + response.messages.message[i].code + ": " + response.messages.message[i].text + " ";
          i = i + 1;
        }
        // reject(errorMessage);
        resolve(response.messages);
      } else {
        resolve(response.opaqueData);
      }
    }
  });

}

export const onApplePayInitiated = (paymentId, history, data) => {
  let request = {
    countryCode: 'US',
    currencyCode: 'USD',
    supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
    merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'], // Make sure NOT to include supportsEMV here
    total: {label: 'Total amount:', amount: data.priceBreakup.totalPrice}
  }
  let session = new window.ApplePaySession(1, request);

  session.onvalidatemerchant = function (event) {
    console.log(event)
    GET_UN_AUTH(API_GET_APPLE_MERCHANT_SESSION, {
      validationUrl: event.validationURL,
      client: "WEB"
    }).then(merchantSession => {
      // console.log("Promise passing data to apple:", merchantSession);
      session.completeMerchantValidation(merchantSession);
    }).catch(console.error)
  }
  session.onpaymentauthorized = function (event) {
    console.log("onvalidatemerchant")
    try {
      console.log('starting session.onpaymentauthorized');
      // console.log("Payment token", event.payment.token);
      let options = {
        "paymentMethod": "APPLE_PAY",
        "dataValue": window.btoa(JSON.stringify(event.payment.token.paymentData)),
        "dataDescriptor": APPLE_PAY_DESCRIPTIOR,
        "paymentId": paymentId,
        "mode": "FULL_PAYMENT",
      };

      getIsUaAuthentication().then(({token}) => {
        POST_AUTH(API_PROCESS_TOKEN, options, token).then(finalCall => {
          let status;
          console.log("finalCall",finalCall)
          if (finalCall.status === "SUCCESS") {
            status = window.ApplePaySession.STATUS_SUCCESS;
            console.log('Apple Pay Payment SUCCESS ');
            setTimeout(() => {
              history.push("/payment-success", {
                finalCall,
                amountDue: data?.priceBreakup?.totalPrice,
                data,
                subType: "APPLE_PAY",
                type: PAYMENT_TYPE_PAY_NOW
              })
            }, 2000)
          } else {
            status = window.ApplePaySession.STATUS_FAILURE;
            toast.error("APPLE PAY Failed!")
          }
          console.log("result of sendPaymentToken() function =  " + finalCall);
          session.completePayment(status);
        });
      });

    } catch (e) {
      console.error(e);
    }
  }
  session.oncancel = function (e) {
    console.log(e);
    console.log("oncancel")
  }
  session.begin();
}