import React, { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import "./CreateAccount.scss";
import Button from "../../components/Button";

const CreateAccount = ({ history }) => {
  return (
    <div className="create-root">
      <h1 className="title">Create an Account</h1>
      <div className="signup-btns">
        <Button
          label={"Sign Up with Apple"}
          onClick={() => Auth.federatedSignIn({ provider: "SignInWithApple" })}
          type={"yellow"}
          size={"small"}
          icon={<FaApple />}
        />
        <Button
          label={"Sign Up with Facebook"}
          onClick={() => Auth.federatedSignIn({ provider: "Facebook" })}
          type={"blue"}
          size={"small"}
          icon={<FaFacebook />}
        />
        <Button
          label={"Sign Up with Google"}
          onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          type={"red"}
          size={"small"}
          icon={<FaGoogle />}
        />
        <Button
          label={"Sign Up with Email"}
          type={"grey"}
          onClick={() => history.push("/sign-up")}
          size={"small"}
        />
        <Button
          label={"Already have an account?"}
          type={"white-link"}
          onClick={() => history.push("/login")}
          size={"small"}
        />
      </div>
    </div>
  );
};

export default CreateAccount;
