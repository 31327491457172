import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Amplify from "aws-amplify";
import HomePage from "./containers/HomePage";
import PromoPage from "./containers/PromoPage";
import Concierge from "./containers/Concierge";
import BookAFlight from "./containers/BookAFlight";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CreateAccount from "./containers/CreateAccount";
import Signup from "./containers/Signup";
import Login from "./containers/Login";
import HowItWorks from "./containers/HowItWorks/HowItWorks";
import WhyFlyPrivate from "./containers/WhyFlyPrivate";
import AboutUs from "./containers/AboutUs";
import Testimonials from "./containers/Testimonials";
import DiversityInclusion from "./containers/DiversityInclusion";
import GeneralTemplateDark from "./containers/GeneralTemplateDark";
import GeneralTemplateLight from "./containers/GeneralTemplateLight";
import ReviewBooking from "./containers/ReviewBooking";
import PaymentSuccess from "./containers/PaymentSuccess";
import MyTrips from "./containers/MyTrips";
import BookingDetails from "./containers/BookingDetails";
import MyAccount from "./containers/MyAccount";
import SafetyRatings from "./containers/SafetyRatings";
import Aircrafts from "./containers/Aircrafts";
import Marketplace from "./containers/Marketplace";
import Itinerary from "./containers/Itinerary";
import PopularTrips from "./containers/PopularTrips";
import PopularTripBooking from "./containers/PopularTripBooking";
import Searching from "./containers/Searching/Searching";
import "./styles/root.scss";
import "./styles/guide.scss";
import { setSessionID } from "./utils/session.storage.utils";
import EditAirport from "./containers/EditAirport";
import aws_exports from "./aws-exports";
import ProtectedRoute from "./components/ProtectedRoute";
import UnAuthRoute from "./components/UnAuthRoute";
import { JET_INFO } from "./middleware/constants/aircarft.constants";
import { connect } from "react-redux";
import ForgotPassword from "./containers/ForgotPassword";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import TermsOfSale from "./containers/TermsOfSale";
import TermsOfService from "./containers/TermsOfService";
import { ToastContainer } from "react-toastify";
import FAQPage from "./containers/FAQPage";
import { Helmet } from "react-helmet";
import "@kenshooui/react-multi-select/dist/style.css";
import IQPlatform from "./containers/IQPlatform";
import { AppProvider } from './AppContext';
//import HeaderWrapper from "./components/Header/HeaderWrapper";

Amplify.configure(aws_exports);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);


export function ScrollToTop() {
  const history = useHistory();
  useEffect(() => {
    history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  }, []);

  return null;
}
function App({ getJetInfo }) {
  useEffect(() => {
    setSessionID();
    getJetInfo();
  }, []);
  return (
    <AppProvider>
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <ScrollToTop />
      {/*Root Level Head*/}
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <React.Suspense fallback={loading()}>
        {/* <HeaderWrapper /> */}
        <Header theme="dark" />
        <Switch>
          <Route
            exact
            path="/"
            name="Home Page"
            render={(props) => <HomePage {...props} />}
          />
          <Route
            exact
            path="/concierge"
            name="Concierge"
            render={(props) => <Concierge {...props} />}
          />
          <Route
            exact
            path="/signup"
            name="promo-page"
            render={(props) => <PromoPage {...props} />}
          />
          <Route
            exact
            path="/testimonials"
            name="testimonials"
            render={(props) => <Testimonials {...props} />}
          />
          <Route
            exact
            path="/create-account"
            name="Sign Up"
            render={(props) => <CreateAccount {...props} />}
          />
          <Route
            exact
            path="/popular-routes"
            name="Popular Trips"
            render={(props) => <PopularTrips {...props} />}
          />
          <Route
            exact
            path="/popular-routes-book"
            name="Popular Trip Booking"
            render={(props) => <PopularTripBooking {...props} />}
          />
          {/* <UnAuthRoute exact path="/login" name="Login" Component={Login} /> */}
          {/* <UnAuthRoute
            exact
            path="/"
            name="Home Page"
            render={(props) => <HomePage {...props} />}
          /> */}
          {/* <UnAuthRoute
            exact
            path='/sign-up'
            name='Sign Up'
            Component={Signup}
          /> */}
          <Route
            exact
            path="/how_it_works"
            name="How it works"
            render={(props) => <HowItWorks {...props} />}
          />
          <Route
            exact
            path="/aircraft"
            name="Aircraft"
            render={(props) => <Marketplace {...props} />}
          />
          <Route
            exact
            path="/why_fly_private"
            name="Why private"
            render={(props) => <WhyFlyPrivate {...props} />}
          />
          <Route
            exact
            path="/book-flight"
            name="Book Flight Page"
            render={(props) => <BookAFlight {...props} />}
          />
          <Route
            exact
            path="/general-template-dark"
            name="General Template Dark"
            render={(props) => <GeneralTemplateDark {...props} />}
          />
          <Route
            exact
            path="/general-template-light"
            name="General Template Light"
            render={(props) => <GeneralTemplateLight {...props} />}
          />
          <Route
            exact
            path="/jet-info"
            name="Jet Info"
            render={(props) => <Aircrafts {...props} />}
          />
          <Route
            exact
            path="/safety-ratings"
            name="Safety Ratings"
            render={(props) => <SafetyRatings {...props} />}
          />
          <Route
            exact
            path="/about_us"
            name="About Us"
            render={(props) => <AboutUs {...props} />}
          />
          <Route
            exact
            path="/diversity_inclusion"
            name="Diversity and Inclusion"
            render={(props) => <DiversityInclusion {...props} />}
          />
          <ProtectedRoute
            exact
            path="/review-booking/:id"
            name="Review Booking"
            Component={ReviewBooking}
          />
          <ProtectedRoute
            exact
            path="/payment-success"
            name="Payment Success"
            Component={PaymentSuccess}
          />
          <ProtectedRoute
            exact
            path="/my-trips"
            name="My Trips"
            Component={MyTrips}
          />
          <ProtectedRoute
            exact
            path="/booking-details/:id"
            name="Booking Details"
            Component={BookingDetails}
          />
          <ProtectedRoute
            exact
            path="/itinerary/:id"
            name="Itinerary"
            component={Itinerary}
          />
          <ProtectedRoute
            exact
            path="/my-account"
            name="My Account"
            Component={MyAccount}
          />
          <Route
            exact
            path="/searching"
            name="Searching"
            render={(props) => <Searching {...props} />}
          />
          <Route
            exact
            path="/edit-airport"
            name="Edit Airport"
            render={(props) => <EditAirport {...props} />}
          />
          {/* <UnAuthRoute
            exact
            path="/forgot-password"
            name="Forgot Password"
            Component={ForgotPassword}
          /> */}
          <Route
            exact
            path="/privacy-policy"
            name="Privacy Policy"
            render={(props) => <PrivacyPolicy {...props} />}
          />
          <Route
            exact
            path="/terms-of-sale"
            name="Terms Of Sale"
            render={(props) => <TermsOfSale {...props} />}
          />
          <Route
            exact
            path="/terms-of-service"
            name="Terms Of Service"
            render={(props) => <TermsOfService {...props} />}
          />
          <Route
            exact
            path="/faq"
            name="FAQ"
            render={(props) => <FAQPage {...props} />}
          />
          <Route
            exact
            path="/iq-platform"
            name="IQ PLATFORM"
            render={(props) => <IQPlatform {...props} />}
          />
           {/* <Route
            exact
            path="/sitemap"
            name="Site Map"
            render={(props) => <IQPlatform {...props} />}
          /> */}
          {/* <Route
            exact
            path='/apps'
            name='OUR APPS'
          /> */}
        </Switch>
        <Footer />
      </React.Suspense>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </BrowserRouter>
    </AppProvider>
  );
}

function mapStateToProps({ aircraft }) {
  return {
    jetInfoData: aircraft.jetInfo.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJetInfo: () => dispatch({ type: JET_INFO }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
