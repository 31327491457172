import React from "react";
import ArrowDown from "../../assets/icons/arrow-down.png";
import { IoLogoLinkedin } from "react-icons/io";
import LinkedInIcon from "../../assets/icons/linkedin.png";
import "./AboutUs.scss";

const teamMembers = [
  {
    title: "David Metrick - CEO and Founder",
    mail: "david@airbookone.com",
    infoLink: "https://www.linkedin.com/in/david-a-metrick-3859717/",
    body: (
      <div>
        <p>
          David’s enthusiasm of and use of private aviation was the genesis of
          AIRBOOK ONE. Recognizing a disconnect in servicing ‘passengers’ in the
          private aviation industry, David has brought 30+ years of his
          ‘customer first’ approach from an extensive real estate service
          provider background to help create a first in private aviation – a
          ‘passenger first’ powered platform.
          <br />
          <br /> David’s expertise is in servicing Fortune 200 companies related
          to business expansion for retailers, restaurants, office users,
          healthcare companies and logistics firms at the global, national,
          regional and local levels with integrated services in sales
          forecasting, consumer trends and consumer purchasing analytics.
          <br />
          <br />
          Experiential research Includes:
        </p>
        <ul className="Team-list">
          <li>
            regression analysis, including psychographics to determine
            operational, financial, sales forecasting planning, in order to meet
            financial objectives
          </li>
          <li>assess growth potential and create market strategy</li>
          <li>align business and capital investment plans</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Phil Usatine – Chief Product Officer",
    mail: "pusatine@airbookone.com",
    infoLink: "https://www.linkedin.com/in/philu",
    body: (
      <div>
        <p>
          Phil comes to AIRBOOK ONE with a rich background in ‘next-level’
          product design and delivery 'to the customer’.
          <br />
          <br /> He has established digital strategies, driven digital
          transformation, and delivered many products as a leader with
          innovators and startups for industries including digital health,
          media, hospitality, and eCommerce and brands including Travel Channel,
          Food Network, and Gibson Guitar. Phil has created award winning
          consumer mobile and web experiences used by 40+ million people
          monthly, and simple and efficient B2B SaaS products and APIs used by
          thousands of professionals. Phil has extensive experience in digital
          strategy, product development, business startup, and applied
          technologies like cloud, SaaS, big data, mobile, and IoT, and
          processes like agile, lean, and design thinking.
        </p>
      </div>
    ),
  },
  {
    title: "Mike Dagnino – Principal",
    mail: "mike@airbookone.com",
    infoLink: "https://www.linkedin.com/in/mikedagnino/",
    body: (
      <div>
        <p>
          Mike is an FAA certified dispatcher with over 20 years of corporate
          flight department experience. This includes large fleet management,
          heavy international flight planning and charter operations. Mike began
          his career at North American Jet in Wheeling, IL with their charter
          department.
          <br />
          <br /> Mike went on to found Aero Resources as well as Apex Aviation
          Logistics. Aero and Apex have now been providing flight departments
          with competent temporary, full-time and subscription-based scheduling
          and dispatching.
          <br />
          <br />
          Mike is the head of operations at AIRBOOK ONE. Mike’s background will
          give AIRBOOK ONE a highly experienced Flight Operator/Logistics expert
          with both domestic and international experience.
          <br />
          <br />
          Mike is as graduate of Lewis University with a degree in Aviation
          Flight Management and Aviation Administration and holds a private
          pilot’s license instrument rating.
        </p>
      </div>
    ),
  },
  {
    title: "Tom Ball – Head of Business Development",
    mail: "tball@airbookone.com",
    infoLink: "https://www.linkedin.com/in/tom-ball-365830a/",
    body: (
      <div>
        <p>
        With a focus on business-to-business development and partnerships,
        Tom has a track record of success in sales and service in the travel industry.
        Most recently, he is president of a full-service travel agency and logistics company.<br /><br />
        Previously, as Executive Vice President of Sales and Marketing for a regional transportation company,
        Tom was responsible for the reorganization of an entity into a thriving and profitable enterprise.
        Tom’s expertise lies in developing new markets, additional channels of distribution, acquisitions,
        online marketing sales, and innovative pricing programs. Tom was Managing Director of the Aspen (Colorado)
        Chamber Resort Association providing valuable insight to resort management, luxury travel, and accommodations.<br /><br />
        Tom has a degree in Marketing from Florida State University School of Business.
        </p>
      </div>
    ),
  },
  {
    title: "Corrie Sinning – Principal",
    mail: "corrie@airbookone.com",
    infoLink: "https://www.linkedin.com/in/corrie-sinning-4170011b/",
    body: (
      <div>
        <p>
          Corrie was most recently the Flight Ops Scheduling Manager for a
          corporate flight department and has 11+ years’ experience as a
          scheduler.
          <br />
          <br /> After working for varying sizes of charter operators and flight
          departments, Corrie has extensive experience in managing aircraft
          fleets that perform high volume, complex international and domestic
          trips. In addition, Corrie’s experience includes managing scheduling
          teams both in the US and AUS.
          <br />
          <br />
          Corrie will lead the Flight Concierge Team for AIRBOOK ONE where her
          background in aviation and customer support provide the ideal
          foundation for building AIRBOOK ONE's Flight Concierge Team. She and
          her team will coordinate trips from booking to final destination and
          take pride in supporting our passengers.
          <br />
          <br />
          Corrie holds a BS in Aerospace Administration from Middle Tennessee
          State University, an MBA in Aviation from Embry Riddle.
        </p>
      </div>
    ),
  },
  
  
];
const AboutUs = ({ }) => {
  return (
    <div className="AboutUs">
      <div className="root-section">
        <div className="hiw-section-1">
          <h4 className="about-us">
            AIRBOOK ONE was founded in an effort to disrupt private aviation by
            offering the first customer-focused private air service and
            delivering world-class personal travel experiences to consumers.
            <br></br>
            <br></br>
            With both our booking platform and proprietary pricing-availability algorithm we give ‘Power to the Passenger’ by allowing instant access to private aircraft and real time guaranteed pricing, with no membership fees or pre-paid flight hours required. For more
            information, download the AIRBOOK ONE mobile app via the Apple and
            Google Play stores.
          </h4>
          <img
            className="scroll-icon pointer-cursor"
            src={ArrowDown}
            onClick={() => {
              window.document
                .querySelector(".Profiles")
                .scrollIntoView({ behavior: "smooth" });
            }}
            alt="scroll down"
          />
        </div>
        {/* <p className={"text-align-center color-white page-width diverse-section"} id={"DIVERSITY"}>
          We are a diverse team that celebrates the unique perspectives, ideas and experiences of every individual.
          From the team members we hire, to the customers we serve, inclusion and diversity is a way of life at AIRBOOK
          ONE. We are guided by our inclusive purpose and mission to curate gateways to develop deeper connections and
          broaden horizons for our partners, customers and communities. We are dedicated to creating an inclusive
          environment for everyone, regardless of race, ethnicity, color, national origin, age, disability, sexual
          orientation, gender identity/expression or any other legally protected characteristic.
        </p> */}
        <div className="Profiles">
          <h1 className="Team-Heading">TEAM MEMBERS</h1>
          {teamMembers && teamMembers.length > 0 && (
            <>
              {teamMembers.map((indvMember, ind) => (
                <div className="Team" key={`team-ab-${ind}`}>
                  <div className="Heading">
                    <p className="Title">{indvMember.title}</p>
                    <a href={`mailto:${indvMember.mail}`} className="Email">
                      | {indvMember.mail}
                      {indvMember.infoLink && (
                        <a
                          target="_blank"
                          className="linkedin"
                          href={indvMember.infoLink}
                        >
                          <img src={LinkedInIcon} alt="LinkedIn" />
                        </a>
                      )}
                    </a>
                  </div>
                  <div className="Body">{indvMember.body}</div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="spacer" />
      </div>
    </div>
  );
};

export default AboutUs;
