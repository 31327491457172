import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import "./InputBox.scss";
import {FaEye, FaEyeSlash} from "react-icons/fa";

function InputBox({theme = "black", label, ...props}) {
  const inputRef = useRef(null);
 // const [state, setState] = useState("password");
  // const onEyeClick = () => {
  //   if (inputRef.current.type === "password") {
  //     inputRef.current.type = "text";
  //     setState("text")
  //   } else {
  //     inputRef.current.type = "password";
  //     setState("password")
  //   }
  // }
  return (
    <div className={`InputBox ${theme}-input`}>
      {label && <label>{label}</label>}
      <div className="input-container">
        <input ref={inputRef} {...props} />
        {/* <div className="eye-container"> */}
          {/* {props.type === "password"  && (
            inputRef.current.type = "password"
          )} */}
         {/* {props.type === "password" && state === "text" && (
            <FaEye size={20} onClick={onEyeClick} />
          )}
        </div> */}
      </div>
    </div>
  );
}

InputBox.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.oneOf(["black", "light"]),
};

export default InputBox;