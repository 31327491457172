import {
  INIT_PAYMENT_SUCCESS,
  PAYMENT_TYPE_PAY_NOW,
  SET_PAYMENT_TYPE,
  SET_SUB_PAYMENT_TYPE
} from "../constants/payment.constants";

const initialState = {
  paymentOption: PAYMENT_TYPE_PAY_NOW,
  subPaymentOption: "",
  initiatePayment: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_TYPE: {
      return {...state, paymentOption: action.payload, subPaymentOption: ""}
    }
    case SET_SUB_PAYMENT_TYPE: {
      return {...state, subPaymentOption: action.payload,}
    }
    case INIT_PAYMENT_SUCCESS: {
      return {...state, initiatePayment: action.payload}
    }
    default: {
      return state
    }
  }
};
