import React, { useState, useEffect, useRef } from "react";
import DownloadRef from "../../components/DownloadRef";
import GenTempImageView from "../../components/GenTempImageView";
import ServiceCard from "../../components/ServiceCard";
import FlightView from "../../assets/flight-wing.jpeg";
import BuildingView from "../../assets/building-view.png";
import Plane from "../../assets/icons/plane.png";
import InstantBook from "../../assets/icons/instant-book.png";
import Search from "../../assets/icons/search.png";
import ArrowDown from "../../assets/icons/arrow-down.png";
import Forbes from "../../assets/featureForbes.png";
import SanitizeImg from "../../assets/sanitize.jpg";
import BrandNameLogo from "../../assets/logos/logo-name-desktop.png";
import "./HomePage.scss";
import SearchFlightForm from "./SearchFlightForm";
import PageContainer from "../../components/PageContainer";
import { HOME_PAGE_SEO } from "../../constants/seo.constants";
import tick from "../../assets/icons/list-bullet.png";
import Popup from "../../components/Incentive/Popup.js";
import { GET_UN_AUTH } from "../../utils/HTTP.utils";
import { API_GET_CONFIGURATION } from "../../middleware/constants/url.constants";
import home_video from "../../assets/home_video/home_video.mov";
import TM from "../../assets/tm.png";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import BenefitSummary from "../../containers/BenefitSummary/BenefitSummary";
import CreateAccount from "../../containers/Login/CreateAccount";
import CreatePassword from "../../containers/Login/CreatePassword";
import ForgotPassword from "../../containers/Login/ForgotPassword";
import ResetPassword from "../../containers/Login/ResetPassword";
import Login from "../../containers/Login/Login";
import PopularTripCards from "../../containers/PopularTrips/PopularTripCards.js";
import "../../containers/PopularTrips/PopularTrips.scss";
import { useAppContext } from "../../AppContext";
import { connect } from "react-redux";
import { UPDATE_AUTH_DATA } from "../../middleware/constants/auth.constants";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import Blank from "../../assets/blank.png";
import { useLocation } from 'react-router-dom';

const HomePage = ({
  history,
  loginStatus,
  loginUserData,
  createAccount,
  updateLoginStatus,
  hideModalStatus
}) => {
  // POPUP CODE
  const userInfo = useSelector((state) => state.auth.userData);
  const location = useLocation();
  let t = 3; // time until popup
  let [offerModal, setOfferModal] = useState(false);
  let [peekModal, setPeekModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const [fields, setFields] = useState({});
  const [modal, setModal] = useState("");
  const [accountModal, setAccountModal] = useState(false);
  const [joinButton, setJoinButton] = useState(false);
  const [userData, setUserData] = useState("");
  const { modal1 } = useAppContext();
  const videoRef = useRef(null);
  let pathRef = history?.location?.state?.loginFrom ?? '';
  let delNav = new URLSearchParams(location.search).get('login');
  //window?.location?.search.match();

  const modalHandler = (data, props) => {
    console.log(data,props)
    setModal(data);
    setFields(props);
    setModalClose(true);
  };

  const close = () => {

    updateLoginStatus(false,undefined, false, undefined);
    setModalClose(false);
    const socialLogin = localStorage.getItem("socialLogin") || "no";
    if (socialLogin === "yes") {
      setIsAuthenticated(false);
      localStorage.removeItem("socialLogin");
      Auth.signOut({})
        .then(() => {
          // Handle any additional logic after successful sign-out
          console.log("User signed out successfully");

          return false;
        })
        .catch((error) => {
          // Handle sign-out errors
          console.error("Error signing out:", error);
        });
    }
  };
  useEffect(() => {
    GET_UN_AUTH(API_GET_CONFIGURATION, { type: "PEAK_TRAVEL_MESSAGE" }).then(
      (data) => {
        if (!data.error) {
          if (data["details"] && data["details"]["homeScreenMessage"]) {
            setPeekModal(true);
          }
        }
      }
    );
   
  }, []);

 



  useEffect(() => {
    setUserData(loginUserData);
    setIsAuthenticated(loginStatus);

    if (!loginStatus) {
      if (createAccount) {
        setAccountModal(true);
        //setUserData(userData);
        setOfferModal(false);
      } else {
        setTimeout(() => {
          setOfferModal(true);
        }, t * 1000);
      }
    }
  }, [loginStatus, loginUserData, createAccount]);

  useEffect(() => {
    if (accountModal === true) {
      modalHandler("createAccount");
      setModalClose(true);
    }
  }, [accountModal]);

  useEffect(() => {
    if(hideModalStatus)
      close();
  }, [hideModalStatus]);

  const imgViewData = {
    img1: BuildingView,
    img2: FlightView,
    title1: "How AirBook One Works",
    title2: "Why Fly Private?",
    subtitle1:
      "AIRBOOK ONE allows consumers to simply enter their travel information and search aircraft options directly, all from the convenience of their mobile phone, tablets or desktop. All price quotes provided are accurate and can be locked-in immediately. In addition, AIRBOOK ONE offers all users access to a 24/7 complimentary premium Flight Concierge Team via email, chat, or phone to ensure a seamless experience.",
    subtitle2:
      "Private jet travel not only provides a more efficient way to travel, but also a safer way to fly by reducing passengers' exposure to health and security risks. In addition, travelers enjoy the privilege of using private terminals (Fixed Based Operators – FBOs) where they can relax before their flight, without the hustle and bustle of commercial airline terminals.",
    link1: "./how_it_works",
    link2: "./why_fly_private",
  };

  const services = [
    {
      image: Search,
      title: "Search Aircrafts.",
      reference:
        "Search thousands of aircraft and routes at your fingertips, effortlessly without any barriers or limitations.",
    },
    {
      image: InstantBook,
      title: "Instantly Book.",
      reference:
        "Secure your booking and lock in your “real-time” price instantly, guaranteed.",
    },
    {
      image: Plane,
      title: "Fly.",
      reference:
        "Enjoy a safe and fun experience while traveling with friends, family or colleagues.",
    },
  ];

  useEffect(() => {
    if (modal1 === true) {
      modalHandler("login");
      setModalClose(true);
    }
  }, [modal1]);

  useEffect(() => {
    if (pathRef  === 'protectedPage' || delNav === 'true') {
      modalHandler("login");
      setModalClose(true);
      history.replace({})
    }
  }, [pathRef,delNav ]);

  useEffect(() => {
    // Access the video element using the ref
    const video = videoRef.current;

    // Check if the video element exists
    if (video) {
      // Listen for the 'loadedmetadata' event to ensure the video is ready
      video.addEventListener('loadedmetadata', () => {
        // Start playing the video
        video.play().catch(error => {
          // Handle the error (e.g., autoplay policy restrictions)
          console.error('Autoplay error:', error);
        });
      });
    }
  }, []);

  //console.log(authenticated)

  return (
    <PageContainer className="root-section" {...HOME_PAGE_SEO}>
      <div className="search-flight">
        <div className="search-img">
        <video
          // id="ab1Bg"
          className="videoTag"
          muted={true}
          autoPlay={"autoplay"}
          loop={true}
          playsInline={true}
          poster={Blank}
          //ref={videoRef}  // Assign the ref to the video element
        >
          <source src={home_video} type="video/mp4" />
        </video>
          <div className="left-section">
            <img className="logo" src={BrandNameLogo} alt="Airbook one logo" />
            <h5>
              Power to the Passenger
              <img className="logo-tm" src={TM} alt="Airbook one logo" />
            </h5>
            <div className="content">
              <div className="home-saftey-content">
                Safety is our number one priority at AIRBOOK ONE
              </div>
              <div className="home-saftey-sub-content">
                We only ‘price’ Argus Platinum, Wyvern Wingman & IS-BAO Stage 3
              </div>
            </div>
            <div className="search-list">
              <img src={tick} alt="" />
              <p>Guaranteed Pricing - No Member Fees</p>
            </div>
            <div className="search-list">
              <img src={tick} alt="" />
              <p>Complimentary 24/7 Flight Concierge</p>
            </div>
            {!isAuthenticated ? (
              <>
                <span className="search-btn">
                  <Button
                    type="yellow"
                    size="large"
                    label="Join"
                    onClick={() => {
                      setModal("benefit");
                      setModalClose(true);
                      setJoinButton(false);
                    }}
                  />
                </span>
                <span
                  className="login-btn"
                  onClick={() => {
                    modalHandler("login");
                    setModalClose(true);
                  }}
                >
                  Login
                </span>
              </>
            ) : (
              ""
            )}

            {/* <Modal createLogin={true} close={() => setCAModal(false)} show={caModal}> */}
            {/* <div className='ca-account'>
            <h5 className="heading">You are currently logged in at <a href="/">airbookone.com</a>.</h5>
            <h5>Not your account?</h5>
            <h5>Click Log out button below to log out.</h5>
            </div>         */}
            {/* <Login/> */}

            {/* </Modal> */}
            {/* <ChangePasswordModal
              status={changeModal}
              email={state.email}
              onChange={setChangeModal}
            /> */}
            {/* <div className="search-list" onClick={() => history.push(`/safety-ratings`)}>
              <img src={tick} alt="" />
              <p className="home-links">All Aircraft are Safety Vetted</p>
            </div> */}
            {/* <div className="download">
              <a className="app-img-link" href="https://apps.apple.com/us/app/airbook-one/id1553278305">
                <img className="app-img button-1" src={AppStore} alt="app store" />
              </a>
              <a className="app-img-link" href="https://play.google.com/store/apps/details?id=com.airbookone">
                <img className="app-img button-2" src={PlayStore} alt="google play store" />
              </a>
            </div> */}
          </div>
          <div>
            {modal === "benefit" ? (
              <div className="join-popup">
                <Modal
                  benefit={true}
                  close={() => setModalClose(false)}
                  show={modalClose}
                >
                  <BenefitSummary modalHandler={modalHandler} />
                </Modal>
              </div>
            ) : (
              <Modal
                createLogin={true}
                close={close}
                show={modalClose}
                preventClose={true}
              >
                {modal === "login" ? (
                  <Login modalHandler={modalHandler} history={history} />
                ) : modal === "createAccount" ? (
                  <CreateAccount
                    modalHandler={modalHandler}
                    modalclose={close}
                    userData={loginUserData}
                  />
                ) : accountModal ? (
                  <CreateAccount
                    modalHandler={modalHandler}
                    modalclose={close}
                    userData={loginUserData}
                  />
                ) : modal === "createPassword" ? (
                  <CreatePassword
                    modalHandler={modalHandler}
                    fieldValue={fields}
                    close={close}
                  />
                ) : modal === "forgotPassword" ? (
                  <ForgotPassword
                    modalHandler={modalHandler}
                    fieldValue={fields}
                    close={close}
                  />
                ) : modal === "resetPassword" ? (
                  <ResetPassword
                    modalHandler={modalHandler}
                    fieldValue={fields}
                    close={close}
                  />
                ): null}
              </Modal>
            )}
          </div>
          <SearchFlightForm history={history} modalHandler={modalHandler} />
        </div>
        <div className="undersection">
          <img className="Forbes" src={Forbes} alt="Forbes" />
          <div className="content">
            <p id="learnmore">
              These Websites Want To Change How You Charter Private Jets
            </p>
            <a
              target="_blank"
              href="https://www.forbes.com/sites/douggollan/2021/06/02/these-websites-want-to-change-how-you-charter-private-jets/?sh=625ca6216a37"
            >
              <img className="arrow" src={ArrowDown} alt="learn more" />
            </a>
          </div>
        </div>
      </div>
      {/* <Carousel /> */}

      <h1 className="homepage-title">Easily book your next private trip.</h1>
      <div className="services-section">
        {services.map((service, index) => {
          return <ServiceCard key={index} {...service} />;
        })}
      </div>
      <div className="spacer" />
      <GenTempImageView {...imgViewData} />
      <div className="sanitize">
        <div className="sanitize-img">
          <img src={SanitizeImg} alt="Sanitizing flight" />
        </div>
        <div className="sanitize-content">
          <h3>Covid &amp; Cleaning Procedures</h3>
          <p>
            Private jet travel not only provides a more efficient way to travel,
            but also a safer way to fly by reducing passengers' exposure to
            health and security risks. In addition, travelers enjoy the
            privilege of using private terminals (Fixed Based Operators – FBOs)
            where they can relax before their flight, without the hustle and
            bustle of commercial airline terminals.{" "}
          </p>
          {/* <button>Learn more</button> */}
        </div>
      </div>
      <div className="trip-cards">
        <PopularTripCards />
      </div>
      <div id="downloads">
        <DownloadRef />
      </div>
      {!accountModal && (
        <>
          <Popup status={offerModal} type="offer" onChange={setOfferModal} />
          <Popup status={peekModal} type="peek" onChange={setPeekModal} />
        </>
      )}
    </PageContainer>
  );
};

function mapStateToProps({ auth }) {
  return {
    loginStatus: auth.loginStatus,
    loginUserData: auth.userData,
    createAccount: auth.createAccount,
    hideModalStatus : auth.hideModalStatus

  };
  
}
function mapDispatchToProps(dispatch) {
  return {
    updateLoginStatus : (status, userData, createAccount, user) => dispatch({ type: UPDATE_AUTH_DATA, loginStatus : status , userData : userData, createAccount : createAccount, user: user}),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
