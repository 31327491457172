import React, {useState} from 'react';
import "./AddPassengerModal.scss";
import Modal from "../Modal";
import Button from "../Button";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { POST_AUTH } from "../../utils/HTTP.utils";
import { API_POST_ADD_TRAVELLER } from "../../middleware/constants/url.constants";
import DatePicker from "react-datepicker";
import { getRange } from "./helper";
import moment from "moment";
import LightTextPanel from "../LightTextPanel";
import { CountryDropdown } from 'react-country-region-selector';
import {isValidPhoneNumber,isPossiblePhoneNumber} from "react-phone-number-input";
import Input from "react-phone-number-input/input";

const TABS = [
  { label: "Business", value: "COLLEAGUE" },
  { label: "Family", value: "FAMILY" },
  { label: "Friends", value: "FRIEND" },
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function AddPassengerModal({ status, onChange, onNewTraveller }) {
  const [state, setState] = useState({
    fname: "",
    mname: "",
    lname: "",
    tag: TABS[0].value,
    phoneNumber: "",
    email: "",
    dob: "",
    passportNumber: "",
    passportExpiry: "",
    passportCountry: "United States",
  });
  const [errorFields, setErrorFields] = useState({
    fname: false,
      mname: false,
      lname: false,
      phoneNumber: false,
      tag:false,
      dob: false,
      invalidphoneNo: false,
  });
  const [invalidEmailError, setInvalidEmailError] = useState(false);

  const addTraveller = async (payload) => {
    let { token } = await getIsUaAuthentication();
    try {
      let res = await POST_AUTH(API_POST_ADD_TRAVELLER, {...payload, mname: payload.mname.toUpperCase()}, token);
      onNewTraveller && onNewTraveller(res.id);
      setState({
        fname: "",
        mname: "",
        lname: "",
        tag: TABS[0].value,
        phoneNumber: "",
        email: "",
        dob: "",
        dobSelect: "",
        passportNumber: "",
        passportExpiry: "",
        expSelect: "",
        passportCountry: "United States",
      });
    } catch (e) {
      console.error(e);
    }
    setState({
      fname: "",
      mname: "",
      lname: "",
      tag: TABS[0].value,
      phoneNumber: "",
      email: "",
      dob: "",
      dobSelect: "",
      passportNumber: "",
      passportExpiry: "",
      expSelect: "",
      passportCountry: "United States",
    });
    onChange(false);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      fname,
      mname,
      lname,
      phoneNumber,
      email,
      tag,
      dob,
      passportNumber,
      passportExpiry,
      passportCountry,
    } = state;
    if (isFormValidOnSubmit()) {

      await addTraveller({
        fname,
        mname,
        lname,
        phoneNumber,
        email,
        tag,
        dob,
        passportNumber,
        passportExpiry,
        passportCountry,
      });
    }
  };

  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };
  const handlePhone = e => {
    setState((prev) => ({
      ...prev,
      phoneNumber: e
    }));
  }

  const isFormValidOnSubmit = () => {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
   // let phoneRegex = /^\d/;
    const errors = {
      fname: false,
      mname: false,
      lname: false,
      phoneNumber: false,
      tag:false,
      dob: false,
      invalidphoneNo: false,
    };

    if (state.fname === "") {
      errors.fname = true;
    }
    // if (state.mname === "") {
    //   errors.mname = true;
    // }
    if (state.lname === "") {
      errors.lname = true;
    }
    if (state.dobSelect === "") {
      errors.dob = true;
    }
    if (state.phoneNumber === undefined || state.phoneNumber === "") {
      errors.phoneNumber = true;
    }
    else {
      console.log(state.phoneNumber)
      if(isValidPhoneNumber(state.phoneNumber)=== false) {
        errors.invalidphoneNo = true;
       // setInvalidPhoneError(false)

      }
    }
    
    console.log(errors);
    setErrorFields(errors);
    if ((Object.values(errors).some((error) => error === true))) {

      console.log(errors);


      return false;
    }
    return true;
  };


  const isFieldValid = (e) => {
    const { name, value } = e.target;
  
    let errors = false;
  
    if (name === 'fname' && value === "") {
      errors = true;
    }
    // if (name === 'mname' && value === "") {
    //   errors = true;
    // }
  
    if (name === 'lname' && value === "") {
      errors = true;
    }
    // if (name === 'dob' && value === "") {
    //   errors = true;
    // }
  
    if (name === 'phoneNumber' && value === "") {
      errors = true;
    }
  
    setErrorFields((prev) => ({
      ...prev,
      [name]: errors,
      //invalidEmail: false,
    }));
  };
  return (
    <div className="AddPassengerModal">
      <Modal close={() => onChange(false)} show={status} preventClose={true}>
        <p className="title">Add Passenger</p>
        <div className="tab-container">
          {TABS.map((a, i) => (
            <Button
              key={i}
              label={a.label}
              size={"small"}
              fullWidth={false}
              onClick={() => setState({ ...state, tag: a.value })}
              type={`${a.value === state.tag ? "black" : "link"}`}
            />
          ))}
        </div>
        <form onSubmit={onSubmit}>
          <div className="input-container">
            <input
              type="text"
              required
              name='fname'
              className="full-width-container"
              onChange={handleChange("fname")}
              value={state.fname.trim()}
              placeholder={"First Name *"}
            />
            <input
              type="text"
              name="mname"
              className="full-width-container"
              onChange={handleChange("mname")}
              placeholder={"Middle Initial"}
              value={state.mname.trim().toUpperCase()}
              maxLength={1}
              onBlur={isFieldValid}

            />
            <input
              type="text"
              required
              name="lname"
              className="full-width-container"
              onChange={handleChange("lname")}
              placeholder={"Last Name *"}
              onBlur={isFieldValid}

            />
            <div className="dob-container">
              <DatePicker
                selected={state.dobSelect}
                required
                renderCustomHeader={({ date, changeYear, changeMonth }) => {
                  return (
                    <div className={"date-time-custom-header"}>
                      <select
                        name="Months"
                        value={months[moment(date).month()]}
                        id="date-time-picker-months"
                        onChange={({ target }) =>
                          changeMonth(months.indexOf(target.value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        name="Years"
                        value={moment(date).year()}
                        id="date-time-picker-years"
                        onChange={({ target }) => changeYear(target.value)}
                      >
                        {getRange(1920, new Date().getFullYear()).map(
                          (option) => (
                            <option
                              key={option}
                              value={option}
                              selected={option === new Date().getFullYear() - 18}
                            >
                              {option}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  );
                }}
                placeholderText="Date Of Birth *"
                maxDate={new Date()}
                dateFormat="MMMM d, yyyy"
                onChange={(date) => {
                  if (moment(date).isBefore()) {
                    setState({
                      ...state,
                      dobSelect: date,
                      dob: `${new Date(date).getFullYear()}-${(
                        new Date(date).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}-${new Date(date)
                        .getDate()
                        .toString()
                        .padStart(2, "0")}`,
                    });
                  }
                }}
                onBlur={isFieldValid}

              />
            </div>

            <Input
              required
              defaultCountry="US"
              //className="phone-input"
              className={`phone-input full-width-container ${(errorFields.phoneNumber || errorFields.invalidphoneNo) && "error"}`}
              name="phoneNumber"
              placeholder="Phone Number *"
              //value={state.phone ?? "+1"}
              onChange={handlePhone}
              onBlur={isFieldValid}
            />
            {/* <input
              type="number"
              required
              className="full-width-container"
              onChange={handleChange("phoneNumber")}
              placeholder={"Phone Number *"}
            /> */}
            <input
              type="text"
              className="full-width-container"
              onChange={handleChange("email")}
              placeholder={"Email"}
            />
            <div className="passport-input">
              <input
                type="text"
                className="pass-full-width-container"
                onChange={handleChange("passportNumber")}
                placeholder={"Passport Number"}
              />
              <h6 className="subtext">Required for international trips *</h6>
            </div>
            <div className="passport-input">
              <DatePicker
                className="pass-full-width-container"
                selected={state.expSelect}
                minDate={new Date()}
                renderCustomHeader={({ date, changeYear, changeMonth }) => {
                  return (
                    <div className={"date-time-custom-header"}>
                      <select
                        name="Months"
                        value={months[moment(date).month()]}
                        id="date-time-picker-months"
                        onChange={({ target }) =>
                          changeMonth(months.indexOf(target.value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        name="Years"
                        value={moment(date).year()}
                        id="date-time-picker-years"
                        onChange={({ target }) => changeYear(target.value)}
                      >
                        {getRange( new Date().getFullYear(), new Date().getFullYear()+20).map(
                          (option) => (
                            <option
                              key={option}
                              value={option}
                              selected={option === new Date().getFullYear() - 18}
                            > 
                              {option}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  );
                }}
                placeholderText="Passport Expiration Date"
                dateFormat="MMMM d, yyyy"
                onChange={(date) => {
                    setState({
                      ...state,
                      expSelect: date,
                      passportExpiry: `${new Date(date).getFullYear()}-${(
                        new Date(date).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, "0")}-${new Date(date)
                        .getDate()
                        .toString()
                        .padStart(2, "0")}`,
                    });
                }}
              />
              <h6 className="subtext">Required for international trips *</h6>
            </div>
            <div className="passport-input">
            <CountryDropdown
              value={state.passportCountry}

              defaultOptionLabel="Passport Country Issued"
              onChange={(country)=>{setState({...state,passportCountry:country})}}
              style={{
                color:"#848b94",
                width: "90%",
                border: 0,
                fontSize: 16
            }} />
              <h6 className="subtext">Required for international trips *</h6>
            </div>
            
            
          </div>
          <LightTextPanel
            body={
              "Passenger must have physical ID or Passport at the time of flight. "
            }
          />
          <Button
            label={"Add Passenger"}
            type={"yellow"}
            btnAction={"submit"}
          />
        </form>
      </Modal>
    </div>
  );
}

export default AddPassengerModal;
