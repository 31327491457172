
export const PAYMENT_TYPE_PAY_NOW = "FULL_PAYMENT";
export const PAYMENT_TYPE_PAY_BY_WIRE = "PARTIAL_PAYMENT";
export const PAYMENT_TYPE_DESELECT = "DESELECT_PAYMENT";
export const PAYMENT_TYPE_SUBPAYMENT = "DESELECT_SUBPAYMENT";


export const PAYMENT_TYPE_PAY_NOW_CREDIT_CARD = "PAY_NOW_CREDIT_CARD";
export const PAYMENT_TYPE_PAY_NOW_WALLET_PAY = "PAY_NOW_WALLET_PAY";

export const PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY = "PAY_BY_WIRE_APPLE_PAY";
export const PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD = "PAY_BY_WIRE_CREDIT_CARD";

export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";
export const SET_SUB_PAYMENT_TYPE = "SET_SUB_PAYMENT_TYPE";

export const INIT_PAYMENT = "INIT_PAYMENT";
export const INIT_PAYMENT_SUCCESS = "INIT_PAYMENT_SUCCESS";
export const INIT_PAYMENT_ERROR = "INIT_PAYMENT_ERROR";


export const APPLE_PAY_DESCRIPTIOR = "COMMON.APPLE.INAPP.PAYMENT";