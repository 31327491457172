import {getSessionID, setSessionID} from "../../utils/session.storage.utils";

export const getSearchDataFromState = (data, searchOptions) => {
  let travelCities = [];
  for (let i = 0; i < data.length; i++) {
    travelCities.push({
      "origin": data[i].departureAirport.icaoCode,
      "destination": data[i].arrivalAirport.icaoCode,
      "departureTime": transformDate(data[i].departureDateTime),
      "returnTime": "",
      "order": i
    },)
  }
  return {
    "routeType": "MULTI_CITY",
    "multiLegData": {
      // "firstStop": {
      //   "value": {},
      //   "suggestions": [],
      //   "inputValue": ""
      // },
      // "stops": {
      //   "value": {},
      //   "suggestions": [],
      //   "inputValue": ""
      // },
      // "lastStop": {
      //   "value": {},
      //   "suggestions": [],
      //   "inputValue": ""
      // },
    },
    travelCities,
    "flyShare": searchOptions.flyShare,
    "passengers": searchOptions.passengers,
    "petFriendly": searchOptions.petFriendly,
    "sessionId": getSessionID() || setSessionID(),
  }
}

function transformDate(unFormatted) {
  let [date, time] = unFormatted.split(" ");
  let [day, month, year] = date.split("/");
  let [hour, min, sec] = time.split(":");
  return `${year}-${month}-${day} ${hour}:${min}`;
}