import React, {useState} from 'react';
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { POST_AUTH } from "../../utils/HTTP.utils";
import { API_POST_VENDOR_LIMOLINK } from "../../middleware/constants/url.constants";
import {addDays} from 'date-fns';
import './LimoLinkModal.scss';
import Modal from "../Modal";
import Button from "../Button";
import Input from "react-phone-number-input/input";
import { BsPlusCircleFill } from "react-icons/bs";
import DatePicker,{CalendarContainer} from "react-datepicker";
import {toast} from 'react-toastify';
import {useParams} from "react-router-dom";

const LimoLinkModal = ({status, onChange, getRefresh}) => {
    const id = useParams();
    const[generalInfo, setGeneralInfo] = useState({
        firstName:"",
        lastName:"",
        phone:"", 
        email:"",
        vehicleType:"",
        luggageCount:""
    })
    const[pickupLocation, setPickupLocation] = useState({
        address:"",
        city:"",
        state:"",
        zipcode:"",
        time:"",
    })
    const[dropOffLocation,setDropOffLocation] = useState({
        address:"",
        city:"",
        state:"",
        zipcode:"",
        time:"",
    })
    const[stopsList, setStops] = useState([])
    const[driverNotes, setDriverNotes] = useState("")

    const onSubmit = (e) => {
        e.preventDefault();
        let payload = {
            bookingId: id.id,
            generalInfo,
            pickupLocation,
            dropOffLocation,
            stopsList,
            driverNotes
        }
        getIsUaAuthentication().then(async({token}) => {
            let res = await POST_AUTH(API_POST_VENDOR_LIMOLINK,payload,token);
            onChange(false);
            if(res.error){
                toast.error("Your Request Couldn't Be Submitted")
            }else{
                toast.success("Your Request Has Been Submitted")
                getRefresh();
            }
            
        })
    }


    const handleGeneralInfoChange = (key) => (e) => {
        setGeneralInfo({...generalInfo, [key]: e.target.value});
      };
    
    const handlePickupInfoChange = (key) => (e) => {
        setPickupLocation({...pickupLocation, [key]: e.target.value});
      };

    const handleDropoffInfoChange = (key) => (e) => {
        setDropOffLocation({...dropOffLocation, [key]: e.target.value});
    };

    const handleAddLocationChange = (e, index, key) =>{
        stopsList[index][key] = e.target.value;
        setStops([...stopsList])
    }

    const handleDriverNotes = (e) => {
        setDriverNotes(e.target.value);
    }

    const handlePhone = e => {
        generalInfo.phone = e;
        setGeneralInfo(generalInfo);
    }

    const addStop = () => {
        setStops(stopsList.concat({
            address:"",
            city: "",
            state: "",
            zipcode: "",
            time: ""
        }))
    }

    const removeStop = (arr, index) => {
        return arr.filter((a, i) => i !== index)
        
    }


    return(
    <div className="LimoLinkModal">
      <Modal close={() => onChange(false)} show={status}>
        <p className="title">Request a quote</p>
        <p className="limo-info">Fill out this form to help LimoLink build a quote for you.</p>
        <form onSubmit={onSubmit}>
            <p className="subtitle">General</p>
            <div className="input-container">
                <input
                    type="text"
                    required
                    className="full-width-container"
                    onChange={handleGeneralInfoChange("firstName")}
                    placeholder={"First Name"}
                    value={generalInfo.firstName}
                />
                <input
                    type="text"
                    required
                    className="full-width-container"
                    onChange={handleGeneralInfoChange("lastName")}
                    placeholder={"Last Name"}
                    value={generalInfo.lastName}
                />
                <Input
                    required
                    defaultCountry="US"
                    className="phone-input full-width-container"
                    placeholder="Phone Number"
                    onChange={handlePhone}
                    value={generalInfo.phone}
                />
                <input
                    type="email"
                    required
                    className="full-width-container"
                    onChange={handleGeneralInfoChange("email")}
                    placeholder={"Email Address"}
                    value={generalInfo.email}
                />
                <select required onChange={handleGeneralInfoChange("vehicleType")}className="full-width-container">
                    <option value="" selected disabled hidden>Vehicle Type</option>
                    <option value="Sedan">Sedan</option>
                    <option value="SUV">SUV</option>
                    <option value="SprinterVan">Sprinter Van</option>
                </select>
                <input
                    type="number"
                    className="full-width-container"
                    onChange={handleGeneralInfoChange("luggageCount")}
                    placeholder={"Luggage Count"}
                    value={generalInfo.luggageCount}
                />
            </div>
            <p className="subtitle">Pickup Location</p>
            <div className="input-container">
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handlePickupInfoChange("address")}
                        placeholder={"Address"}
                        value={pickupLocation.address}
                />
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handlePickupInfoChange("city")}
                        placeholder={"City"}
                        value={pickupLocation.city}
                />
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handlePickupInfoChange("state")}
                        placeholder={"State"}
                        value={pickupLocation.state}
                />
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handlePickupInfoChange("zipcode")}
                        placeholder={"Zip Code"}
                        value={pickupLocation.zipcode}
                />
                <div className="full-width-container">
                    <DatePicker selected={pickupLocation.time}
                        showTimeSelect
                        calendarContainer={({ className, children }) => {
                            return (
                            <div style={{width:"327.06px", position:"relative", right:"50px"}}>
                                <CalendarContainer className={className}>
                                    <div className="react-datepicker__triangle"/>
                                    <div style={{width:"327.06px", position:"inherit"}}>
                                        {children}
                                    </div>
                                </CalendarContainer>
                            </div>
                            );
                        }}
                        minDate={new Date()}
                        openToDate={new Date()}
                        placeholderText="Pickup Time"
                        dateFormat="MM-dd-yyyy h:mm:ss"
                        onChange={date => {
                            setPickupLocation({...pickupLocation, time: date});
                        }}/>
                </div>
            </div>
            <p className="subtitle">Dropoff Location</p>
            <div className="input-container">
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handleDropoffInfoChange("address")}
                        placeholder={"Address"}
                        value={dropOffLocation.address}
                        
                />
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handleDropoffInfoChange("city")}
                        placeholder={"City"}
                        value={dropOffLocation.city}
                />
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handleDropoffInfoChange("state")}
                        placeholder={"State"}
                        value={dropOffLocation.state}
                />
                <input
                        type="text"
                        className="full-width-container"
                        onChange={handleDropoffInfoChange("zipcode")}
                        placeholder={"Zip Code"}
                        value={dropOffLocation.zipcode}
                />
                <div className="full-width-container">
                    <DatePicker selected={dropOffLocation.time}
                        showTimeSelect
                        calendarContainer={({ className, children }) => {
                            return (
                            <div style={{width:"327.06px", position:"relative", right:"50px"}}>
                                <CalendarContainer className={className}>
                                    <div className="react-datepicker__triangle"/>
                                    <div style={{width:"327.06px", position:"inherit"}}>
                                        {children}
                                    </div>
                                </CalendarContainer>
                            </div>
                            );
                        }}
                        minDate={new Date()}
                        openToDate={new Date()}
                        placeholderText="Dropoff Time"
                        dateFormat="MM-dd-yyyy h:mm:ss"
                        onChange={date => {
                            setDropOffLocation({...dropOffLocation, time: date});
                        }}/>
                </div>
            </div>
            {stopsList.length===0 &&
                <div>
                    <p className="subtitle addStopTitle">Add’l Stop Location</p>
                    <div className="input-container">
                        <div className="add-stop" onClick={() => addStop()}>
                            <p>Add Stop</p>
                            <BsPlusCircleFill size={25}/>
                        </div>
                    </div>
                </div>
            }
            {stopsList.map((stop,i) => {
                return(
                <div key={i}>
                <p className="subtitle addStopTitle">Add’l Stop Location</p>
                <div className="input-container">
                    <input
                            type="text"
                            className="full-width-container"
                            onChange={(e)=>handleAddLocationChange(e,i,"address")}
                            placeholder={"Address"}
                            value={stop.address}
                    />
                    <input
                            type="text"
                            className="full-width-container"
                            onChange={(e)=>handleAddLocationChange(e,i,"city")}
                            placeholder={"City"}
                            value={stop.city}
                    />
                    <input
                            type="text"
                            className="full-width-container"
                            onChange={(e)=>handleAddLocationChange(e,i,"state")}
                            placeholder={"State"}
                            value={stop.state}
                    />
                    <input
                            type="text"
                            className="full-width-container"
                            onChange={(e)=>handleAddLocationChange(e,i,"zipcode")}
                            placeholder={"Zip Code"}
                            value={stop.zipcode}
                    />
                    {(i===stopsList.length-1) &&
                    <div className="add-stop" onClick={() => addStop()}>
                        <p>Add Stop</p>
                        <BsPlusCircleFill size={25}/>
                    </div>
                    }
                    {(i!==stopsList.length-1 && stopsList.length!==1) &&
                    <div className="remove-stop" onClick={() => {
                            let removed = removeStop(stopsList,i);
                            setStops(removed);
                        }
                    }>
                        <p>Remove Stop</p>
                        <BsPlusCircleFill size={25}/>
                    </div>
                    }
                </div>
                </div>
                )
            })
            }
            <p className="subtitle">Notes for Driver</p>
            <div className="input-container">
                <input
                    type="text"
                    className="full-width-container"
                    onChange={handleDriverNotes}
                    placeholder={"Notes"}
                />
            </div>
            <Button
                label={"Order Now"}
                type={"yellow"}
                btnAction={"submit"}
          />
        </form>
      </Modal>
    </div>
    )
}

export default LimoLinkModal;