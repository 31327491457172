import React from 'react';
import './SafetyRatings.scss';
import Argus from '../../assets/argus.jpg';
import Wvyern from '../../assets/wyvern.png';

const SafetyRatings = () => {
  return (
    <div className="main-section">
      <div className="sub-section">
        <div className="gen-tem-light-section">
          <h1>Safety Ratings</h1>
          <div className="safety-rating-content">
            <p>
              At AIRBOOK ONE safety is our number one priority. Every operator
              is closely vetted and must adhere to strict standards. All
              operators are Federal Aviation Authority (FAA) Part 135 certified
              air carriers that have been audited by an independent Safety
              Auditor (see below for information on each auditor). Every trip is
              screened to verify that these strict standards are met or
              exceeded, in addition to all FAA standards. This includes the
              specific operator, aircraft and crew members for all trips. The
              operator exercises full operational control of each flight at all
              times.
            </p>
          </div>
        </div>
        <div className="rating-card rating-argus">
          <img src={Argus} alt="ARG/US" />
          <div>
            <h2>
              ARG/US
            </h2>
            <p>
              Provides audits of private jet operators spanning over 500
              operators globally. There are three levels: ARGUS Gold Rated
              Charter Operator, ARGUS Gold Rated Plus Charter Operator, and
              ARGUS Platinum Rated Charter Operator.
            </p>
          </div>
        </div>
        <div className="rating-card rating-wvyern">
          <img src={Wvyern} alt="Wyvern Wingman" />
          <div>
            <h2>Wyvern</h2>
            <p>
              Is a third-party rating system of private aircraft operators
              measuring adherence to ICAO (International Civil Aviation
              Organization) standards for safety management systems, emergency
              response plans, and internal evaluations. Included is a two-day,
              on-site audit recurring every 24 months.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyRatings;
