import React, {useEffect, useState} from 'react';
import BookingDetailsHeader from "./BookingDetailsHeader";
import ApplicationTab from "../../components/ApplicationTab";
import DetailsPanel from "./DetailsPanel";
import PassengersPanel from "./PassengersPanel";
import ReceiptPanel from "./ReceiptPanel";
import {GET_AUTH} from "../../utils/HTTP.utils";
import {API_GET_BOOKING_DETAILS} from "../../middleware/constants/url.constants";
import {useParams} from "react-router-dom";
import {JET_INFO} from "../../middleware/constants/aircarft.constants";
import {connect} from "react-redux";
import useAuthentication, {getIsUaAuthentication} from "../../hooks/useAuthentication";
import LoadingOverlay from 'react-loading-overlay';

import "./BookingDetails.scss"

const TABS = [
  {label: "Details", value: "Details"},
  {label: "Passengers", value: "Passengers"},
  {label: "Receipt", value: "Receipt"},
]

function BookingDetails({history}) {
  let [data,setData] = useState({});
  let [tab, setTab] = useState(TABS[0].value);
  let [refresh,setRefresh] = useState(false)
  const [loader, setLoader] = useState(true);
  const onTabChange = value => setTab(value)
  let {id} = useParams();

  const getTabData = (value, data, id) => {
    switch (value) {
      case TABS[0].value: {
        return <DetailsPanel getRefresh={getRefresh} id={id} data={data}/>
      }
      case TABS[1].value: {
        return <PassengersPanel data={data?.travellers ?? []} getRefresh={getRefresh} leadPassenger={data?.leadPassenger ?? {}} legs={data?.craftDetail?.itinerary.legs ?? []} bookingId={id} getPassengers={getPassengers}/>
      }
      case TABS[2].value: {
        return <ReceiptPanel id={id} status={data.status}/>
      }
    }
  }

  const getPassengers = (passengers) =>{
    setData({...data, travellers: passengers})
  }

  const getRefresh = () =>{
    setRefresh(true)
  }

  useEffect(() => {
    getIsUaAuthentication().then(({token}) => {
      GET_AUTH(API_GET_BOOKING_DETAILS, token, {"booking-id":id}).then(data => {
        if (Object.keys(data).length === 0) {
          history.push("/my-trips");
        }
        setData(data);
        setLoader(false);
      })
    })
    return () => {
      setLoader(false);
      setRefresh(false);
    };

  }, [refresh]);

  return (
    <div className="BookingDetails">
      <BookingDetailsHeader id={id} status={data.status}/>
      <ApplicationTab tabs={TABS} onTabChange={onTabChange} activeTab={"Details"}/>
      <div className="body">
        {getTabData(tab, data, id)}
      </div>
      <LoadingOverlay active={loader} spinner text='Loading your content...'/>
    </div>
  );
}


export default BookingDetails