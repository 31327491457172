import React, {useState} from 'react';
import './Aircrafts.scss';
import ArrowDown from '../../assets/icons/arrow-down-dark.png';
import Aircraft from '../../components/Aircraft/Aircraft';
import data from "./data";


const Aircrafts = ({history}) => {
  const [tab, setTab] = useState("Midsize");
  return (
    <div className="main-section">
      <div className="sub-section">
        <p className="aircraft-title">Aircraft</p>
        <div className="aircraft-tabs">
          {Object.keys(data).map((a, i) => (
            <button
              className={`${tab === a ? "btn-active" : "btn-inactive"}`}
              key={i}
              onClick={() => setTab(a)}
            >
              {a}
            </button>
          ))}
        </div>
        <Aircraft {...data[tab].aircraftDetails} />
        <div className="aircraft-type-info">
          <h2>{data[tab].label}</h2>
          <img src={ArrowDown} alt="scroll down" />
          <p className="info">{data[tab].info}</p>
        </div>
        <div className="aircrafts-cards-section">
          {data[tab].manufacturers.map(
            ({ image, title, list, link }, index) => {
              return (
                <div key={index} className="aircraft-card">
                  {/* commenting for now.. in future, we will activate this
                <div className='aircraft-card-header'>
                  <img src={image} alt=''/>
                </div> */}
                  <div className="aircraft-card-content">
                    <h3>{title}</h3>
                    <div className="aircraft-card-content-li">
                      {/* <ul> */}
                      {list.map((item, i) => {
                        return <h6 key={i}>{item}</h6>;
                      })}
                      {/* </ul> */}
                    </div>
                    <p onClick={() => history.push(link)}>
                      {/* commenting for now.. in future, we will activate this
                    <span>learn more</span>
                    <img src={ArrowDown} alt=''/> */}
                    </p>
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div className="disclaimer">
          <p>
            Disclaimer: These are only estimates, as aircraft type and
            configurations vary. Different aspects of your flight can change the
            amount of luggage allowed onboard. For more specific luggage
            information on specific aircraft, please contact our{" "}
            <a href="concierge">Flight Concierge</a> Team
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aircrafts;
