import React, {useEffect, useState} from 'react';
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import {GET_AUTH} from "../../utils/HTTP.utils";
import {API_GET_ALL_TRAVELLERS} from "../../middleware/constants/url.constants";
import { AiFillPlusCircle } from "react-icons/ai";
import LoadingOverlay from "react-loading-overlay";
import AddPassengerModal from "../../components/AddPassengerModal";

const Passengers = ({}) => {
  const [state, setState] = useState([]);
  const [loader, setLoader] = useState(true);
  const [addPassengerModal, setAddPassengerModal] = useState(false);
  const fetchData = async () => {
    let {token} = await getIsUaAuthentication();
    let data = await GET_AUTH(API_GET_ALL_TRAVELLERS, token, {});
    setState(data)
    setLoader(false)
  };
  useEffect(() => {
    fetchData()
  }, [addPassengerModal])
  if (!loader) {
    return (
      <div className="Passengers">
        <h2>Passengers</h2>
        <div className="action" onClick={() => setAddPassengerModal(true)}>
          <AiFillPlusCircle size={40} />
          <p className="label">Add Passenger</p>
        </div>
        <div className="tiles-container">
          {state.map((a, i) => (
            <div key={i} className="tile">
              <p className="name">{a.name}</p>
              <p className="info">
                {a.email && `${a.email},`} {a.tag}
              </p>
            </div>
          ))}
        </div>
        <AddPassengerModal
          status={addPassengerModal}
          onChange={setAddPassengerModal}
        />
      </div>
    );
  } else {
    return (
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    );
  }
};

Passengers.propTypes = {};

export default Passengers;
