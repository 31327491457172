import React from 'react';

const TermsOfService = () => {
  return (
    <div className='general-section' style={{ textAlign: "justify" }}>
      <>
        <p>
          <strong>AirBookOne Terms of Service</strong>
        </p>
        <p>Last Updated: May 24, 2022</p>
        <p>
          AirBookOne, LLC ("<strong>AirBookOne</strong>", "<strong>we</strong>",
          "<strong>us</strong>", or "<strong>our</strong>") provides private jet
          aircraft reservation and chartering services (the "
          <strong>Services</strong>") through its websites, mobile applications,
          and online hosted portals that support the Services (including,
          without limitation, the website located at{' '}
          <a href='http://www.airbookone.com' title='http://www.airbookone.com'>
            www.airbookone.com
          </a>
          ) (collectively, the "<strong>Site</strong>"). Your access to and use
          of the Site and Services is subject to these Terms of Service
          (including the <a href="/terms-of-sale" style={{ textDecoration: "none" }}>Terms of Sale</a>, these "<strong>Terms</strong>").{' '}
        </p>
        <p>
          <strong>
            PLEASE CAREFULLY REVIEW THE ARBITRATION PROVISION SET FORTH IN SECTION 18 BELOW (“ARBITRATION AND DISPUTE RESOLUTION AGREEMENT”), AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH US ON AN INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION. BY USING THE SITE AND SERVICES, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTOOD ALL OF THE TERMS OF THE ARBITRATION AND DISPUTE RESOLUTION AGREEMENT, AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT DECISION.{' '}
          </strong>
        </p>
        <p>
          <strong>
            YOU ACKNOWLEDGE AND AGREE THAT AIRBOOKONE IS NOT AN AIR CARRIER, FLIGHT OPERATOR, AIRCRAFT OWNER, OR INSURER, NOR IS AIRBOOKONE PARTY TO ANY AGREEMENTS ENTERED INTO OR TRANSACTIONS MADE BETWEEN OPERATORS AND CUSTOMERS DIRECTLY. AIRBOOKONE IS ACTING SOLELY AS A BROKER FACILITATING THE RESERVATION AND PURCHASE BY CUSTOMERS FOR PRIVATE JET AIRCRAFT FLIGHTS PROVIDED OR OPERATED BY THE SPECIFIED OPERATORS. THE OPERATOR EXERCISES FULL OPERATIONAL CONTROL OF EACH FLIGHT AT ALL TIMES. AIRBOOKONE HAS NO CONTROL OVER THE CONDUCT OF CUSTOMERS, OPERATORS, OR ANY ASPECTS OF AIRCRAFT AND EXPRESSLY DISCLAIMS ALL LIABILITY IN THIS REGARD.
          </strong>
        </p>
        <p>
          <strong>
            PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SITE AND SERVICES. BY CLICKING “AGREE”, REGISTERING AN ACCOUNT WITH US, OR OTHERWISE ACCESSING OR USING THE SITE AND SERVICES, YOU ARE AGREEING TO COMPLY WITH THESE TERMS. IF YOU ARE USING THE SITE AND SERVICES AS AN EMPLOYEE OR OTHER AUTHORIZED AGENT OF A COMPANY OR OTHER ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO ENTER INTO THESE TERMS AND BIND THE COMPANY OR OTHER ENTITY TO THE TERMS HEREOF. IF YOU (OR THE COMPANY OR ENTITY YOU REPRESENT) DO NOT AGREE TO BE BOUND BY THESE TERMS, DO NOT USE THE SITE OR SERVICES.
          </strong>
        </p>
        <p>
          <strong>1.</strong><span style={{ paddingLeft: "30px" }}></span> <strong>THE SERVICES</strong>
        </p>
        <p>
          The Services enable customers, visitors, and other users ("
            <strong>Customers</strong>") to reserve or charter and purchase via
            the Site private jet aircraft flights ("<strong>Flights</strong>")
            operated by our supplier air carriers and operators ("
            <strong>Operators</strong>", and together with Customers,
            collectively, "<strong>Users</strong>"). All Operators are Federal
            Aviation Administration ("<strong>FAA</strong>") Part 135 certified
            air carriers registered with the U.S. Department of Transportation
            (or applicable foreign equivalent) ("<strong>DOT</strong>"), and
            have been audited by an independent safety auditor to verify that
            all FAA and DOT standards and requirements are met, including with
            respect to the specific Operator, aircraft, and crew members for
            each Flight. Upon Customer request, relevant Operator certification,
            registration, and/or audit information may be provided through the
            applicable Operator to the extent available.{' '}
        </p>
        <p>
          By using the Services as a Customer, you hereby engage AirBookOne to act as your broker to facilitate and arrange for the reservation and purchase on your behalf of Flight(s) to be provided and operated by the specified Operator(s). You acknowledge and agree that AirBookOne is acting solely as a broker and is not an air carrier or aircraft owner, nor does AirBookOne operate any Flight that you authorize us to book on your behalf.
          </p>
        <p>
          <strong>2.</strong><span style={{ paddingLeft: "30px" }}></span><strong>CHANGES TO THE SERVICES AND THESE TERMS</strong>
        </p>
        <p>
          We expressly reserve the right to make any changes that we deem appropriate from time to time to the Site and/or Services. We reserve the right at any time to modify or discontinue the Site or Services (or any part thereof) without notice. To the maximum extent permitted under applicable law, we shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Site or Services.{' '}
        </p>
        <p>
          In addition, we may change these Terms at any time, and all such changes are effective immediately upon notice, which we may give by any means, including, but not limited to, by sending a message to the email address or mobile phone number that we have on file for you or by posting a revised version of these Terms or other notice on the Site. You should view these Terms often to stay informed of changes that may affect you. Your use of the Site and Services constitutes your continuing agreement to be bound by these Terms, as they are amended from time to time.{' '}
        </p>
        <p>
          <strong>3.</strong><span style={{ paddingLeft: "30px" }}></span><strong>PRIVACY POLICY</strong>
        </p>
        <p>
          Information that you provide to us or that we collect about you through your access to and use of the Site and Services is subject to our Privacy Policy, the terms of which are hereby incorporated by reference into these Terms. We encourage you to read and become familiar with our Privacy Policy.
          </p>
        <p>
          <strong>4.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>AGE REQUIREMENTS</strong>
        </p>
        <p>
          No one under the age of 18 may access or use the Site or Services unless supervised by a parent or legal guardian who is bound by these Terms. By accessing or using, or attempting to access or use, the Site or Services, you represent that you are at least 18 years of age and that you are able to enter into legally binding contracts, including, without limitation, these Terms (or, if you are under 18 years of age, that your parent or legal guardian has reviewed and agrees to be bound by these Terms on your behalf).
          </p>
        <p>
          <strong>5.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>RESERVATIONS AND FLIGHT OPERATIONS</strong>
        </p>
        <div style={{ paddingLeft: "43px" }}>
          <p>
            5.1<span style={{ paddingLeft: "30px" }}></span>To make a Flight reservation, you will need to provide your name, email address, mobile phone number, and payment card, electronic funds transfer (“EFT”), and/or other billing information. The Site may restrict how far in advance you are able to make a Flight reservation (for example, no more than 45 days in advance, or another time period as may be indicated on the Site from time to time). If you choose to initially hold your reservation with a deposit, you will
have up to the specified reservation hold time to make full payment of the remaining balance for your selected Flight. Once you make full payment, we will process your payment and will send you a confirmation email after processing is complete. Although we may send you an initial trip itinerary for your selected Flight upon booking or holding a reservation, YOUR RESERVATION IS NOT CONFIRMED UNTIL YOU RECEIVE A CONFIRMATION EMAIL FROM US EXPLICITLY STATING THAT YOUR PAYMENT HAS BEEN PROCESSED AND THAT YOUR RESERVATION IS CONFIRMED.{' '}
          </p>
          <p>
            5.2<span style={{ paddingLeft: "30px" }}></span>Once your reservation is confirmed, you will also need to provide the names and dates of birth of all of the passengers traveling with you so that we can share this information with the relevant Operator and complete security measures required by the Transportation Safety Administration and Department of Homeland Security. We and/or the applicable Operator may also require that all passengers provide certain information regarding their health status and recent travel history. Additional passenger information may be required for international Flights. All required passenger information must be provided at least forty-eight (48) hours prior to scheduled departure. Neither AirBookOne nor the relevant Operator will be liable to you for any cancellations, delays, problems, issues, or other liabilities arising from your failure to timely provide all required passenger information. By providing any information pertaining to, or on behalf of, any other passenger traveling with you, you represent and warrant that you have all consents, permissions, and authorizations necessary to provide such information to AirBookOne and the applicable Operator, and you will fully indemnify AirBookOne and the applicable Operator for your failure to obtain or maintain any such consent, permission, or authorization.{' '}
          </p>
          <p>
            5.3<span style={{ paddingLeft: "30px" }}></span>After your reservation has been confirmed and no later than forty-eight (48) hours prior to your scheduled departure, we will send you a final trip itinerary that includes the aircraft type and name of the Operator assigned to your Flight. During all phases of Flight, the Operator shall have and retain complete and exclusive Operational Control, as defined in FAR 135.77 and FAR 1.1, over the aircraft and flight crew, and shall exercise sole authority over initiating, conducting, and/or terminating any Flight. In addition, the pilot-in-command may, in his or her discretion, terminate or refuse to commence any Flight, or take any other flight-related action which, in the pilot-in-command’s judgment, is necessary to ensure the safety of the aircraft, passengers, and flight crew.
          </p>
          <p>
            5.4<span style={{ paddingLeft: "30px" }}></span>If, at any time before or during your Flight, the Operator determines that the aircraft cannot or should not be operated for reasons of safety or regulatory compliance, the Operator reserves the right to cancel your Flight or the remainder of your Flight. AirBookOne may use reasonable efforts to locate another aircraft for your Flight but will not be liable or responsible for any costs above what you initially paid for your original Flight. If we are unable to locate another aircraft for your Flight, or if you choose not to take your Flight with the new aircraft, you will be entitled to a full refund of the amount you initially paid for your original Flight, less any portion of the Flight already flown. Additional technical stops may be required for fuel, customs, or other operational reasons.{' '}
          </p>
          <p>
            5.5<span style={{ paddingLeft: "30px" }}></span>If your Flight is canceled due to inclement weather, we may use reasonable efforts to get you to your destination (or the airport closest to your destination) by locating an alternative Flight. If we are unable to locate an alternative Flight, or if you choose not to take an alternative Flight offered to you, you will receive a credit for the amount you initially paid for your original Flight in your Account, less any portion of the Flight already flown (if applicable). In the event your aircraft has landed or has to depart from an airport where there is a potential for accumulation

              of ice on the fuselage or wings of the airplane, the Operator may require the aircraft to undergo de-icing procedures. There may be a separate charge for this service by local fixed-base operators, and any such charges will be charged directly to the method of payment you originally provided upon completion of your Flight. The Operator crew may make you aware of any charges and alternative options for departure before commencing any de-icing procedures. Additional other charges may apply to any destination reached and/or for return flight of the aircraft and crew (with or without passengers) to home base. Without limiting any of the foregoing, in no event will AirBookOne be liable for any expenses incurred for replacement transportation (whether due to inclement weather, mechanicals, or any other reason).
          </p>
          <p>
            5.6<span style={{ paddingLeft: "30px" }}></span>For international Flights, the fee listed in the Flight listing may change due to changes in international handling fees, airport fees, and peak travel season. These charges are set by international airports and handlers and are not provided to AirBookOne until we finalize the departure. You are responsible for making sure that you meet all country entrance requirements and that your passport is valid for the date of travel. Please check and verify your travel documents to ensure that you meet all visa/passport and other entrance requirements of the country you are visiting. In the event you are not admitted to the country after you have arrived, AirBookOne will not be responsible for the costs of any return Flight. You are solely responsible for making any additional reservation for the return Flight and paying all associated costs and fees. You are responsible for making sure the luggage and carry-on items you are bringing with you are allowed by the customs of the country you are traveling to. You will be responsible for any costs and fines assessed by customs in the event of any violation of the rules and regulations of that country. In addition, when returning to the U.S., a U.S. customs stop may be required prior to reaching your final destination.
          </p>
        </div>
        <p>
          <strong>6.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>ACCOUNTS</strong>
        </p>
        <div style={{ paddingLeft: "43px" }}>
          <p>6.1<span style={{ paddingLeft: "30px" }}></span>
                In order to access and use certain parts of the Site and Services, you may be required to register and maintain an active Services account("<strong>Account</strong>").
              To set up an Account with us, you will be asked to provide certain information about yourself, such as your first and last name, physical address, email address, and mobile phone number. You will also be required to create a password for your Account and acknowledge and agree to these Terms and the terms of our Privacy Policy. You must provide complete and accurate information when setting up an Account.{' '}
          </p>

          <p>6.2<span style={{ paddingLeft: "30px" }}></span>
                You are responsible for maintaining the confidentiality of your password and Account. You agree to: (a) use a strong password and keep your password confidential and not share it with anyone else; (b) not transfer any part of your Account to anyone else; and (c) immediately notify us of any unauthorized use of your password or Account.
              </p>

          <p>6.3<span style={{ paddingLeft: "30px" }}></span>
                You agree at all times to keep current and promptly update your Account information, including, but not limited to, your email address and mobile phone number. Your failure to maintain accurate, complete, and up-to-date Account information may result in your inability to access and use the site and Services and/or suspension or termination of your Account.
              </p>
          <p>6.4<span style={{ paddingLeft: "30px" }}></span>
                You acknowledge and agree that we are authorized to act on instructions received through use of your password and/or Account, and that we may, but are not obligated to, deny access or block any transaction made through use of your password and/or Account without prior notice if we believe your password and/or Account are being used by someone other than you, or
                for any other reason. You are solely responsible for all activity that occurs under your Account unless you close it or report misuse.
          </p>
        </div>
        <p>
          <strong>7.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>TERMS OF SALE</strong>
        </p>
        <div style={{ paddingLeft: "43px" }}>
          <p>7.1<span style={{ paddingLeft: "30px" }}></span>
          By making a reservation for or chartering any Flight via
          the Site and Services, you expressly agree to the<a href="/terms-of-sale" style={{ textDecoration: "none" }}>Terms of Sale</a>, the terms of which are hereby incorporated by reference into these Terms.
              </p>
          <p>7.2<span style={{ paddingLeft: "30px" }}></span>
                We may use a third-party payment processor to charge you for Flight reservations made through the Site and Services. You expressly authorize AirBookOne, or a third-party payment processor on our behalf, to charge and/or initiate EFT debit transactions from the method of payment or financial account that you have provided upon reservation and to collect the full amount specified for the Flight you select, including any Initial Deposit, related fees, charges, and taxes. We may calculate taxes payable by you (if any) based on the billing information that you have provided to us at the time of purchase. By submitting your payment card and/or financial account information, you grant us the right to process and store your information, including with any third-party payment processor, which we may change from time to time. You agree that we will not be responsible for any failures of any third-party payment processor to adequately protect such information. The processing of payments will be subject to the terms, conditions, and privacy policies of the third-party payment processor in addition to these
               <a href="/terms-of-sale" style={{ textDecoration: "none" }}>Terms of Sale</a>. You acknowledge that we may change the third-party payment processor at any time, with or without notice to you, and move your information to other service providers that encrypt your information using secure socket layer technology (SSL) or other comparable security.
              </p>
        </div>
        <p>
          <strong>8.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>OPERATOR OFFERINGS AND THIRD-PARTY SERVICES</strong>
        </p>
        <p>
          The Services may be made available or accessed in connection with third-party services and content that we do not control, including, but not limited to, Flights, services, and offerings made available by any Operator or third party (<strong>Operator Offerings</strong>
            "). We have no control over and do not endorse any Operator,
            Operator Offerings, or other third-party services or content, and in
            no event shall we be responsible or liable for any conduct of or
            interactions you may have with any Operator or other third-party
            provider (whether or not related to the Services) or for your use of
            any Operator Offerings or any other third-party products or
            services.
          </p>
        <p>
          <strong>9.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>TEXT MESSAGING</strong>
        </p>
        <div style={{ paddingLeft: "43px" }}>
          <p>9.1<span style={{ paddingLeft: "30px" }}></span>
                You agree that we have your explicit consent to provide notices and messages to you for Account management, customer support, and promotional purposes in the following ways: (a) within the Service, or (b) sent to the contact information you provided to us (e.g., email or SMS or MMS to your mobile number).{' '}
            <strong>
              You can cancel the SMS service at any time by replying "STOP" to the last text message you received, or by using the features and settings available in your Account. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us.
                </strong>{' '}
          </p>
          <p>9.2<span style={{ paddingLeft: "30px" }}></span>
            <strong>Message and data rates may apply.</strong> Messages are sent from an automated system, and message frequency depends on your use of the Services. If you have any questions about your
text plan or data plan, please contact your wireless provider.
 For all other questions about the SMS services, you can send an email to &nbsp;
                <a
              href='mailto:customerservice@airbookone.com'
              title='mailto:customerservice@airbookone.com'
            >customersupport@airbookone.com.</a>{' '}
          </p>
        </div>
        <p>
          <strong>10.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>CONTENT AND INTELLECTUAL PROPERTY</strong>
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>10.1<span style={{ paddingLeft: "30px" }}></span>
                The content and other materials displayed or made available on or through the Site and Services, including, without limitation, text, information, data, content, Flight listings, photos, images, graphics, and illustrations (collectively, the
                ("<strong>Content</strong>"), are protected by copyright and/or other intellectual property laws. You agree to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Site and Services, and you will not use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, or otherwise exploit for any purpose any Content not owned by you (a) without the prior consent of the owner of that Content, or (b) in a way that violates someone else’s (including, but not limited to, our or any other User’s) rights.
              </p>
          <p>10.2<span style={{ paddingLeft: "30px" }}></span>
                You understand and agree that we and/or our licensors own all right, title, and interest in and to the Site, Services, and all Content. You acknowledge and agree that the Content may constitute valuable proprietary information that is protected by applicable intellectual property and other proprietary rights, laws, and treaties of the United States and other countries, and that you acquire no ownership interest by accessing or using the Site, Services, or Content. Such intellectual property and proprietary rights may include, but are not limited to, copyrights, trademarks and service marks, trade dress, and trade secrets, and all such rights are the property of AirBookOne or its licensors and Content-providers.
              </p>
          <p>10.3<span style={{ paddingLeft: "30px" }}></span>
                If you are a Customer, you expressly acknowledge and agree that AirBookOne shall not be responsible for any Content provided or otherwise made available by any Operators. The Operator is solely responsible for the accuracy and completeness of all information and data contained in its Flight listings and other Content. AirBookOne does not verify, validate, or endorse any Flight listing or other Content supplied by any Operator, and AirBookOne expressly disclaims any warranty related to the accuracy or completeness of any Flight listing or other Content displayed on the Site and Services.
              </p>
          <p>10.4<span style={{ paddingLeft: "30px" }}></span>
                While we strive to ensure accuracy of all pricing on the Site, occasionally there may be an error or omission related to the pricing of advertised Flights. We will use reasonable efforts to correct any pricing errors or omissions as soon as practicable after learning of them. We reserve the right to change, modify, substitute, suspend, or remove without notice any pricing information related to any Flight listing on the Site.{' '}
          </p>
          <p>10.5<span style={{ paddingLeft: "30px" }}></span>
                By submitting suggestions or other feedback regarding the Site or Services, you agree that we may use and share (with no obligation to do so) such feedback for any purpose without compensation to you. You agree to only provide content or information that does not violate the law nor anyone’s rights (including, without limitation, intellectual property rights).
              </p>
        </div>
        <p>
          <strong>11.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>USER CONDUCT</strong>
        </p>
        <p>
          You may access and use the Site only in connection with your use of the Services and Content as permitted herein and only for your personal use (or, if accessing the Site on behalf of your company, only for your company’s internal business use as permitted herein). Any other access
to or use of the Site constitutes a violation of these Terms and may violate applicable copyright, trademark, or other laws.{' '}
        </p>
        <p>
          In accessing and using the Site, Services, and any Content, you agree that you will comply with all applicable federal, state, local, and foreign laws, including, without limitation, data privacy laws, copyright and other intellectual property laws, cyber harassment, anti-spam laws, and other regulatory requirements.{' '}
        </p>
        <p>
          In addition, in accessing and using the Site, Services, and any Content, you agree that you will NOT:
          </p>
        <ul>
          <li>
            <p>
              Circumvent, disable, or otherwise interfere with any security-related features of the Site or Services, including, without limitation, any features that enforce limitations on the use of the Site, Services, or any Content;
              </p>
          </li>
          <li>
            <p>
              Reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, or algorithms of the Site, Services, or Content;
              </p>
          </li>
          <li>
            <p>
              Copy, modify, adapt, translate, or create derivative works based on the Site, Services, or Content;
              </p>
          </li>
          <li>
            <p>
              Reproduce, redistribute, duplicate, sell, resell, lease, sublicense, time-share, or exploit for any commercial purpose any portion of the Site, Services, or Content, except as permitted hereunder or otherwise expressly authorized by us in writing;{' '}
            </p>
          </li>
          <li>
            <p>
              Remove, alter, cover, or distort any copyright notice or trademark legend, author attribution, or other notice placed on or contained within the Site, Services, or any Content;
              </p>
          </li>
          <li>
            <p>
              Access, use, or copy any portion of the Site, Services, or Content through the use of bots, spiders, scrapers, web crawlers, indexing agents, or other automated devices or mechanisms;{' '}
            </p>
          </li>
          <li>
            <p>
              Use the Site or Services to store or transmit viruses, worms, time bombs, Trojan horses, or other malicious code, or any unsolicited messages, “spam” or any other content or material in violation of applicable law;
              </p>
          </li>
          <li>
            <p>
              Attempt to interfere with the proper working of the Site or Services or otherwise impair, overburden, or disable the same; or
              </p>
          </li>
          <li>
            <p>
              Otherwise engage in any conduct that restricts or inhibits any other User from using or enjoying the Site or Services.
              </p>
          </li>
        </ul>
        <p>
          <strong>12.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>TERMINATION OF ACCESS</strong>
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>12.1<span style={{ paddingLeft: "30px" }}></span>
                We may, in our sole discretion, without liability, and without notice to you, immediately suspend, limit, and/or terminate your access to the Site, Services, and/or your Account for any reason, including, without limitation: (a) if we believe in our sole discretion that you have violated these Terms or any applicable laws or regulations; (b) at the request of law enforcement, government agencies, or courts; (c) if you ask us to close your Account; (d) if we discontinue or materially modify the Site or Services (or any part thereof); or (e) if we believe in our sole

                discretion that your use or access to the Site, Services, or your Account may create risk (including, but not limited to, legal risk) for us, our affiliates, contractual partners, or Users. Upon any termination, you will lose the right to access and use the Site and Services and your Account (if any) will be closed.
              </p>
          <p>12.2<span style={{ paddingLeft: "30px" }}></span>
                Any suspension or termination shall not affect your obligations to us under these Terms. The provisions of these Terms which by their nature should survive the suspension or termination of your access to or use of the Site, Services, and/or your Account, shall survive including, but not limited to, the “Content and Intellectual Property”, “Indemnification and Release”, “Disclaimers”, “Limitation of Liability”, “Arbitration and Dispute Resolution Agreement”, and “Miscellaneous” sections of these Terms.
              </p>
        </div>
        <p>
          <strong>13.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>SECURITY</strong>
        </p>
        <p>
          AirBookOne takes such commercially reasonable measures as it deems appropriate to secure and protect information transmitted to and from the Site. Nevertheless, we cannot and do not guarantee that any such transmissions are or will be totally secure. You are responsible for maintaining the confidentiality of any information about you, including, without limitation, any password used in connection with your use of the Site. You agree to notify us immediately if you discover loss of or access to such information by another party not under your control or supervision.
          </p>
        <p>
          <strong>14.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>DEVICES AND NETWORK ACCESS</strong>
        </p>
        <p>
          You are responsible for obtaining the data network access necessary to access and use the Site and Services. Your mobile network's data and messaging rates and fees may apply when using the Site and Services from a mobile and/or wireless-enabled device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Site and Services and any updates thereto. We do not guarantee that the Site or Services, or any portion thereof, will function on any particular hardware or devices. In addition, the Site and Services may be subject to malfunctions and delays inherent in the use of the Internet and cellular and electronic communications.
          </p>
        <p>
          <strong>15.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>INDEMNIFICATION AND RELEASE</strong>
        </p>
        <p>
          You agree to indemnify and hold harmless AirBookOne and its officers, members, managers, owners, employees, contractors, and agents (collectively, the "<strong>AirBookOne Parties</strong>")
          from and against any and all claims, demands, losses, liabilities, damages, costs, and expenses (including attorneys' fees and costs) arising out of or relating to: (a) your use of the Site and Services; (b) your breach or violation of any of these Terms; (c) your violation of the rights of any other User or any third party (including, without limitation, violation of any privacy or intellectual property or other proprietary rights); (d) your violation of any applicable law or regulation; (e) any data or information that you submit to or transmit using the Site and Services; or (f) any relationship, transaction, interaction, or dispute that you have with any other User, including, without limitation, any aspect of any Flight or aircraft that you take or operate (applicable).
          </p>
        <p>
          Without limiting any of the foregoing, if you are a Customer, you acknowledge and agree that, as between you and AirBookOne, you are solely responsible for your interactions with any Operator. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, YOU HEREBY RELEASE THE AIRBOOKONE PARTIES FROM ANY AND ALL CLAIMS OR LIABILITY

          RELATED TO ANY OPERATOR OFFERING, ANY ACTION OR INACTION BY AN OPERATOR, INCLUDING, WITHOUT LIMITATION, ANY HARM CAUSED TO YOU BY ANY ACTION OR INACTION OF AN OPERATOR, ANY OPERATOR’S FAILURE TO COMPLY WITH APPLICABLE LAW, AND/OR ANY OTHER PRODUCT OR SERVICE PURCHASED OR OBTAINED BY YOU FROM ANY OPERATOR OR OTHER THIRD PARTY.
          </p>
        <p>
          <strong>16.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>DISCLAIMERS</strong>
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>16.1<span style={{ paddingLeft: "30px" }}></span>
                YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT THE SITE, SERVICES, AND ALL CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITH ALL FAULTS. WITHOUT LIMITING THE FOREGOING AND TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE AIRBOOKONE PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES: (A) THAT THE SITE, SERVICES, AND/OR CONTENT WILL MEET YOUR REQUIREMENTS OR SATISFACTION; (B) CONCERNING THE AVAILABILITY, ACCURACY, COMPLETENESS, SECURITY, USEFULNESS, TIMELINESS, OR INFORMATIONAL CONTENT OF THE SITE, SERVICES, AND/OR CONTENT; (C) OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE; (D) FOR PRODUCTS OR SERVICES RECEIVED THROUGH OR ADVERTISED ON THE SITE OR SERVICES; (E) THAT YOUR USE OF THE SITE, SERVICES, AND/OR ANY CONTENT WILL BE SECURE OR UNINTERRUPTED; AND (F) THAT THE SITE, SERVICES, AND/OR ANY CONTENT WILL BE ERROR-FREE OR THAT ERRORS IN THE SITE, SERVICES, AND/OR ANY CONTENT WILL BE CORRECTED.{' '}
          </p>
          <p>16.2<span style={{ paddingLeft: "30px" }}></span>
                WITHOUT LIMITING ANY OF THE FOREGOING, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT AIRBOOKONE DOES NOT PROVIDE OR SUPPLY AIRCRAFT OR TRANSPORTATION SERVICES AND IS NOT A FLIGHT OR AIRCRAFT OPERATOR OR A TRANSPORTATION CARRIER OR PROVIDER. AIRBOOKONE HAS NO RESPONSIBILITY OR LIABILITY FOR ANY FLIGHTS OR TRANSPORTATION SERVICES PROVIDED TO YOU BY ANY OPERATOR OR ANY OTHER THIRD PARTY (WHETHER OR NOT RELATED TO THE SERVICES). AIRBOOKONE DOES NOT ENDORSE OR ASSESS THE SUITABILITY, LEGALITY, OR ABILITY OF ANY OPERATOR, AND YOU EXPRESSLY WAIVE AND RELEASE THE AIRBOOKONE PARTIES FROM ANY AND ALL LIABILITY, CLAIMS, OR DAMAGES ARISING FROM OR IN ANY WAY RELATED TO THE OPERATOR. FURTHER, YOU ACKNOWLEDGE THAT AIRBOOKONE DOES NOT MONITOR ANY OPERATOR’S ON-GOING COMPLIANCE WITH ANY LICENSING AND/OR PERMITTING RULES OR REGULATIONS, AND AIRBOOKONE WILL NOT BE RESPONSIBLE FOR ANY LIABILITY, CLAIMS, OR DAMAGES ARISING FROM OR IN ANY WAY RELATED TO ANY OPERATOR’S FAILURE TO MAINTAIN A CURRENT LICENSE AND/OR PERMIT.{' '}
          </p>
          <p>16.3<span style={{ paddingLeft: "30px" }}></span>
                AIRBOOKONE WILL NOT BE A PARTY TO DISPUTES OR NEGOTIATIONS OF DISPUTES BETWEEN CUSTOMERS AND OPERATORS. THE QUALITY OF THE FLIGHTS AND TRANSPORTATION SERVICES RESERVED OR PURCHASED THROUGH THE SITE AND SERVICES IS ENTIRELY THE RESPONSIBILITY OF THE OPERATOR. YOU UNDERSTAND THAT BY USING THE SITE AND SERVICES, YOU MAY BE EXPOSED TO TRANSPORTATION THAT IS POTENTIALLY DANGEROUS, HARMFUL,

                OR OTHERWISE UNSAFE AND THAT YOU USE THE SITE AND SERVICES AT YOUR OWN RISK. IN NO EVENT WILL ANY OF THE AIRBOOKONE PARTIES BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF ANY CUSTOMER, OPERATOR, OR ANY OTHER USER (WHETHER OR NOT RELATED TO THE SERVICES), INCLUDING WITHOUT LIMITATION, DEATH, BODILY INJURY, EMOTIONAL DISTRESS, PROPERTY DAMAGE, AND/OR ANY OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS WITH OTHER USERS OF, PERSONS YOU MEET THROUGH THE SITE OR SERVICES, OR FLIGHTS YOU BOOK THROUGH THE SITE OR SERVICES
              </p>
          <p>16.4<span style={{ paddingLeft: "30px" }}></span>
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OR FROM THE SITE OR SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, DISCONTINUATION OF USE OF THE SITE AND SERVICES IS YOUR SOLE RIGHT AND REMEDY FOR ANY DISSATISFACTION WITH THE SITE, SERVICES, OR ANY CONTENT.
          </p>
        </div>
        <p>
          <strong>17.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>LIMITATION OF LIABILITY</strong>
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>17.1<span style={{ paddingLeft: "30px" }}></span>
                We may use third parties to provide certain features, functions, and aspects of the Site and Services. WE WILL NOT BE LIABLE TO YOU FOR THEIR ACTS OR OMISSIONS. IN ADDITION, IN NO EVENT WILL THE AIRBOOKONE PARTIES BE LIABLE TO YOU FOR ANY DIRECT OR INDIRECT DAMAGES ARISING OUT OF RELATING TO ANY OPERATOR OFFERINGS, THE CONDUCT OF ANY OTHER USER, OR YOUR RELATIONSHIP, TRANSACTIONS, INTERACTIONS, OR DISPUTES THAT YOU MAY HAVE WITH ANY OTHER USER.
              </p>
          <p>17.2<span style={{ paddingLeft: "30px" }}></span>
                TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO CIRCUMSTANCES, INCLUDING, WITHOUT LIMITATION, THE NEGLIGENCE OF ANY PARTY, WILL THE AIRBOOKONE PARTIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF USE, BUSINESS INTERRUPTION, DATA LOSS, OR OTHER INTANGIBLE LOSSES (EVEN IF THE AIRBOOKONE PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM, ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH YOUR USE OF, OR YOUR INABILITY TO USE, THE SITE, SERVICES, OR ANY CONTENT UNDER ANY THEORY OF LIABILITY. YOUR SOLE REMEDY, AND OUR AGGREGATE LIABILITY IN CONNECTION WITH THESE TERMS OR YOUR USE OF THE SITE, SERVICES, AND ANY CONTENT, SHALL IN ALL EVENTS BE LIMITED TO THE TOTAL AMOUNT PAID BY YOU TO AIRBOOKONE FOR THE SERVICES IN THE PRECEDING THREE (3) MONTHS. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY OR EXCLUSION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE PROVISIONS MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, OUR LIABILITY IS LIMITED TO THE MAXIMUM EXTENT PERMITTED BY THE LAW.
              </p>
          <p>17.3<span style={{ paddingLeft: "30px" }}></span>
                WITHOUT LIMITING ANY OF THE FOREGOING, THE AIRBOOKONE PARTIES SHALL NOT BE LIABLE FOR ANY INJURY, DAMAGE, LOSS, EXPENSE, OR OTHER IRREGULARITY CAUSED BY THE DEFECT OF ANY AIRCRAFT, OR THE NEGLIGENCE OR BAD ACT OF ANY OPERATOR OR OTHER COMPANY OR PERSON ENGAGED IN CONVEYING THE CUSTOMER OR CARRYING OUT THE ARRANGEMENTS FOR A FLIGHT, OR BY REASON OF ACCIDENT, DELAY, FLIGHT, SCHEDULE, CHANGE, CANCELLATION, SICKNESS, WEATHER, STRIKES, WAR, QUARANTINE, ACTS OF ANY GOVERNMENTAL OR REGULATORY AUTHORITY, OR ANY SIMILAR CAUSE. THE AIRBOOKONE PARTIES SHALL NOT BE LIABLE FOR LOSS OR DAMAGE TO ANY PROPERTY (INCLUDING, BUT NOT LIMITED TO, BAGGAGE, PERSONAL EFFECTS, OR CARGO). Customer agrees to reimburse the AirBookOne Parties for all costs and expenses incurred by any AirBookOne Party or Operator in connection with any loss or damage to the aircraft caused by the negligence or misconduct of Customer, its employees, agents, or guests. We reserve the right to charge Customer for repair or replacement of damaged items and/or require Customer to reimburse the Operator therefor. Additionally, Customer agrees to reimburse the AirBookOne Parties for all costs and expenses incurred by the AirBookOne Parties and/or the Operator where the aircraft requires substantial cleaning services upon completion of Customer’s Flight (e.g., where extraordinary cleaning expenses are required due to Customer’s negligence or misconduct).
              </p>
        </div>
        <p>
          <strong>18.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>ARBITRATION AND DISPUTE RESOLUTION AGREEMENT</strong>
        </p>
        <p>
          PLEASE READ THIS FOLLOWING CLAUSES CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING, BUT NOT LIMITED TO, YOUR RIGHT TO FILE A LAWSUIT IN COURT.
          </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>18.1<span style={{ paddingLeft: "30px" }}></span>
                You agree that any dispute, claim, or controversy between you and AirBookOne arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Site or Services (collectively, "
                <strong>Disputes</strong>") will be resolved exclusively by binding arbitration between you and AirBookOne, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party's copyrights, trademarks, trade secrets, patents, or other intellectual property rights, or where a party’s conduct may cause the other irreparable injury. The provisions of this Section 18 constitute the parties’ written agreement to arbitrate Disputes under the Federal Arbitration Act.{' '}
          </p>
          <p>18.2<span style={{ paddingLeft: "30px" }}></span>
                The arbitration shall be administered by the Judicial Arbitration & Mediation Services ("<strong>JAMS</strong>")
              and shall be heard by a single arbitrator, pursuant to the Arbitration Rules and Procedures then in effect (the "
                <strong>JAMS Rules</strong>"), except as modified by this
                Section 18.
              </p>
          <p>18.3<span style={{ paddingLeft: "30px" }}></span>
                To begin an arbitration proceeding, a party must submit the Dispute by making a demand for arbitration, as detailed at{' '}
            <a
              href='https://www.jamsadr.com/'
              title='https://www.jamsadr.com/'
            >
              https://www.jamsadr.com/
                </a>
                , and simultaneously send a copy of the completed demand to the other party. Payment of all filing, administration and arbitrator fees will be governed by the JAMS rules. AirBookOne will reimburse those fees for Disputes totaling less than $10,000 if you are the prevailing party in such arbitration. AirBookOne will not seek attorneys’ fees and costs in arbitration unless the arbitrator determines that a Dispute initiated by you is frivolous. The arbitration will be conducted based upon written submissions, unless you request and/or the arbitrator determines that a telephone or in-person hearing is necessary. If the
arbitrator grants the request or determines that an in-person hearing is necessary, the hearing will proceed in Cook County, Illinois, unless the arbitrator determines or AirBookOne agrees that the matter should proceed in the county where you reside.
              </p>
          <p>18.4<span style={{ paddingLeft: "30px" }}></span>
                The arbitrator will apply and be bound by these Terms, apply applicable law and the facts, and issue a reasoned award within the time frame specified in the JAMS Rules. The arbitrator's decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator's award damages must be consistent with the terms of the "Limitation of Liability" section above as to the types and the amounts of damages for which a party may be held liable. You and AirBookOne also agree that the arbitrator may not award multiple or punitive damages. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant's individual claim.{' '}
          </p>
          <p>18.5<span style={{ paddingLeft: "30px" }}></span>
                EACH PARTY AGREES THAT IT SHALL BRING ANY DISPUTE AGAINST THE OTHER PARTY IN ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE PROCEEDING, OR AS AN ASSOCIATION. IN ADDITION, EACH PARTY AGREES THAT DISPUTES SHALL BE ARBITRATED ONLY ON AN INDIVIDUAL BASIS, AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. THE ARBITRATOR DOES NOT HAVE THE POWER TO VARY THESE PROVISIONS.
              </p>
        </div>
        <p>
          <strong>19.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>MISCELLANEOUS</strong>
        </p>
        <div style={{ paddingLeft: "50px" }}>
          <p>19.1<span style={{ paddingLeft: "30px" }}></span>
                These Terms and the Privacy Policy (as each may be revised and amended from time to time according to their respective terms) collectively constitute the entire agreement with respect to your access to and use of the Site, Services, and Content.
              </p>
          <p>19.2<span style={{ paddingLeft: "30px" }}></span>
                Our electronically or otherwise properly stored copy of these Terms will be deemed to be the true, complete, valid, authentic, and enforceable copy, and you agree that you will not contest the admissibility or enforceability of our copy of these Terms in connection with any action or proceeding arising out of or relating to these Terms.
              </p>
          <p>19.3<span style={{ paddingLeft: "30px" }}></span>
                These Terms shall be construed in accordance with the laws of the State of Illinois without regard to its conflict of laws rules that would result in the application of the laws of a jurisdiction other than the State of Illinois. If for any reason a Dispute proceeds in court, you and AirBookOne: (a) agree that any such Dispute may only be instituted in a state or federal court located in Cook County, Illinois and waive any defenses or objections based on the jurisdiction, venue, or convenience of this exclusive venue; (b) irrevocably consent and submit to the exclusive personal jurisdiction and venue of such courts for resolution of such Disputes; and (c) AGREE TO WAIVE ANY RIGHT TO A TRIAL BY JURY.
              </p>
          <p>19.4<span style={{ paddingLeft: "30px" }}></span>
                These Terms do not confer any rights, remedies, or benefits upon any person other than AirBookOne and you.
              </p>
          <p>19.5<span style={{ paddingLeft: "30px" }}></span>
                We may assign our rights and obligations under these Terms, in whole or in part, at any time to any third party without notice. You may not assign these Terms or any rights or obligations hereunder without our prior written consent.{' '}
          </p>
          <p>19.6<span style={{ paddingLeft: "30px" }}></span>
                Our waiver of any breach of these Terms will not be a waiver of any preceding or subsequent breach thereof.
              </p>
          <p>19.7<span style={{ paddingLeft: "30px" }}></span>
                Failure by AirBookOne to perform any obligation hereunder shall be excused if and for so long as such breach or failure to perform is caused by acts of God, decisions or actions of any governmental or regulatory authorities (including changes to applicable law), riots, strikes, acts of war, civil unrest, epidemics, pandemics, fire, flood, weather, power or communication line failures, Internet failures, earthquakes or other disasters, or other similar causes.{' '}
          </p>
          <p>19.8<span style={{ paddingLeft: "30px" }}></span>
                If any provision of these Terms is held to be invalid, illegal, or unenforceable, the remaining provisions of these Terms will not be affected, and the invalid, illegal, or unenforceable provision will be replaced by such valid, legal, and enforceable provision as comes closest to the intention underlying the invalid or enforceable provision.
              </p>
          <p>19.9<span style={{ paddingLeft: "30px" }}></span>
                Possible evidence of use of the Site or Services for illegal purposes will be provided to law enforcement authorities.
              </p>
        </div>
        <p>
          <strong>20.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>END USER TERMS REQUIRED BY APPLE</strong>
        </p>
        <p>
          For Users of our mobile application (the "<strong>App</strong>"),
          you acknowledge and agree that: (a) these Terms are concluded between you and AirBookOne, and not Apple, Inc. ("
            <strong>Apple</strong>");(b) AirBookOne and its licensors, and not Apple, are solely responsible for the App and the content therein; (c) Apple has no responsibility whatsoever to furnish any maintenance and support services with respect to the App; (d) in the event of any failure of the App to conform to any applicable warranty, you may notify Apple and Apple will refund the purchase price you paid for the App, if applicable; (e) to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App; (f) Apple is not responsible for any claims, losses, liabilities, damages, costs, or expenses
            ("<strong>Claims</strong>") that you may have arising out of your use of the App, and you agree to contact AirBookOne regarding any such Claims; (g) Apple will have no responsibility whatsoever for the investigation, defense, settlement, or discharge of any third-party claim that the App infringes such third party’s intellectual property rights; and (h) Apple and its subsidiaries are third party beneficiaries of these Terms and, upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) as a third party beneficiary to enforce these Terms against you. You represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.{' '}
        </p>
        <p>
          <strong>21.</strong><span style={{ paddingLeft: "30px" }}></span>
          <strong>ASSISTANCE AND QUESTIONS </strong>
        </p>
        <p>
          If you need any assistance with booking, modifying, or cancelling any reservation, please contact our concierge service by calling 1.312.761.5080 and we will be happy to assist you. In addition, if booking a Flight with a destination outside of North America, Mexico, and the Caribbean, you must call our concierge service to directly assist with booking.
        </p>
        <p>
          If you have any general questions regarding the Site, Services, or these Terms, please contact us at{' '}
          <a
            href='mailto:contactus@airbookone.com'
            title='mailto:contactus@airbookone.com'
          >
            contactus@airbookone.com
          </a>{' '}
          or:
        </p>
        <p>AirBookOne, LLC </p>
        <p style={{ marginTop: "-15px" }}>
          <a
            href='mailto:customerservice@airbookone.com'
            title='mailto:customerservice@airbookone.com'
          >
            customerservice@airbookone.com
          </a>
        </p>
      </>
    </div >
  );
};

export default TermsOfService;
