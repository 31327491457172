import React, {useState} from 'react';
import "./MyAccount.scss";
import MyAccountHeader from "./MyAccountHeader";
import ApplicationTab from "../../components/ApplicationTab";
import PersonalInfo from "./PersonalInfo";
import AbReward from "./ABReward";
import Wallet from "./Wallet";
import Notification from "./Notification";
import Passengers from "./Passengers";
// import ExcludedAircraft from './ExcludedAircraft';

const TABS = [
  {label: "Notifications", value: "Notifications"},
  {label: "Personal Info", value: "Personal Info"},
  {label: "Passengers", value: "Passengers"},
  {label: "AB1 Rewards", value: "AB1 Rewards"},
  {label: "Wallet", value: "Wallet"},
  // {label: "Excluded Aircraft", value: "Excluded Aircraft"}
]

const getTabData = value => {
  switch (value) {
    case TABS[0].value: {
      return <Notification/>
    }
    case TABS[1].value: {
      return <PersonalInfo/>
    }
    case TABS[2].value: {
      return <Passengers/>
    }
    case TABS[3].value: {
      return <AbReward/>
    }
    case TABS[4].value: {
      return <Wallet/>
    }
    // case TABS[5].value: {
    //   return <ExcludedAircraft/>
    // }
  }
}


function MyAccount({}) {
  let [tab, setTab] = useState("Personal Info");
  const onTabChange = value => setTab(value)
  return (
    <div className="MyAccount">
      <MyAccountHeader/>
      <ApplicationTab tabs={TABS} onTabChange={onTabChange} activeTab={"Personal Info"}/>
      <div className="body">
        {getTabData(tab)}
      </div>
    </div>
  );
}

export default MyAccount;