import {put, takeLatest} from "redux-saga/effects";
import Constants from "../constants";
import {POST_AUTH} from "../../utils/HTTP.utils";
import {API_INITIATE_PAYMENT} from "../constants/url.constants";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";

function* initPayment(action) {
  try {
    let {token} = yield getIsUaAuthentication();
    let data = yield POST_AUTH(API_INITIATE_PAYMENT, action.payload, token);
    yield put({type: Constants.INIT_PAYMENT_SUCCESS, payload: data})
  } catch (error) {
    console.error(error)
    yield put({type: Constants.INIT_PAYMENT_ERROR})
  }
}

export function* initPaymentWatch() {
  yield takeLatest(Constants.INIT_PAYMENT, initPayment)
}
