import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [modal1, setModal1] = useState(null);

  const buttonClickHandler = (type) => {
    setModal1(type);
  };

//   const closeModal = () => {
//     setModal(null);
//   };

  return (
    <AppContext.Provider value={{buttonClickHandler }}>
      {children}
    </AppContext.Provider>
  );
};