import React from 'react';
import PlaneCircleFilled from "../../assets/icons/plane-circle-filled.svg";
import GreyEditCircleFilled from "../../assets/icons/grey-edit-circle-filled.svg";
import {useHistory} from "react-router-dom";
import {MULTI_CITY} from "../../middleware/constants/trip.constants";


function BookAFlightHeader({searchOptions = {}}) {
  let history = useHistory();
  return (
    <div className="BookAFlightHeader">
      {/* <h1>Book a Flight</h1> */}
      <div className="route-info-container" onClick={() => {
        history.push("/", {prefill: true});
      }}>
        <img src={PlaneCircleFilled} className="plane-circle-filled" alt={"plane-circle-filled"}/>
        {searchOptions?.routeType !== MULTI_CITY && <div className="route-info">
          <p className="airport">{searchOptions?.travelCities?.[0].origin}</p>
          <p className="suffix">TO</p>
          <p className="airport">{searchOptions?.travelCities?.[0].destination}</p>
        </div>}
        {searchOptions?.routeType === MULTI_CITY && <div className="route-info">
          <p className="airport">{searchOptions?.travelCities?.[0].origin}</p>
          <p className="suffix">TO</p>
          <p
            className="airport">{searchOptions?.travelCities?.[searchOptions?.travelCities?.length - 1].destination}</p>
        </div>}
        <img src={GreyEditCircleFilled} className="grey-edit-circle-filled" alt="grey-edit-circle-filled"/>
      </div>
    </div>
  );
}

export default BookAFlightHeader;