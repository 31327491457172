import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {FaArrowRight} from 'react-icons/fa';
import ChangePasswordModal from '../../components/ChangePasswordModal';
import Input from "react-phone-number-input/input";
import { isValidPhoneNumber } from 'react-phone-number-input';
import LoadingOverlay from "react-loading-overlay";
import Button from "../../components/Button";
import {API_PROFILE} from '../../middleware/constants/url.constants'
import { GET_AUTH, POST_AUTH } from '../../utils/HTTP.utils';
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import { toast } from "react-toastify";

function PersonalInfo({ }) {
  const [state, setState] = useState({ firstname: "", lastname: "", phone: "", email: "", displayEmail : "" });
  const [amplify,setAmplify] =useState({})
  const [changeModal, setChangeModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [invalidPhoneError, setInvalidPhoneError] = useState(false);

  // const [fields, setFields] = useState({
  //   firstName: userData?.firstname || "",
  //   lastName:  userData?.lastname || "",
  //   phoneNumber:  userData?.phone || "",
  //   email:  userData?.email || ""
  //   //telCode : "+1",
  // });

  const [errorFields, setErrorFields] = useState({
    firstname: false,
    lastname: false,
    phone: false,
    email: false,
    invalidEmail: false,
    invalidphoneNo: false,
    //telCode : false,
  });

  useEffect(() => {
    getIsUaAuthentication().then(({token})=>{
      GET_AUTH(API_PROFILE,token).then((data)=>{
        console.log(data)
        setState(data)
      })
    })
    
    Auth.currentAuthenticatedUser().then((a) => {
      setAmplify(a.attributes)
    });
   
  }, []);

  const userHandleChange = key => e => {
      setState({ ...state, [key]: e.target.value.trim() });
  }

  const handlePhone = e => {
    setState({ ...state, phone: e });
  }

  const onSubmit = e => {
    e.preventDefault();
    let {firstname,lastname,phone,email,displayEmail} = state
    if (isFormValidOnSubmit()) {
      setLoader(true);

      getIsUaAuthentication().then(({token})=>{
        POST_AUTH(API_PROFILE,{
          firstname,
          lastname,
          email,
          phone,
          displayEmail
        },token);
      }).then((data)=>{
        toast.success("Changes Saved Successfully")
      })
      setLoader(false);
    }
  }

  const isFormValidOnSubmit = () => {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
   // let phoneRegex = /^\d/;
    const errors = {
      firstname: false,
      lastname: false,
      phone : false,
      email: false,
      invalidEmail: false,
      invalidphoneNo: false,
    };

    if (state.firstname === "") {
      errors.firstname = true;
    }

    if (state.lastname === "") {
      errors.lastname = true;
    }
   
    if (state.phone === undefined || state.phone === "") {
      errors.phone = true;
    }
    else {
      errors.phone = false;
      if(isValidPhoneNumber(state.phone)=== false) {
        errors.invalidphoneNo = true;
      }else{
        errors.invalidphoneNo = false;
      }
    }
    if (state.email === "") {
      errors.email = true;
      setInvalidEmailError(false)
    } else {
      if (!emailRegex.test(state.email)) {

        errors.invalid=true;
        setInvalidEmailError(true)
      }else{

        errors.invalid=false;
        setInvalidEmailError(false)
      }
    }
    console.log(errors);
    setErrorFields(errors);
    if ((Object.values(errors).some((error) => error === true))) {

      console.log(invalidEmailError);


      return false;
    }
    return true;
  };


  const isFieldValid = (e) => {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const { name, value } = e.target;
  
    let errors = false;
  
    if (name === 'firstname' && value === "") {
      errors = true;
    }
  
    if (name === 'lastname' && value === "") {
      errors = true;
    }
  
    if (name === 'phone' && value === "") {
      errors = true;
    }
  
    if (name === 'email' && value === "") {
      errors = true;
      setInvalidEmailError(false)
    } 

    //  else if (name === 'email' && !emailRegex.test(value)) {
    //  // errors=true;
    //  setErrorFields((prev) => ({
    //   ...prev,
    //   invalidEmail: true,
    // }));
    // }
  
    setErrorFields((prev) => ({
      ...prev,
      [name]: errors,
      //invalidEmail: false,
    }));
  };


  // const updateUserInfo = async (payload) => {
  //   const user = await Auth.currentAuthenticatedUser();
  //     let update = await Auth.updateUserAttributes(user, {
  //       ...payload,
  //       phone_number: payload.phone_number,
        
  //     });
  //   setLoader(false);
  //     toast.success("Changes were saved successfully.")
  // }
  return (
    <div className="PersonalInfo">
      <h2>Personal Info</h2>
      <form onSubmit={onSubmit}>
        <div className="personal-info-inputs">
          <div className="key-value">
            <p className="key">First Name</p>
            <input
              type="text"
              //required
              name="firstname"
              className={`full-width-container ${errorFields.firstname && "errorr"}`}
              onChange={userHandleChange("firstname")}
              placeholder="Enter First Name"
              value={state.firstname ?? ""}
              onBlur={isFieldValid}

            />
          </div>
          {errorFields.firstname && <p className="dangerr">Firstname is required</p>}

          <div className="key-value">
            <p className="key">Last Name</p>
            <input
              type="text"
              //required
              name="lastname"
              className={`full-width-container ${errorFields.firstname && "errorr"}`}
              onChange={userHandleChange("lastname")}
              placeholder="Enter Last Name"
              value={state.lastname ?? ""}
              onBlur={isFieldValid}
            />
          </div>
          {errorFields.lastname && <p className="dangerr">Lastname is required</p>}

          <div className="key-value">
            <p className="key">Email</p>
            <p className="value">{state.displayEmail ?? ""}</p>
          </div>
          <div className="key-value">
            <p className="key">Phone Number</p>
            <Input
              // required
              name="phone"
              defaultCountry="US"
              className={`phone-input ${(errorFields.phone || errorFields.invalidphoneNo) && "errorr"}`}
              placeholder="Enter Phone Number"
              value={state.phone ?? ""}
              onChange={handlePhone}
              onBlur={isFieldValid}
            />
            
          </div>
          {errorFields.invalidphoneNo === false && errorFields.phone === true && <p className="dangerr">Phone number is required</p>}
          {errorFields.phone === false && errorFields.invalidphoneNo === true && (
            <p className="dangerr">Invalid Phone Number</p>
          )}
        </div>
        {!amplify.identities && (
          <div>
            <div
              className="link-key-value"
              onClick={() => setChangeModal(true)}
            >
              <p className="title">Change Password</p>
              <FaArrowRight />
            </div>
            <ChangePasswordModal
              status={changeModal}
              email={state.email}
              onChange={setChangeModal}
            />
          </div>
        )}
        <Button label={"Save"} type={"yellow"} btnAction={"submit"} />
      </form>
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    </div>
  );
}


PersonalInfo.propTypes = {};

export default PersonalInfo;
