import React from 'react';
import {AiOutlineCloseCircle, AiFillCloseCircle} from "react-icons/ai";
import "./Modal.scss";
import logo from "../../assets/logos/ab1-login-logo.png";
import benefitClose from "../../assets/close-white.svg";

function Modal({close, show, children, width, center, gallery=false,createLogin=false,benefit=null, preventClose = false}) {
  const divStyle = {display: show ? 'block' : 'none'};
  return (
    <div className={"Modal"}>
      <div
        className="modal"
        onClick={preventClose ? undefined :close}
        style={divStyle}>
        {!gallery && !createLogin && !benefit &&
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            {close && <div className="close" onClick={close}><AiOutlineCloseCircle size={30}/></div>}
            {children}
          </div>
        }
        {gallery &&
          <div className="gallery-content"  onClick={e => e.stopPropagation()}>
            {close && <div className="close" onClick={close}><AiFillCloseCircle color={"#FFF"} size={30}/></div>}
            {children}
          </div>
        }
         {createLogin && 
            <div className="create-login-content"  onClick={e => e.stopPropagation()}>
            <img src={logo} alt={"logo"} className="middle-logo" />
            {close && <div className="close" onClick={close}><AiFillCloseCircle color={"#FFF"} size={45}/></div>}
            {children}
          </div>
        }
         {benefit &&
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            {close && <div className="close" onClick={close}><img src={benefitClose} alt="offsetLogo" /></div>}
            {children}
          </div> }
        
      </div>
    </div>
  );
}

export default Modal;
