import React, {useEffect, useState} from 'react';
import {AiFillDollarCircle} from "react-icons/ai";
import {FaAngleRight} from "react-icons/fa";
import { GET_AUTH } from "../../utils/HTTP.utils";
import LoadingOverlay from "react-loading-overlay";
import {useHistory} from "react-router-dom";
import {API_REWARD_HISTORY} from "../../middleware/constants/url.constants";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import EmptyIcon from "../../assets/icons/empty_icon.png"


function AbReward({}) {
  let history = useHistory();
  const [loader,setLoader] = useState(true)
  const [state, setState] = useState({
    rewardBalance:"0",
    transactions:[]
  });
  const [wait, setWait] = useState({status:true,bookingId:""});
  const fetchData = async () => {
    let {token} = await getIsUaAuthentication();
    let data = await GET_AUTH(API_REWARD_HISTORY, token, {});
    setState(data)
    setLoader(false)
  };
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() =>{
      if(!wait.status){
        history.push(`/booking-details/${wait.bookingId}`);
      }
  },[wait])

  function handleRewardClick(e) {
    setWait({status:false,bookingId:e})
  }
  if (!loader) {
    return (
      <div className="AbReward">
        <h2>AB1 Rewards</h2>
        <div className="available-balance">
          <p className="title">Available AB1 Rewards</p>
          <p className="balance">${state.rewardBalance?.toLocaleString()}</p>
          <p className="sub-title">
            You may use up to 50% of your AB1 Rewards per booking.
          </p>
        </div>
        {state?.transactions?.map((a, i) => (
          <div
            key={i}
            className="key-value"
            onClick={() => handleRewardClick(a.bookingId)}
            style={{ cursor: "pointer" }}
          >
            <div className="info">
              <p className="key">{a.bookingId}</p>
              {a.status === "CAPTURED" && (
                <p className="value">
                  <AiFillDollarCircle size={18} /> You used $
                  {Math.abs(a.amount)?.toLocaleString()} in AB1 Rewards
                </p>
              )}
              {a.status === "ADDED" && (
                <p className="value">
                  <AiFillDollarCircle size={18} /> You earned $
                  {a.amount?.toLocaleString()} in AB1 Rewards
                </p>
              )}
            </div>
            <FaAngleRight className="angle-right" size={25} />
          </div>
        ))}
        {state.transactions.length === 0 && (
          <div className="empty-container">
            <div className="Empty">
              <img src={EmptyIcon} />
              <h3>No rewards to display</h3>
            </div>
          </div>
        )}
      </div>
    );
  }
  else {
    return (
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    );
  }
}

export default AbReward;