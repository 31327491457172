import React, {useState,useEffect} from 'react';
import Modal from "../Modal";
import Button from "../Button";
import { POST_AUTH } from "../../utils/HTTP.utils";
import {API_PROFILE} from '../../middleware/constants/url.constants'
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import Input from "react-phone-number-input/input";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";

import "./UpdatePersonalInfoModal.scss";

function UpdatePersonalInfoModal({profile, setProfile, status, onChange, initiatePaymentAfterUpdate}){
const [loader, setLoader] = useState(false);
const [state, setState] = useState({ firstname: "", lastname: "", phone: "", email: "" });

useEffect(()=>{
    if(status){
        setState({ firstname: profile.firstname, lastname: profile.lastname, phone: "", email: profile.email})
    }
},[status])

const onSubmit = e => {
    setLoader(true);
    e.preventDefault();
    let {firstname,lastname,phone,email} = state
    if(phone!==undefined && phone!==null && phone !==""){

        getIsUaAuthentication().then(({token})=>{
            POST_AUTH(API_PROFILE,{
            firstname,
            lastname,
            email,
            phone
            },token);
        }).then((data)=>{
            setProfile(state)
            toast.success("Information Updated Successfully",{autoClose: 2000, onClose: props => initiatePaymentAfterUpdate()})
            onChange(false)
        })
    }else{
        toast.error("Please enter a phone number")
    }
    setLoader(false);
}
const userHandleChange = key => e => {
    setState({ ...state, [key]: e.target.value.trim() });
}

const handlePhone = e => {
  setState({ ...state, phone: e });
}
return(
    <div className="UpdatePersonalInfoModal">
        <Modal close={() => onChange(false)} show={status}>
        <p className="title">Personal Info</p>
        <form onSubmit={onSubmit}>
            <div className="key-value">
                <p className="key">First Name</p>
                <input
                type="text"
                required
                className="full-width-container"
                onChange={userHandleChange("firstname")}
                placeholder="Enter First Name"
                value={state.firstname.trim() ?? ""}
                />
            </div>
            <div className="key-value">
                <p className="key">Last Name</p>
                <input
                type="text"
                required
                className="full-width-container"
                onChange={userHandleChange("lastname")}
                placeholder="Enter Last Name"
                value={state.lastname.trim() ?? ""}
                />
          </div>
          <div className="key-value">
            <p className="key">Email</p>
            <p className="value">{state.email.trim() ?? ""}</p>
          </div>
          <div className="key-value">
            <p className="key">Phone Number</p>
            <Input
              required
              defaultCountry="US"
              className="phone-input"
              placeholder="000-000-0000"
              value={state.phone ?? "+1"}
              onChange={handlePhone}
            />
            </div>
            <p className="guideline">Please provide your contact number in order to receive important information regarding your upcoming trip.</p>
            <Button label={"Save to Book"} type={"yellow"} btnAction={"submit"} />
        </form>
        </Modal>
        <LoadingOverlay active={loader} spinner text="Loading your content..." />
    </div>
)
}

export default UpdatePersonalInfoModal;