import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  navAuthLinks,
  navMobileAuthLinks,
  navMobileUnauthLinks,
  navUnauthLinks,
} from "../../constants/app-constants";
import Modal from "../../components/Modal";
import Login from "../../containers/Login/Login";

//Importing images
import DesktopLogo from "../../assets/logos/desktop-logo.png";
import MobileLogo from "../../assets/logos/mobile-logo.png";
import DesktopLogoDark from "../../assets/logos/desktop-logo-dark.png";
import MobileLogoDark from "../../assets/logos/mobile-logo-dark.png";
import QuestionMark from "../../assets/icons/questionmark-icon.png";
import OpenMenu from "../../assets/icons/open-icon.png";
import "./Header.scss";
import { Auth } from "aws-amplify";
import useAuthentication from "../../hooks/useLoginAuthentication";
import { AiOutlineClose } from "react-icons/ai";
import AccountDeleteModal from "../../components/accountDeleteModal";
import { delete_cookie } from '../../utils/common.utils';
import GeneralModal from "../../containers/HomePage/Modal";
//import useLoginAuthentication from "../../hooks/useLoginAuthentication.js";
import { connect } from "react-redux";
import { UPDATE_AUTH_DATA } from "../../middleware/constants/auth.constants";
import { useSelector } from "react-redux";
import NotificationBar from "../Incentive/NotificationBar";

const Header = ({ theme, loginStatus, loginUserData,updateLoginStatus }) => {
  const width = window.innerWidth;
  let history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accountDeleteModal, setAccountDeleteModal] = useState(false);
  //const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const userInfo = useSelector((state) => state.auth.userData);

  const logAuth = useAuthentication();

  // useEffect(() => {
  //   logAuth.getLoginAuthentication();

  // },[loginStatus,loginUserData]);
  // useEffect(() => {
  //   setIsAuthenticated(logAuth.authenticated.loginStatus);
  //   updateLoginStatus(logAuth.authenticated.loginStatus,logAuth.authenticated.userData)
  // },[logAuth.authenticated]);

  useEffect(() => {
    console.log("header")
    logAuth.getIsUaAuthentication(userInfo).then(({ status, userData, createAccount, user }) => {
      updateLoginStatus(status,userData, createAccount, user);
      setIsAuthenticated(status);
    }
  )}, []);


  const navLinks = isAuthenticated ? navAuthLinks : navUnauthLinks;
  const navMobileLinks = isAuthenticated
    ? navMobileAuthLinks
    : navMobileUnauthLinks;

  const handleOpenMenu = () => {
    document.getElementById("mobile-menulist").style.display = "block";
    document.getElementsByClassName("header-menu")[1].style.display = "none";
    document.getElementsByClassName("header-menu")[2].style.display = "block";
    document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  };

  const handleCloseMenu = () => {
    delete_cookie("SearchFilterData")
    delete_cookie("popular_route")
    delete_cookie("PrevUrl")

    document.getElementById("mobile-menulist").style.display = "none";
    document.getElementsByClassName("header-menu")[1].style.display = "block";
    document.getElementsByClassName("header-menu")[2].style.display = "none";
    document.getElementsByTagName("html")[0].style.overflowY = "scroll";
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  };
  history.listen((location, action) => {
    
    logAuth.getIsUaAuthentication().then(({ status }) => setIsAuthenticated(status));
  });
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  const modalLogin =() => {
    setLoginModalOpen(true)
  }
  const modalLoginClose =() => {
    setLoginModalOpen(false);
  }

  const clickMenu = (menu) => {
    if(menu === "book") {
      //delete_cookie("SearchFilterData");
      // if(window.location.pathname === "/")
      // window.location.reload()
      // else
      history.push("/")
    }
  }

  return (
    <>
            <NotificationBar modalClose={modalLoginClose}/>
            <div className="header-main-dark">
      <nav>
        <div className="header-root">
          <Link to={"/"}>
            {theme === "dark" ? (
              <img
                src={width < 480 ? MobileLogo : DesktopLogo}
                alt="AB1 Logo"
                className="brand-logo"
                onClick={() => history.push("/")}
              />
            ) : (
              <img
                src={width < 480 ? MobileLogoDark : DesktopLogoDark}
                alt="AB1 Logo"
                className="brand-logo"
                onClick={() => history.push("/")}
              />
            )}
          </Link>
          <div className="header-sections nav-grp">
            {navLinks.map(({ name, route }, index) => {
              return (
                <HashLink
                  className={`ab1-nav-links ${
                    theme === "dark" ? "theme-light" : "theme-dark"
                  }`}
                  key={index}
                  to={route}
                  onClick={() => clickMenu(name)}
                >
                  {name}
                </HashLink>
              );
            })}
          </div>
          <div className="header-sections header-right">
            {isAuthenticated ? (
              <>
                <div
                  className={`ab1-nav-links ${
                    theme === "dark" ? "theme-light" : "theme-dark"
                  }`}
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  my account
                </div>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => history.push("/my-account")}
                  >
                    ACCOUNT
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => history.push("/my-trips")}
                  >
                    MY TRIPS
                  </a>
                  <a
                    className="dropdown-item"
                    href="/"
                    onClick={() => {
                      console.log("logout")
                      delete_cookie("SearchFilterData")
                      delete_cookie("popular_route")
                      delete_cookie("PrevUrl")
                      localStorage.removeItem("socialLogin")
                      Auth.signOut({})
                      window.location.href = "/"
                      //   Auth.signOut({})
                      //    .then((a) => (window.location.href = "/"))
                      // //   .then(() => {
                      // //    // openModal();
                      // // })
                      //   .catch(console.error);
                    }}
                  >
                    LOG OUT
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => {
                      setAccountDeleteModal(true);
                    }}
                  >
                    DELETE
                  </a>
                </div>
              </>
            ) : (
              <div className="right-menu">
                  <div
                  className={`ab1-nav-links ${
                    theme === "dark" ? "theme-light" : "theme-dark"
                  }`}
                  onClick={()=>modalLogin(true)}
                >
                  Login
                </div>

              </div>
            )}
          </div>
          <div className="mobile-header-sections header-right">
            <img
              onClick={() => history.push("/concierge")}
              src={QuestionMark}
              alt="Help"
              className="header-menu"
            />
            <div>
              <img
                src={OpenMenu}
                onClick={handleOpenMenu}
                alt="menuitems"
                className="header-menu"
              />
              <span
                onClick={handleCloseMenu}
                className="header-menu"
                style={{ fontSize: "25px", color: "white", display: "none" }}
              >
                <AiOutlineClose />
              </span>
            </div>
          </div>
        </div>
        <div id="mobile-menulist">
          <div style={{ padding: "20px 0" }}>
            {navMobileLinks.map(({ name, route }, index) => {
              return (
                <HashLink
                  className={`ab1-nav-links grp-list ${
                    theme === "dark" ? "theme-light" : "theme-dark"
                  }`}
                  onClick={handleCloseMenu}
                  key={index}
                  to={route}
                >
                  {name}
                </HashLink>
              );
            })}
          </div>
          {/* <Link
            className={`ab1-nav-links unq-list ${
              theme === 'dark' ? 'theme-light' : 'theme-dark'
            }`}
            onClick={handleCloseMenu}
            to='/concierge'
          >
            concierge
          </Link> */}
          {isAuthenticated ? (
            <div style={{ padding: "20px 0" }}>
              <Link
                className={`ab1-nav-links grp-list ${
                  theme === "dark" ? "theme-light" : "theme-dark"
                }`}
                onClick={handleCloseMenu}
                to="/my-account"
              >
                my account
              </Link>

          
              <Link
                className={`ab1-nav-links grp-list ${
                  theme === "dark" ? "theme-light" : "theme-dark"
                }`}
                onClick={() => {
                  console.log("logout")
                  delete_cookie("SearchFilterData")
                  delete_cookie("popular_route")
                  delete_cookie("PrevUrl")
                  localStorage.removeItem("socialLogin")
                  Auth.signOut({})
                   .then((a) => (window.location.href = "/"))
                   // .then({modalLogin}) //openModal previously
                    .catch(console.error);
                }}
                to="#"
              >
                logout
              </Link>
              <Link
                      className={`ab1-nav-links grp-list ${
                        theme === "dark" ? "theme-light" : "theme-dark"
                      }`}
                    onClick={() => {
                      setAccountDeleteModal(true);
                    }}
                  >
                    DELETE ACCOUNT
              </Link>
            </div>
          ) : (
            <Link
              className={`ab1-nav-links unq-list ${
                theme === "dark" ? "theme-light" : "theme-dark"
              }`}
             // onClick={openModal}
             onClick={()=>modalLogin(true)}
              to="#"
            >
              login
            </Link>
          )}
        </div>
      </nav>
      <div>
        {accountDeleteModal && (
          <AccountDeleteModal
            status={accountDeleteModal}
            onChange={setAccountDeleteModal}
          />
        )}
      </div>
      {/* <Modal
        createLogin={true}
        close={() => setIsModalOpen(false)}
        show={isModalOpen}
        preventClose={true}
      >
        <Login history={history} />
      </Modal> */}

      {loginModalOpen === true &&
        <GeneralModal modaltype="login" from="header" modalLoginClose={modalLoginClose} history={history}/>
      }
    </div>

    </>
    
  );
};

function mapStateToProps({ auth }) {
  return {
    loginStatus : auth.loginStatus,
    loginUserData : auth.userData,
    createAccount : auth.createAccount
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateLoginStatus : (status, userData, createAccount, user) => dispatch({ type: UPDATE_AUTH_DATA, loginStatus : status , userData : userData, createAccount : createAccount, user : user})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
