import React,{useState} from 'react';
import "./AutoComplete.scss";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import InformationModal from "../../components/InformationModal";

function AutoComplete({input, getSuggestionTile, loading=true, suggestions = [], onSelect, value = "", restriction=null}) {
  const [informationModal, setInformationModal] = useState(false);
  return (
    <div className="AutoComplete">
      <div className="input-field">
        {input}
        {restriction!=null &&
        <div className="restriction" onClick={()=>setInformationModal(true)}>
          <BsFillExclamationTriangleFill color={"#4782E7"} size={15} /> 
          <p>Possible stops due to airport restrictions</p>
        </div>
        }
      </div>
      {value.length > 2 && value.length < 10 && loading &&
        <div className="suggestions active">
          <div className="tile">Loading...</div>
        </div>
        }
      {!loading && value.length > 2 && value.length < 10 && suggestions.length === 0 &&
      <div className={`suggestions active no-result`}>
        {/* <p className={"airport-name"}>{value}</p> Airport Not Found */}
        <p className="contact-us">The airport you are searching appears to be unavailable at this time. Please contact
          us for assistance. Thank you.</p>
      </div>}
      <div className={`suggestions ${suggestions.length > 0 ? "active" : ""}`}>
        {!loading && suggestions.map((a, i) => {
          return <div className="tile" onClick={() => onSelect(a)} key={i}>{getSuggestionTile(a)}</div>
        })}
      </div>
      <InformationModal
        status={informationModal}
        onChange={setInformationModal}
      />
    </div>
  );
}

export default AutoComplete;