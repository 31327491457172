import React, { useEffect, useState } from "react";
import "./accountDeleteModal.scss";
import Modal from "../Modal";
import Button from "../Button";
import useAuthentication from "../../hooks/useLoginAuthentication";
import { Auth } from "aws-amplify";
import { POST_AUTH } from "../../utils/HTTP.utils";
import { API_PROFILE_DELETE } from '../../middleware/constants/url.constants'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";

function AccountDeleteModal({ status, onChange, existing = [] }) {
    const logAuth = useAuthentication();
    const userInfo = useSelector((state) => state.auth.userData);

    const [state, setState] = useState({
        deleteVal: ""
    });
    const [deleteStatus, setDeleteStatus] = useState(true);
    const [loader, setLoader] = useState(false);
    const [userName, setUserName] = useState('');
    let history = useHistory();
    const handleChange = (key) => (e) => {
        if (e.target.value.trim() === 'DELETE') {
            setDeleteStatus(false);
        } else {
            setDeleteStatus(true);
        }
    };

    const fetchData = async () => {
        let user = await Auth.currentAuthenticatedUser()
        setUserName(user.username);
    }

    const onSubmit = () => {
        setLoader(true);

        logAuth.getIsUaAuthentication(userInfo).then(({ token }) => {
            POST_AUTH(API_PROFILE_DELETE + '?id=' + userName, {}, token).then((result) => {
                if (result.error) {
                } else {
                    toast.success("Account Deleted Successfully");
                    setLoader(false);
                    Auth.signOut({ });
                    window.location.href="/?login=true";
                }
                setDeleteStatus(true);
                onChange(false);
            })
        })
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className="ConciergeModal">
            <Modal close={() => onChange(false)} show={status}>
                <div className="ConciergeModalContainer">
                    <p className="title">
                        <b>Are you sure?</b>
                    </p>
                    <p className="sub-title confirm-msg">This action CANNOT be undone. You will premanently lose your data.</p>
                    <p className="delete-msg">
                        Please type <b>DELETE</b> to confirm.
                    </p>
                    <p>
                        <input
                            type="text"
                            required
                            className="full-width-container"
                            onChange={handleChange("deleteVal")}
                        />
                    </p>
                    <Button
                        label={"Delete my account"}
                        type={deleteStatus ? "red-disable" : "warning-red"}
                        btnAction={"submit"} disabled={deleteStatus} onClick={() => onSubmit()}
                    />
                </div>
            </Modal>
            if (!loader) {
                <LoadingOverlay active={loader} spinner />
            }
        </div>
    );
}

export default AccountDeleteModal;
