import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import DatePicker, { CalendarContainer } from "react-datepicker";
import addDays from "date-fns/addDays";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Circle from "../../assets/icons/search-flight-icons/circle.png";
import Location from "../../assets/icons/search-flight-icons/location.png";
import User from "../../assets/icons/search-flight-icons/user.png";
import Paw from "../../assets/icons/search-flight-icons/paw.png";
import ArrowRight from "../../assets/icons/search-flight-icons/arrow-right.png";
import ArrowReturn from "../../assets/icons/search-flight-icons/arrow-return.png";
import Button from "../../components/Button";
import {
  MULTI_CITY,
  ONE_WAY,
  ROUND_TRIP,
} from "../../middleware/constants/trip.constants";
import { GET_UN_AUTH } from "../../utils/HTTP.utils";
import { setCookie, getCookie, delete_cookie } from "../../utils/common.utils";
import { API_SEARCH_AIRPORT } from "../../middleware/constants/url.constants";
import AutoComplete from "../../components/AutoComplete";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import {
  RESET_SEARCH_RESULTS,
  SET_SEARCH_OPTIONS,
} from "../../middleware/constants/aircarft.constants";
import {
  getFinalAPIDATA,
  getFinalAPIDataMultiLeg,
  getSuggestionTile,
  validateMultiLegData,
  validateReturnOneWay,
} from "./helper";
import MultiCityForm from "./MultiCityForm";
import moment from "moment";
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';

function SearchFlightForm({
  setFilterOptions,
  resetSearchResults,
  existing,
  history,
  loading,
  modalHandler,
}) {
  let timeRegex = /([0-9][0-9]):([0-9][0-9]):([0-9][0-9])/;
  let dateRegex = /([A-Z,a-z]){3,3} ([0-9][0-9]) [0-9]{4,4}/;
  const [loader, setLoader] = useState(false);
  // const MyContainer =
  // let [chosen,setChosen] = useState({dept:false,arr:false})
  let minDate = moment().add(1, "days");
  let [time, setTime] = useState([0, 0, 0, 0]);
  let [day, setDay] = useState(true);
  const [route, setRoute] = useState(ROUND_TRIP);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [multiLeg, setMultiLeg] = useState({
    firstStop: {},
    stops: [{ value: {}, suggestions: [], loading: true, inputValue: "", date: "", restriction: null }],
    lastStop: {},
  });

  const [petStatus, setPetStatus] = useState(false);

  const [departAirport, setDepartAirport] = useState({
    value: {},
    suggestions: [],
    inputValue: "",
    restriction: null,
    loading: true,
  });
  const [arriveAirport, setArriveAirport] = useState({
    value: {},
    suggestions: [],
    inputValue: "",
    restriction: null,
    loading: true,
  });
  const [state, setState] = useState({
    passengers: "",
    petFriendly: false,
    departureTime: "",
    returnTime: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  let prefill = history?.location?.state?.prefill ?? false;
  let popularRoute = history?.location?.state?.route ?? false;

  /*
    @description: Handles Prefill data when user select Edit date
   */
  useEffect(() => {

    if (state.departureTime !== "" && existing.routeType !== MULTI_CITY) {
      var temp = state.departureTime;
      setTime(String(temp).match(timeRegex));
      if (
        (parseInt(temp[1]) > 9 && parseInt(temp[2]) === 30) ||
        (parseInt(temp[1]) >= 10 && parseInt(temp[2]) >= 0)
      ) {
        setTime(["Insignificant", "21", "30", "0"]);
      }
    }
    if (Object.keys(existing || {}).length > 0 && prefill) {
      setRoute(existing.routeType);
      if (existing.routeType !== MULTI_CITY) {
        setArriveAirport({
          ...arriveAirport,
          value: existing.arrivalAirport,
          inputValue: existing.arrivalAirport.airportName,
          restriction: existing.arrivalAirport.restriction,
        });
        setDepartAirport({
          ...departAirport,
          value: existing.departureAirport,
          inputValue: existing.departureAirport.airportName,
          restriction: existing.departureAirport.restriction,
        });
      } else {
        setMultiLeg(existing.multiLegData);
      }
      setState({
        ...state,
        petFriendly: existing.petFriendly,
        passengers: existing.passengers,
        departureTime: existing.departureTime,
        returnTime: existing.returnTime,
      });
    }

    // Prepopulate from localstorage

    //const searchInfo = localStorage.getItem("SearchFilterData");
    const searchInfo = getCookie("SearchFilterData");
    if(searchInfo) {
      const parsedSearchInfo = JSON.parse(searchInfo);
      if(parsedSearchInfo && parsedSearchInfo.hasOwnProperty("routeType")) {
        setIsEdit(true)
        setRoute(parsedSearchInfo.routeType);
  
        if (parsedSearchInfo.routeType !== MULTI_CITY) {
          setArriveAirport({
            ...arriveAirport,
            value: parsedSearchInfo.arrivalAirport,
            inputValue: parsedSearchInfo.arrivalAirport.airportName,
            restriction: parsedSearchInfo.arrivalAirport.restriction,
          });
          setDepartAirport({
            ...departAirport,
            value: parsedSearchInfo.departureAirport,
            inputValue: parsedSearchInfo.departureAirport.airportName,
            restriction: parsedSearchInfo.departureAirport.restriction,
          });
          if(parsedSearchInfo.routeType === ONE_WAY ) {
            setState({
              ...state,
              petFriendly: parsedSearchInfo.petFriendly,
              passengers: parsedSearchInfo.passengers,
              departureTime: new Date(parsedSearchInfo.departureTime),
            });
          }
          else {
            setState({
              ...state,
              petFriendly: parsedSearchInfo.petFriendly,
              passengers: parsedSearchInfo.passengers,
              departureTime: new Date(parsedSearchInfo.departureTime),
              returnTime: new Date(parsedSearchInfo.returnTime),
            });
          }
          
        } else {
          setMultiLeg(parsedSearchInfo.multiLegData);
          setState({
            ...state,
            petFriendly: parsedSearchInfo.petFriendly,
            passengers: parsedSearchInfo.passengers
          });
        }
        
      }
    }
  

  }, []);

  useEffect(() => {
    if (existing && existing.hasOwnProperty('routeType') && existing.routeType !== MULTI_CITY) {
      if (state.departureTime !== "") {
        var deptTime = String(state.departureTime).match(timeRegex);
        if (parseInt(deptTime[1]) >= 22) {
          setTime(["21", "30"]);
        } else {
          setTime([deptTime[1], deptTime[2]]);
        }
        setDay(true);
      }
      if (state.departureTime === "") {
        setDay(false);
      }
      if (
        state.departureTime !== "" &&
        state.returnTime !== "" &&
        route === ROUND_TRIP
      ) {
        var res =
          String(state.departureTime).match(dateRegex)[0] ===
          String(state.returnTime).match(dateRegex)[0];
        setDay(res);
      }
    }
  }, [state.departureTime, state.returnTime]);

  const fetchAndSetAirports = (query, setter) => {
    GET_UN_AUTH(API_SEARCH_AIRPORT, { searchStr: query }).then((data) =>
      setter(data)
    );
  };
  const handleTabSwitching = (type) => () => {
    type !== route ? setIsEdit(true) : setIsEdit(false);
    setRoute(type);
  };
  const onDepartAtChange = ({ target }) => {
    if (target.value.trim() === "") {
      setDepartAirport({
        value: {},
        suggestions: [],
        inputValue: "",
        restriction: null,
        loading: true,
      });
    } else {
      setDepartAirport({ ...departAirport, inputValue: target.value });
    }
    if (target.value.length > 2) {
      setDepartAirport({
        ...departAirport,
        inputValue: target.value,
        loading: true,
      });
      fetchAndSetAirports(target.value, (data) =>
        setDepartAirport({
          ...departAirport,
          loading: false,
          suggestions: data,
          inputValue: target.value,
        })
      );
    }
  };
  const onArrivalAtChange = ({ target }) => {
    if (target.value.trim() === "") {
      setArriveAirport({
        value: {},
        suggestions: [],
        inputValue: "",
        restriction: null,
        loading: true,
      });
    } else {
      setArriveAirport({ ...arriveAirport, inputValue: target.value });
    }
    if (target.value.length > 2) {
      setArriveAirport({
        ...arriveAirport,
        inputValue: target.value,
        loading: true,
      });
      fetchAndSetAirports(target.value, (data) =>
        setArriveAirport({
          ...arriveAirport,
          loading: false,
          suggestions: data,
          inputValue: target.value,
        })
      );
    }
  };

  const getDepartFiled = () => {
    return (
      <div className="input-group">
        <div>
          <img src={Circle} alt="depart from" />
        </div>
        <input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={departAirport.inputValue}
          placeholder="Departure"
          onChange={onDepartAtChange}
        />
      </div>
    );
  };

  const getArrivalFiled = () => {
    return (
      <div className="input-group">
        <div>
          <img src={Location} alt="Arrive at" />
        </div>
        <input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={arriveAirport.inputValue}
          placeholder="Destination"
          onChange={onArrivalAtChange}
        />
      </div>
    );
  };
  const onSubmit = async (e) => {
   
    e.preventDefault();
    try {
      if (isEdit) {
      
        let { status: isAuth } = await getIsUaAuthentication();
        if (isAuth) {     

          let final = {};
          if (route === ROUND_TRIP || route === ONE_WAY) {
            validateReturnOneWay(
              route,
              state,
              departAirport.value,
              arriveAirport.value
            );
            final = getFinalAPIDATA(
              state,
              departAirport.value,
              arriveAirport.value,
              route
            );

          } else {
            validateMultiLegData(state, multiLeg);
            final = getFinalAPIDataMultiLeg(state, multiLeg, route);
          }
          console.log(final);
          setCookie("SearchFilterData", JSON.stringify(final),15)
          setFilterOptions(final);
          resetSearchResults({});
          history.push("/searching");
          
        } else {
          let final = {};
          if (route === ROUND_TRIP || route === ONE_WAY) {
            validateReturnOneWay(
              route,
              state,
              departAirport.value,
              arriveAirport.value
            );
            final = getFinalAPIDATA(
              state,
              departAirport.value,
              arriveAirport.value,
              route
            );
          } else {
            validateMultiLegData(state, multiLeg);
            final = getFinalAPIDataMultiLeg(state, multiLeg, route);
          }

          //localStorage.setItem("SearchFilterData", JSON.stringify(final));
          setCookie("SearchFilterData", JSON.stringify(final),15)
          setFilterOptions(final);
          resetSearchResults({});

          if (history.location.pathname === "/popular-routes-book" && popularRoute) {
            let popular_route = {};
            popular_route.passengers = state.passengers;
            popular_route.petFriendly = state.petFriendly;
            popular_route.departureTime = state.departureTime;
            popular_route.returnTime = state.returnTime
            setCookie("popular_route", JSON.stringify(popular_route),15)

          }

          modalHandler("benefit");
          //setIsModalOpen(true);
        }
        
      } else {

        history.push("/book-flight");
      }
    } catch (e) {
      console.error(e);
      toast.error(e);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (history.location.pathname === "/popular-routes-book" && popularRoute) {
      loading(true);
      let popular_route = getCookie("popular_route") || '';
      console.log(popular_route)
      if(popular_route !== ''){
        let parsedPopularRoute = JSON.parse(popular_route)
        if(parsedPopularRoute){
          setState({
            passengers: parsedPopularRoute.passengers || "",
            petFriendly: parsedPopularRoute.petFriendly || false,
            departureTime: new Date(parsedPopularRoute.departureTime) || "",
            returnTime: new Date(parsedPopularRoute.returnTime) || ""
          })
        }
      }
      else {
        setState({
          passengers: "",
          petFriendly: false,
          departureTime: "",
          returnTime: ""
        })
      }
      
      fetchAndSetAirports(popularRoute.deptICAO, (data) =>
        setDepartAirport({
          ...departAirport,
          loading: false,
          suggestions: [],
          inputValue: data[0].airportName,
          value: data[0],
          restriction: data[0].restriction,
        })
      );
      fetchAndSetAirports(popularRoute.arrivalICAO, (data) =>
        setArriveAirport({
          ...arriveAirport,
          loading: false,
          suggestions: [],
          inputValue: data[0].airportName,
          value: data[0],
          restriction: data[0].restriction,
        })
      );
      console.log("000000000000000000000")
      setIsEdit(true);
      loading(false);
    }
    else {
      delete_cookie("popular_route")
    }
  }, []);

  function applyTransform() {
    const switchHandle = document.querySelector(".react-switch-handle");
    if (switchHandle) {
      switchHandle.style.transform = "translateX(16px)";
    }
  }

  // Function to watch for changes in the "react-switch-bg" class
  function watchClassChanges() {
    const switchBg = document.querySelector(".react-switch-bg");

    // Use a MutationObserver to detect changes in the DOM
    const observer = new MutationObserver(function (mutationsList) {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          // Class attribute has changed, apply the transformation
          applyTransform();
        }
      }
    });

    if (switchBg) {
      // Start observing changes in the "class" attribute of the "react-switch-bg" element
      observer.observe(switchBg, { attributes: true });
    }
  }

  const handleStyle = {
    transform: petStatus ? `translateX(16px)` : "translateX(0px)",
    transition: "transform 0.2s ease", // Add a transition effect for smooth animation
   
  };

  return (
    <div className="SearchFlightForm search-flight-form">
      <div className="trip-type">
        <Button
          label={"Round Trip"}
          className="trip-button"
          size={"large"}
          fullWidth={false}
          onClick={handleTabSwitching(ROUND_TRIP)}
          type={`${route === ROUND_TRIP ? "black" : "link"}`}
        />
        <Button
          label={"One Way"}
          size={"large"}
          fullWidth={false}
          onClick={handleTabSwitching(ONE_WAY)}
          type={`${route === ONE_WAY ? "black" : "link"}`}
        />
        <Button
          label={"Multi City"}
          size={"large"}
          fullWidth={false}
          onClick={handleTabSwitching(MULTI_CITY)}
          type={`${route === MULTI_CITY ? "black" : "link"}`}
        />
      </div>
      <form onSubmit={onSubmit}>
        {(route === ROUND_TRIP || route === ONE_WAY) && (
          <>
            <div className="search-input-grp input-form-fill travel-field">
              <AutoComplete
                input={getDepartFiled()}
                value={departAirport.inputValue}
                suggestions={departAirport.suggestions}
                loading={departAirport.loading}
                restriction={departAirport.restriction}
                onSelect={(a) => {
                  setDepartAirport({
                    ...departAirport,
                    value: a,
                    suggestions: [],
                    inputValue: a.airportName,
                    restriction: a.restriction,
                  });
                  setIsEdit(true);
                }}
                getSuggestionTile={getSuggestionTile}
              />
            </div>
            <div className="search-input-grp input-form-fill travel-field">
              <AutoComplete
                input={getArrivalFiled()}
                value={arriveAirport.inputValue}
                suggestions={arriveAirport.suggestions}
                loading={arriveAirport.loading}
                restriction={arriveAirport.restriction}
                onSelect={(a) => {
                  setArriveAirport({
                    ...arriveAirport,
                    value: a,
                    suggestions: [],
                    inputValue: a.airportName,
                    restriction: a.restriction,
                  });
                  setIsEdit(true);
                }}
                getSuggestionTile={getSuggestionTile}
              />
            </div>
          </>
        )}
        {route === MULTI_CITY && (
          <MultiCityForm
            setData={setMultiLeg}
            minDate={minDate}
            setIsEdit={setIsEdit}
            existing={multiLeg}
          />
        )}
        <div className="search-input-grp">
          <div>
            <img src={User} alt="Passengers" />
          </div>
          <input
            type="number"
            min={1}
            placeholder="Passengers"
            value={state.passengers}
            onChange={({ target }) => {
              setState({ ...state, passengers: target.value });
              setIsEdit(true);
            }}
          />
        </div>
        <div className="search-input-grp ">
          <div>
            <img src={Paw} alt="pets" />
          </div>
          <div className="pets">
            <p style={{ color: "#848b94" }}>Pets</p>

            <Switch
              onChange={(value) => {
                setState({ ...state, petFriendly: value });
                setIsEdit(true);
              }}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              offColor="#FFFFFF"
              offHandleColor="#FFFFFF"
              borderRadius={50}
              handleDiameter={22}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 0px 0px 0px"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={22}
              width={36}
              checked={state.petFriendly}
              background={{ true: "red", false: "grey" }}
            />
          </div>
          {/* </div> */}
        </div>
        {route !== MULTI_CITY && (
          <div className="search-input-grp-date input-form-fill">
            <div>
              <img src={ArrowRight} alt="Depart Date" />
            </div>
            <DatePicker
              selected={state.departureTime}
              showTimeSelect
              popperPlacement="bottom"
              // calendarContainer={({ className, children }) => {
              //   return (
              //     <div
              //       style={{
              //         width: "327.06px",
              //         position: "relative",
              //         right: "50px",
              //       }}
              //     >
              //       <CalendarContainer className={className}>
              //         <div className="react-datepicker__triangle" />
              //         <div style={{ width: "327.06px", position: "inherit" }}>
              //           {children}
              //         </div>
              //       </CalendarContainer>
              //     </div>
              //   );
              // }}
              minDate={addDays(new Date(), 2)}
              openToDate={addDays(new Date(), 2)}
              placeholderText="Depart Date"
              dateFormat="MMMM d, yyyy h:mm aa"
              onChange={(date) => {
                if (moment(date).isAfter(minDate)) {
                  // setChosen({...chosen, dept:true})
                  setState({ ...state, departureTime: date });
                  setIsEdit(true);
                }
              }}
            />
          </div>
        )}
        {route === ROUND_TRIP && (
          <div className="search-input-grp-date input-form-fill">
            <div>
              <img src={ArrowReturn} alt="Return Date" />
            </div>
            <DatePicker
              selected={state.returnTime}
              showTimeSelect
              popperPlacement="bottom"
              // calendarContainer={({ className, children }) => {
              //   return (
              //     <div
              //       style={{
              //         width: "327.06px",
              //         position: "relative",
              //         right: "50px",
              //       }}
              //     >
              //       <CalendarContainer className={className}>
              //         <div className="react-datepicker__triangle" />
              //         <div style={{ width: "327.06px", position: "inherit" }}>
              //           {children}
              //         </div>
              //       </CalendarContainer>
              //     </div>
              //   );
              // }}
              disabled={state.departureTime === ""}
              minDate={state.departureTime}
              openToDate={state.departureTime}
              placeholderText={"Return Date"}
              dateFormat="MMMM d, yyyy h:mm aa"
              onChange={(date) => {
                if (moment(date).isAfter(moment().add(1, "days"))) {
                  // setChosen({...chosen, arr:true})
                  setState({ ...state, returnTime: date });
                  setIsEdit(true);
                }
              }}
              minTime={
                day
                  ? setHours(
                      setMinutes(new Date(), parseInt(time[1]) + 1),
                      parseInt(time[0]) + 2
                    )
                  : null
              }
              maxTime={day ? setHours(setMinutes(new Date(), 59), 23) : null}
            />
          </div>
        )}
        <div className="search-btn">
          <Button btnAction={"submit"} type="yellow" label="Book Now" />
        </div>
      </form>
      
      {/* <Modal
        createLogin={true}
        close={() => closeModal(false)}
        show={isModalOpen}
      > */}
        {/* <BenefitSummary/> */}
        {/* <Login login="Booking" history={history} /> */}
      {/* </Modal> */}
    </div>
  );
}

function mapStateToProps({ aircraft }) {

  return {
    existing: aircraft.searchOptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFilterOptions: (data) =>
      dispatch({ type: SET_SEARCH_OPTIONS, payload: data }),
    resetSearchResults: (data) =>
      dispatch({ type: RESET_SEARCH_RESULTS, payload: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFlightForm);
