import React, { useEffect, useState } from "react";
import CheckSuccess from "../../assets/icons/CheckSuccess.svg";
import Button from "../../components/Button";
import { API_GET_BANK_DETAILS } from "../../middleware/constants/url.constants";
import useAuthentication, { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { GET_AUTH } from "../../utils/HTTP.utils";
import "./PaymentSuccess.scss";
import {PAYMENT_TYPE_PAY_BY_WIRE} from "../../middleware/constants/payment.constants";
import {connect} from "react-redux";

function PaymentSuccess({history}) {
  let { finalCall, type, subType, amountDue } = history.location.state;
  let [bankInfo, setInfo] = useState({});


  return (
    <div className="PaymentSuccess">
      <img src={CheckSuccess} className={"check-icon"} alt="CheckSuccess" />
      <h1>Congratulations!</h1>
      {type === PAYMENT_TYPE_PAY_BY_WIRE ? (
        <h2>
        Your flight is sucessfully reserved. 
      </h2>
      )
      :
      ( <div className="booking-content">
        <h2>
        Your flight is booked. We look forward to seeing you onboard and in the
        air.
      </h2>
      <div className="booking-id">
        <p className="label">Booking number:</p>
        <p className="value">{finalCall.bookingId}</p>
      </div>
      </div>
    )}
      <Button
        label={"Go to My Trips"}
        onClick={() => history.push("/my-trips")}
        size={"large"}
        fullWidth={false}
        type={"white-blue"}
      />
      </div>
    
  );
}


function mapStateToProps(
  {
    aircraft, payment
  }
) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)
