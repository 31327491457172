import React from 'react';

const TermsOfSale = () => {
  return (
    <div className='general-section'>
      <>
        <p
          style={{
            textIndent: '36pt',
            textAlign: 'center',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <u>
              <strong>AirBookOne Terms of Sale</strong>
            </u>
          </span>
        </p>
        <p
          style={{
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            By making a reservation for or chartering any Flight through
            AirBookOne, you agree to the&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            <a href="/terms-of-service" style={{ textDecoration: "none" }}>Terms of Service</a>&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            and these Terms of Sale. Any capitalized terms used but not defined
            in these Terms of Sale have the meanings given in the&nbsp;
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            Terms of Service
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: 16,
              color: '#000000',
            }}
          >
            .
          </span>
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal' }}>
            <p
              style={{
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '12.000000000000002pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>PRICING AND FEES</strong>
              </span>
            </p>
          </li>
          <ul>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Pricing</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;The price of and fee(s) for each Flight will be displayed in the applicable Flight listing on the Site. All pricing and fees are set by AirBookOne and the applicable Operator based on market rates and are subject to change based on aircraft and flight crew availability. Additional fuel surcharges may apply for Flights booked a certain period of time in advance (for example, 14 or more days in advance). Unless otherwise specified herein or in the Flight listing, the quoted price is inclusive of all Federal Excise Tax and carbon offset charges. Additional services and items, such as de-icing, catering, trip insurance, and flight phone, satellite and/or WiFi, may be made available and, if elected by Customer, will be subject to separate fees (which may be added to the total Flight fees or charged to Customer separately). Any other additional costs and expenses that may be incurred will be listed in the Flight listing and/or notified to you by the Operator. Ground transportation is not included with any Flight and is the sole responsibility of Customer. Once you pay the full amount specified for your Flight in accordance with these Terms of Sale and receive a confirmation email confirming receipt of your payment, the pricing for your Flight is guaranteed and will not change unless you subsequently make or request changes to your itinerary.&nbsp;
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Aircraft Changes</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;Unless explicitly indicated otherwise, prices are aircraft-specific. If you have received a confirmation email confirming receipt of full payment for your Flight and you subsequently request an aircraft change, or make or request a change to your itinerary that otherwise necessitates an aircraft change, your Flight may be subject to price adjustment and/or additional fees. If AirBookOne or the applicable Operator needs to make an aircraft change for reasons other than a request or change made by you, such aircraft change will be made at no additional cost to you. In all cases, you will be informed of any aircraft change prior to Flight and the amount of any additional cost, if applicable.&nbsp;
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Itinerary Changes</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;Upon holding or booking a reservation, AirBookOne may send you an initial trip itinerary for the selected Flight. Initial trip itineraries are subject to confirmation by the Operator and are not confirmed until you have paid the full amount specified for your Flight in accordance with these Terms of Sale and received a confirmation email confirming receipt of your payment. Subsequent itinerary changes requested by Customer may be permitted, but are subject to aircraft and flight crew availability and price adjustment. Acceptance of any itinerary changes requested by Customer is within AirBookOne’s and the applicable Operator’s sole discretion. Departure times are subject to change for operational reasons.
                </span>
              </p>
            </li>
          </ul>
          <li style={{ listStyleType: 'decimal' }}>
            <p
              style={{
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '12.000000000000002pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>RESERVATIONS AND PAYMENT</strong>
              </span>
            </p>
          </li>
          <ul>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Holding a Reservation</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;Unless otherwise specified in the Flight listing, when making a reservation, you may have the option to hold the reservation for up to 24 hours from the time of booking by pa
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ying an initial $1,000 deposit (an “
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <strong>Initial Deposit</strong>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ”).&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  If you choose
                  to hold your reservation by making an Initial Deposit, you will have up to 24 hours to complete your reservation by paying the remaining balance of the fees specified for your Flight (whi
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ch payment may be made by ACH wire or other payment method permitted by AirBookOne). If you do not pay the remaining balance of the fees within such 24 hour period, your reservation will be automatically canceled, and you will be refunded the amount of your&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  Initial Deposit, less a 3.5% administrative processing fee.&nbsp;
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Completing a Reservation</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;You may complete your reservation at the time of booking by paying the&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  full amount specified for the Flight you select, including any related fees, charges, and taxes
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  .&nbsp;If you chose to hold your reservation by paying an Initial Deposit, you must pay the remaining balance of the fees specified for your Flight within 24 hours of placing the hold in order to complete your reservation.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  NO RESERVATION IS CONFIRMED UNTIL YOU&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  RECEIVE A CONFIRMATION EMAIL FROM US CONFIRMING RECEIPT OF YOUR PAYMENT AND THAT YOUR FLIGHT IS CONFIRMED.
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  &nbsp;Once confirmed, the pricing for your Flight is guaranteed and will not change unless you subsequently make or request changes to your itinerary as set forth above.
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>On-Demand Flights</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;For U.S. domestic Flights booked or paid within 72 hours of the scheduled Flight departure time, or for international Fli
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ghts booked or paid within 120 hours of the scheduled Flight departure time (each, an “
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <strong>On-Demand Flight</strong>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ”), you will be required to complete your reservation at the time of booking by paying the&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  full amount specified for the Flight, including any related fe
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  es, charges, and taxes
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . If booking an On-Demand Flight scheduled or preferred to depart within 24 hours, you must call our concierge service directly at
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  &nbsp;1.312.761.5080
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  to assist with booking.
                </span>
              </p>
            </li>
          </ul>
          <li style={{ listStyleType: 'decimal' }} id={"CANCELLATION-AND-REFUND-POLICY"}>
            <p
              style={{
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '12.000000000000002pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>CANCELLATION AND REFUND POLICY</strong>
              </span>
            </p>
          </li>
          <p
            style={{
              textAlign: 'justify',
              lineHeight: 'normal',
              marginTop: '0pt',
              marginBottom: '12.000000000000002pt',
              border: 'none',
            }}
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#000000',
              }}
            >
              Once you receive a confirm
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#000000',
              }}
            >
              ation email from us confirming your Flight reservation, any and all subsequent cancellations and refunds will be subject to the following policy:
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>One-Way and Multi-Leg Flights</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;All one-way or multi-leg Flights are are non-cancelable and non-refundable. If
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  &nbsp;you book a one-way or multi-leg Flight and later wish to cancel, you will be charged 100% of the amount specified for the Flight and will not be entitled to any refund.
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Round-Trip Flights</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;For U.S.&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  domestic Flights booked and paid more than 72 hours prio
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  r to scheduled Flight departure time and subsequently cancelled, you will only be entitled to a refund as follows:
                </span>
              </p>
            </li>
          </ul>
          <table
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              marginLeft: '40.25pt',
              borderLeft: '0.5pt solid #000000',
              borderTop: '0.5pt solid #000000',
              borderRight: '0.5pt solid #000000',
              borderBottom: '0.5pt solid #000000',
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    backgroundColor: '#d9d9d9',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      <strong>Cancellation Window</strong>
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    backgroundColor: '#d9d9d9',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      <strong>Refund</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      More than 72 hours prior to
                      scheduled Flight departure time
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      100% of amount paid, less (i) any pre-paid costs or&nbsp;
                    </span>
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      expenses relating to the Flight, and (ii) if the Flight was booked using a credit card, a 3.5% administrative processing fee
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      24-72 hours prior to scheduled Flight departure time
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      50% of amount paid
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      Less than 24 hours prior to
                      scheduled Flight&nbsp;
                    </span>
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      departure time
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      No refunds
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            style={{
              marginLeft: '36pt',
              textAlign: 'justify',
              lineHeight: 'normal',
              marginTop: '0pt',
              marginBottom: '0pt',
              border: 'none',
            }}
          >
            &nbsp;
          </p>
          <p
            style={{
              marginLeft: '36pt',
              textAlign: 'justify',
              lineHeight: 'normal',
              marginTop: '0pt',
              marginBottom: '12.000000000000002pt',
              border: 'none',
            }}
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#000000',
              }}
            >
              For international&nbsp;
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#000000',
              }}
            >
              Flights booked and paid more than 120 hours prior to scheduled Flight departure time and subsequently cancelled, you will only be entitled to a refund as follows:
            </span>
          </p>
          <table
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              marginLeft: '40.25pt',
              borderLeft: '0.5pt solid #000000',
              borderTop: '0.5pt solid #000000',
              borderRight: '0.5pt solid #000000',
              borderBottom: '0.5pt solid #000000',
            }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    backgroundColor: '#d9d9d9',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      <strong>Cancellation Window</strong>
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    backgroundColor: '#d9d9d9',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      <strong>Refund</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      More than 120&nbsp;
                    </span>
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      hours prior to
                      scheduled Flight departure time
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      100% of amount paid, less (i) any pre-paid costs or expenses relating to the Flight, and (ii) if the Flight was booked using credit card, a 3.5% administrative processing fee
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      48-120 hours prior to scheduled F
                    </span>
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      light departure time
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      50% of amount paid
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '161.75pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='40.90909090909091%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      Less than 48 hours prior to
                      scheduled Flight departure time
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '233.75000000000003pt',
                    padding:
                      '0pt 5.3858267716535435pt 0pt 5.3858267716535435pt',
                    borderLeft: '0.5pt solid #000000',
                    borderTop: '0.5pt solid #000000',
                    borderRight: '0.5pt solid #000000',
                    borderBottom: '0.5pt solid #000000',
                  }}
                  width='59.09090909090909%'
                >
                  <p
                    style={{
                      textAlign: 'justify',
                      lineHeight: 'normal',
                      marginTop: '0pt',
                      marginBottom: '12.000000000000002pt',
                      border: 'none',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: '"Times New Roman"',
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      No refunds
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            style={{
              textAlign: 'justify',
              lineHeight: 'normal',
              marginTop: '0pt',
              marginBottom: '0pt',
              border: 'none',
            }}
          >
            &nbsp;
          </p>
          <ul>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>On-Demand Flights</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  All&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  On-Demand Flights (i.e.,&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  U.S. domestic Flights booked or paid within 72 hours of the scheduled Flight departure time,&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  or international Flights booked or paid within 120 hours of the scheduled Flight departure time) are non-cancelable and non-
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  refundable. If you book an On-Demand Flight and later wish to cancel, you will be charged 100% of the amount specified for the Fligh
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  t and will not be entitled to a refund.
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>No-Shows</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;No-shows will be charged 100% of the amount specified for the Flight and will not be entitled to a refund.
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Security Concerns</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;If a Flight is canceled, diverted, or otherwise affected due to security conce
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  rns caused by Customer or any passengers, Customer will be charged&nbsp;
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  100% of the amount specified for the Flight and will not be entitled to a refund.
                </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Privately Owned Aircraft</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;
                  If your Flight is booked with an aircraft that is privately owned by a third party, in rare circumstances your Flight may be canceled by the owner. In such circumstances, we will notify you prior to your scheduled departure time, and you will be entitled to receive a full refund of the amount paid for the Flight.
                </span>
              </p>
            </li>
          </ul>
          <p
            style={{
              textAlign: 'justify',
              lineHeight: 'normal',
              marginTop: '0pt',
              marginBottom: '12.000000000000002pt',
              border: 'none',
            }}
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#000000',
              }}
            >
              If you are entitled to a refund under our cancellation and refund policy, we will issue your refund amount as a credit back to your original payment method within 30 days.{' '}
              <strong>
                You acknowledge and agree that amounts retained by AirBookOne in connection with this&nbsp;
              </strong>
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: 16,
                color: '#000000',
              }}
            >
              <strong>
                cancellation and refund policy are intended as liquidated damages and not as a penalty, and are in addition to any other rights or remedies of AirBookOne pursuant to the Terms of Service, these Terms of Sale, or under applicable law.
              </strong>
            </span>
          </p>
          <li style={{ listStyleType: 'decimal' }}>
            <p
              style={{
                textAlign: 'justify',
                lineHeight: 'normal',
                marginTop: '0pt',
                marginBottom: '12.000000000000002pt',
                border: 'none',
              }}
            >
              <span
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: 16,
                  color: '#000000',
                }}
              >
                <strong>MISCELLANEOUS</strong>
              </span>
            </p>
          </li>

          <ul>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Passenge</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>r Identification</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  Prior to boarding, all passengers must provide the Operator at least one form of government-issued photo identification (federal or state) reasonably acceptable to the Operator. In absence of government-issued photo identification, two forms</span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                   &nbsp;of identification will be required, one of which must be government-issued. For any flight to the U.S., passengers holding other than U.S. or Canadian passports must hold a valid Visa. Customer is responsible for informing all passengers of the requirement </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  to provide valid proof of identification and/or citizenship prior to boarding, and neither AirBookOne nor the Operator will be liable for the Operator’s reasonable refusal to board any passenger who fails to provide appropriate identification. All official               </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  travel documents (e.g., passports, visas, etc.) are the responsibility of each passenger. Customer will be liable for any fines, penalties, cancellation penalties, or other costs arising from incorrectly documented passengers (including, but not lim
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ited to, invalid photo identification, travel documents and/or other security information provided by Customer) or non-approval of passengers against the TSA no-fly list.              </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Passenger Changes</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;Customer is responsible for promptly notifying AirBookOne and the
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  &nbsp;Operator in writing of any changes to the list of authorized passengers and shall be liable for all costs incurred by AirBookOne or the Operator by Customer’s failure to so notify, including, but not limited to, all costs related to a Flight scheduled by&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  any individual whose authority has been revoked by Customer but for whom Customer has failed to notify AirBookOne of such revocation.
              </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Health Screening and Travel History</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;Prior to boarding, the Operator may require each passenger to submit to a temperatur
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  e check or similar health screeening and/or provide certain information regarding the passenger’s health status and recent travel history.&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  Customer is responsible for informing all passengers of any health screening and travel history requirements imposed&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  by the Operator and notified to Customer prior to boarding, and neither AirBookOne nor the Operator will be liable for the Operator’s reasonable refusal to board any passenger who fails to meet any such health screening or travel history requirements.              </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Regu</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>latory Requirements</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;Flights are subject to all applicable laws, rules, regulations, approvals, certifications, and standards in effect which may include, but are not limited to, those promulgated by the FAA and TSA and which now or hereafter may be impos
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ed or required. Changes to Flight and related requirements may be necessary to comply with such laws, rules, regulations, approvals, certifications, and standards.              </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>Passenger Cooperation</strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;You and each passenger traveling with you agree to fully cooperate w
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  ith all instructions and directions given by the flight crew at all times throughout the duration of your Flight. If the Operator and/or flight crew believe, in their&nbsp;</span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                   sole discretion, that the safety of the Flight may be jeopardized in any manner as a result of your or any other passenger’s acts or omissions, the Flight may be terminated or refused to commence without any direct, special, consequential, or incidental liability for loss, injury, damage, or expenses occasioned by such termination or refusal.&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  You agree to fully reimburse AirBookOne and/or the Operator for all costs and expenses incurred in connection with any loss or damage to the aircraft caused by your (or any of your
                  employees’, agents’, or guests’) negligence or misconduct, including&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  where&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  the aircraft requires substantial cleaning services upon completion of your Flight (for example, if extraordinary cleaning expenses are incurred due to your negligence or misconduct).              </span>
              </p>
            </li>
            <li style={{ listStyleType: 'disc' }}>
              <p
                style={{
                  marginLeft: '36pt',
                  textIndent: '-18pt',
                  textAlign: 'justify',
                  lineHeight: 'normal',
                  marginTop: '0pt',
                  marginBottom: '12.000000000000002pt',
                  border: 'none',
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  <u>
                    <strong>
                      Prohibited Items; Weapons and Hazardous Materials
                  </strong>
                  </u>
                </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  . &nbsp;Use and/or transportation&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                  of certain items (such as, but not limited to, TSA-restricted items, medicinal marijuana, and other drugs or substances) on any Flight may be prohibited by the specific Operator.             </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >You agree to fully comply at all times with the Operator’s and flight crew’s policies and instructions regarding use and/or transportation of such items. In addition, you must notify AirBookOne at the time of booking if you wish to transport a firearm or hazmat onboard any Flight. Transport of any firearm or hazmat onboard any Flight is not permitted unless you receive written confirmation from AirBookOne specifying as such. Where permitted, firearms must be transported in accordance with FAA/TSA regulations.&nbsp;
              </span>
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: 16,
                    color: '#000000',
                  }}
                >
                 The passenger must bring an approved trigger lock for each firearm and provide the key to the trigger lock to the Operator’s flight crew for the duration of the Flight. Any failure to follow the Operator’s instructions regarding transport of any firearm, hazmat, or other item may result in Flight termination or cancellation without any refund.
              </span>
              </p>
            </li>
          </ul>
        </ol>
        <p
          style={{
            textIndent: '36pt',
            textAlign: 'justify',
            lineHeight: 'normal',
            marginTop: '0pt',
            marginBottom: '12.000000000000002pt',
            border: 'none',
          }}
        >
          &nbsp;
        </p>
      </>
    </div>
  );
};

export default TermsOfSale;
