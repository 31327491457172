import React, { useState } from "react";
import "./BenefitSummary.scss";
import Button from "../../components/Button";
import tick from "../../assets/icons/list-bullet.png";
import email from "../../assets/icons/mail.png";
import phone from "../../assets/icons/call.png";
import chat from "../../assets/icons/chats.png";

const BenefitSummary = ({ modalHandler }) => {
  const [caModal, setCAModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <div className="benefit-summary">
      <p className="account">FREE ACCOUNT</p>
      <p className="benefit">Benefits</p>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>First Time Users Receive $3,000 in Flight Credits</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>Unlimited Guaranteed Pricing Quotes</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>Proprietary Pricing & Availability Algorithm (Highly Accurate)</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>ONLY: Argus Platinum, Wyvren Wingman & IS-BAO Stage 3</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>24/7 Access to Flight Concierge</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>24/7 Hotline for all International Trips</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>All Flights are Carbon Neutral (Since 2020)</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>100% Customized Trips per request</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>Choose your Aircraft</p>
      </div>
      <div className="benefit-points">
        <img src={tick} alt="" />
        <p>Custom/Self Tailored requests</p>
      </div>
      <p className="message">
        Receive Guaranteed Pricing Quotes by <br />
        setting up a free account.
      </p>
      <div>
        <div className="login-btns">
          <Button
            type="yellow"
            size="large"
            label="Sign In"
            onClick={() => {
              modalHandler("login");
            }}
          />
          <Button
            type="yellow"
            size="large"
            label="Create  Account"
            onClick={() => {
              modalHandler("createAccount");
            }}
          />
        </div>
        <div className="contact-links">
          <a
            className="body-font-p break-body"
            href={"mailto:flightcon@airbookone.com"}
          >
            <img src={email} alt="" />{" "}
          </a>
          <a className="body-font-p break-body" href={"tel:3127615080"}>
            <img src={phone} alt="" />
          </a>
          <a
            className="body-font-p  break-body"
            onClick={() => {
              window.LiveChatWidget.call("maximize");
            }}
            href={"#"}
          >
            <img src={chat} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BenefitSummary;
