import React from 'react';
import ArrowDown from '../../assets/icons/arrow-down.png';
import './LearnMore.scss';

const LearnMore = ({title, arrowDir, onClick}) => {
  return (
    <div className='learn-more-section'>
      <div
        className='learn-more-img'
        // style={{
        //   background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        //     url('${img}')`,
        //   backgroundRepeat: 'no-repeat',
        //   backgroundSize: 'cover',
        // }}
      >
        <div className='learn-more-text'>
          <h4
            className={`${
              arrowDir === 'right' ? 'text-align-left' : 'text-align-center'
            } learn-more-title`}
          >
            {title}
            <img
            onClick={onClick}
            className={`${arrowDir === 'right' ? 'arrow-right' : 'arrow-down'}`}
            src={ArrowDown}
            alt='learn more'
          />    
          </h4>
              
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
