import React, { useState, useEffect } from "react";
import "./EmergencyContactModal.scss";
import Modal from "../Modal";
import Button from "../Button";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { GET_AUTH } from "../../utils/HTTP.utils";
import { API_GET_ADD_EMERGENCY_CONTACT } from "../../middleware/constants/url.constants";
import LoadingOverlay from 'react-loading-overlay';
import Input from "react-phone-number-input/input";
import { isValidPhoneNumber } from 'react-phone-number-input';
function EmergencyContactModal({
  status,
  onChange,
  onNewEmergencyContact,
  bookingId

}) {

  const [loader,setLoader] = useState(false)
  const [state, setState] = useState({
    fname: "",
    lname: "",
    phoneNumber: ""
  });

  const [errorFields, setErrorFields] = useState({
    fname: false,
    lname: false,
    phoneNumber: false,
    invalidphoneNo: false,
    //telCode : false,
  });

  useEffect(() => {
    if(status){
      setLoader(true)
    } 
    getIsUaAuthentication().then(({token})=>{
      GET_AUTH(API_GET_ADD_EMERGENCY_CONTACT,token,{"bookingId":bookingId}).then((data)=>{
        if(data.fname!==null){
          setState(data)
        }
        setLoader(false)
      })
    })
  },[])

  const updateEmergencyContact = async () => {
    try {
      onNewEmergencyContact && onNewEmergencyContact(state);
      setState({
        fname: "",
        lname: "",
        phoneNumber: "",
      });
    } catch (e) {
      console.error(e);
    }
    setState({
      fname: "",
      lname: "",
      phoneNumber: "",
    });
    onChange(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValidOnSubmit()) {

      await updateEmergencyContact();
    }
  };

  const isFormValidOnSubmit = () => {
    console.log(state.phoneNumber)
    const errors = {
      fname: false,
      lname: false,
      phoneNumber : false,
      invalidphoneNo: false,
    };

    if (state.fname === "") {
      errors.fname = true;
    }

    if (state.lname === "") {
      errors.lname = true;
    }

    if (state.phoneNumber ===  undefined || state.phoneNumber === "") {
      errors.phoneNumber = true;
    }
    else {
      console.log(state.phoneNumber)
      if(isValidPhoneNumber(state.phoneNumber)=== false) {
        errors.invalidphoneNo = true;
      }
    }
    
    console.log(errors);
    setErrorFields(errors);
    if ((Object.values(errors).some((error) => error === true))) {

      console.log(errors);


      return false;
    }
    return true;
  };



  const handleChange = (key) => (e) => {
    setState({ ...state, [key]: e.target.value });
  };  
  const handlePhone = e => {
    setState({ ...state, phoneNumber: e });
  }

  const isFieldValid = (e) => {
    const { name, value } = e.target;  
    let errors = false;
  
    if (name === 'fname' && value === "") {
      errors = true;
    }
  
    if (name === 'lname' && value === "") {
      errors = true;
    }
  
    if (name === 'phoneNumber' && value === "") {
      errors = true;
    }
    console.log(name)
    setErrorFields((prev) => ({
      ...prev,
      [name]: errors,
      //invalidEmail: false,
    }));
  };


  return (
    <div className="EmergencyContactModal">
      <Modal close={() => onChange(false)} show={status} preventClose={true}>
        <p className="title">Emergency Contact</p>
        <form onSubmit={onSubmit}>
          <div className="input-container">
            <input
              type="text"
              //required
              name="fname"
              value={state.fname}
              className="full-width-container"
              onChange={handleChange("fname")}
              placeholder={"First Name *"}
              onBlur={(e)=>isFieldValid(e)}

            />
            {errorFields.fname && <p className="dangerr">Firstname is required</p>}

            <input
              type="text"
              //required
              name="lname"
              value={state.lname}
              className="full-width-container"
              onChange={handleChange("lname")}
              placeholder={"Last Name *"}
              onBlur={isFieldValid}

            />
            {errorFields.lname && <p className="dangerr">Lastname is required</p>}

            {/* <input
              type="number"
              required
              value={state.phoneNumber}
              className="full-width-container"
              onChange={handleChange("phoneNumber")}
              placeholder={"Phone Number *"}
            /> */}
            <Input
              //required
              name="phoneNumber"
              defaultCountry="US"
              className={`full-width-container phone-input ${(errorFields.phoneNumber || errorFields.invalidphoneNo) && "errorr"}`}
              placeholder="Phone Number *"
              value={state.phoneNumber ?? ""}
              onChange={handlePhone}
              onBlur={isFieldValid}
            />
          {!errorFields.invalidphoneNo && errorFields.phoneNumber && <p className="dangerr">Phone number is required</p>}
          {!errorFields.phoneNumber && errorFields.invalidphoneNo && (
            <p className="dangerr">Invalid Phone Number</p>
          )}
          </div>
          <Button
            label={"Save Changes"}
            type={"yellow"}
            btnAction={"submit"}
          />
        </form>
      </Modal>
      <LoadingOverlay active={loader} spinner text='Loading your content...'/>
    </div>
  );
}

export default EmergencyContactModal;
