import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import "./Incentive.scss"
import GeneralModal from "../../containers/HomePage/Modal";
import useAuthentication from "../../hooks/useLoginAuthentication";
import { connect, useSelector } from 'react-redux';
import {  HIDE_MODAL } from "../../middleware/constants/auth.constants";

const NotificationBar = ({ loginStatus, modalClose, hideOtherModal}) => {
    let history = useHistory();
    let [offerModal, setOfferModal] = useState(false)
    let timeAfterPopup = 0; //seconds after popup
    let [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const userInfo = useSelector((state) => state.auth.userData);

    const modalLogin =() => {
      hideOtherModal(true)
        setLoginModalOpen(true)
        modalClose()

      }
      const modalLoginClose =() => {
        setLoginModalOpen(false)
        hideOtherModal(false)

      }
    useEffect(() => {
      setIsAuthenticated(loginStatus)
    }, [loginStatus])

    return (
        <>
        <div className="notification" onClick={() => setOfferModal(true)}>
        {!isAuthenticated ?(
        <p className="text" style={{"cursor":"pointer"}}  onClick={()=>modalLogin(true)}><u className='promo-ctr' >WELCOME PROMO:</u> Receive $3,000 in AB1 Rewards</p>
         ):(<p className="text"><u >WELCOME PROMO:</u> Receive $3,000 in AB1 Rewards</p>)} 
            </div>
            {/* <div className="notification" onClick={() => history.push(`/iq-platform`)}>
              
                <p className="text">Learn About Our  <u>IQ Platform</u></p> */}
              
          {/* <a href="https://meetings.hubspot.com/mandrews10/nbaa-conference-meeting" target="_blank"> */}
          

                {/* <ConciergeList
            icon={<img src={Mail} style={{ height: "23px" }} />}
            content={"flightcon@airbookone.com"}
            href={"mailto:flightcon@airbookone.com"}
          /> */}
            {/* </div> */}

            {/* <div className="OfferModal">
            <Modal close={() => setOfferModal(false)} show={offerModal}>
                <img className="icon" src={plane} alt=""/>
                <h2>Receive $3,000 in AB1 Rewards</h2>
                <h6>Sign up and get $1,500 in First Flight Rewards and another $1,500 in Future Flight Rewards </h6>
                <Button
                    label={"Sign Me Up"}
                    type={"white"}
                    onClick={() => {
                            setOfferModal(false)
                            history.push("/signup")
                        }
                    }
                />
                <h6 className="terms">*First Flights’s Trip Costs must be of $20,000+ USD. This offer is valid through August 20, 2021. See AB1 <a href="/terms-of-sale">Terms & Conditions</a></h6>

            </Modal>
        </div> */}

{loginModalOpen === true &&
        <GeneralModal modaltype="createAccount" modalLoginClose={modalLoginClose}/>
      }
           
        </>
    )
}


function mapStateToProps({ auth }) {
    return {
      loginStatus : auth.loginStatus,
      loginUserData : auth.userData,
    };
  }
  function mapDispatchToProps(dispatch) {
    return {
      hideOtherModal : (modalStatus) =>  dispatch({ type: HIDE_MODAL, modalStatus : modalStatus})
    };
  }
  export default connect(mapStateToProps, mapDispatchToProps)( NotificationBar );