import React, { useState } from "react";
import "./CarbonOffset.scss";
import { Popover } from "react-tiny-popover";
import Carbonoffset from "../../assets/icons/carbonoffset.svg";

const CarbonOffset = () => {
  let [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div className="carbon-offset">
      <div className="carbon-head">Carbon Neutrality</div>
      <div className="body-carbon-offset">
        <div className="carbon-logo">
          <img src={Carbonoffset} alt="offsetLogo" />
        </div>

        <div className="carbon-details">
          AIRBOOK ONE ensures every flight book with us is Carbon Neutral.We
          will meet 4Air (www.4air.aero) Bronze level every time you fly.
        </div>

        <Popover
          isOpen={isPopoverOpen}
          positions={["right", "top"]} // preferred positions by priority
          content={
            <div className="popup-carbon-info">
              We support projects all over the world that fund greenhouse gas
              reductions including renewable energy,forestry and
              efficiencies.Every project meets the stringent criteria through
              leading non-profit standards like Gold Standard, Verra, Climate
              Action Reserve and American Carbon Registry.
            </div>
          }
          onClickOutside={() => setIsPopoverOpen(false)}
        >
          <div
            className="carbon-link"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            Info
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default CarbonOffset;
