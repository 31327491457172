import React from 'react';

function ReviewBookingHeader(props) {
  return (
    <div className="ReviewBookingHeader">
      <h1>Review Booking</h1>
    </div>
  );
}

export default ReviewBookingHeader;