import React, { useEffect, useState } from "react";
import ApplicationTab from "../../components/ApplicationTab";
import PlaneCarousel from "../../components/PlaneCarousel";
import Accordions from "../../components/Accordions";
import Button from "../../components/Button";
import CostSummary from "../../components/CostSummary";
import AttentionIcon from "../../assets/icons/attention.png";
import TripCard from "../../components/TripCard";
import PlanInfo from "../../components/PlanInfo";
import FlightAddOns from "../../components/FlightAddOns";
import { capitalizeFirstLetter } from "../../utils/common.utils";
import Modal from "../../components/Modal";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import Gallery from "../../components/Gallery";
import "./BookAFlight.scss";
import { AiFillAppstore } from "react-icons/ai";
import BookAFlightHeader from "./BookAFlightHeader";
import { connect } from "react-redux";
import {
  JET_INFO,
  SET_SELECTED_ADDONS,
  SET_SELECTED_AIRCRAFT,
  UPDATE_ADDONS,
} from "../../middleware/constants/aircarft.constants";
import { updateAddOnsOnMaster } from "./helper";
import PageContainer from "../../components/PageContainer";
import LoadingOverlay from "react-loading-overlay";
import vector from "../../assets/icons/Vector.png";
import { API_GET_CONFIGURATION } from "../../middleware/constants/url.constants";
import { GET_UN_AUTH } from "../../utils/HTTP.utils";
import moment from "moment";
import CarbonOffset from "../../components/CarbonOffset/CarbonOffset";

function BookAFlight({
  getJetInfo,
  searchOptions = {},
  searchResults: data = {},
  history,
  jetInfoData = {},
  setSelectedAircraft,
  selectedAircraft: appData = {},
  setSelectedAddOns,
  selectedAddOns = [],
  updateAddOns,
}) {
  let tabsData = Object.keys(data?.data ?? {})
    .map((a) => {
      return {
        label: data.data[a][0].displayName,
        value: a,
        displayOrder: data.data[a][0].displayOrder,
      };
    })
    .sort((a, b) => a.displayOrder - b.displayOrder);

  let [currentJet, setCurrentJet] = useState("");
  let [disabled, setDisabled] = useState(true);
  let [jetInfo, setJetInfo] = useState({});
  let [modal, setModal] = useState(false);
  let [gallery, setGallery] = useState(false);
  let [modalRecommended, setModalRecommended] = useState(false);
  const [loader, setLoader] = useState(true);
  const [addOns, setAddOns] = useState([]);
  const [config, setConfig] = useState({
    fromDate: "",
    toDate: "",
    message: false,
  });
  const [dateStatus, setDateStatus] = useState(false);

  useEffect(() => {
    getJetInfo();
    GET_UN_AUTH(API_GET_CONFIGURATION, { type: "PEAK_TRAVEL_MESSAGE" }).then(
      (res) => {
        if (!res.error) {
          if (res["details"] && res["details"]["bookingScreen"]) {
            setConfig({
              ...config,
              fromDate: res.details.bookingScreen.fromDate,
              toDate: res.details.bookingScreen.toDate,
              message: res.details.bookingScreen.message,
            });
            if (searchOptions && searchOptions["routeType"] != "MULTI_CITY") {
              let deptTime = moment(
                searchOptions["departureTime"],
                "DD-MM-YYYY HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss");
              let arrTime = moment(
                searchOptions["returnTime"],
                "DD-MM-YYYY HH:mm:ss"
              ).format("YYYY-MM-DD HH:mm:ss");
              if (
                res.details.bookingScreen.fromDate &&
                res.details.bookingScreen.toDate
              ) {
                let fDate = Date.parse(
                  new Date(
                    res.details.bookingScreen.fromDate.replace(/-/g, "/")
                  )
                );
                let lDate = Date.parse(
                  new Date(res.details.bookingScreen.toDate.replace(/-/g, "/"))
                );
                let cDate = Date.parse(new Date(deptTime.replace(/-/g, "/")));
                let checkDate = Date.parse(
                  new Date(arrTime.replace(/-/g, "/"))
                );
                if (
                  (cDate <= lDate && cDate >= fDate) ||
                  (checkDate <= lDate && checkDate >= fDate)
                ) {
                  setDateStatus(true);
                } else {
                  setDateStatus(false);
                }
              } else {
                setDateStatus(false);
              }
            } else {
              if (searchOptions["travelCities"].length > 0) {
                for (let i = 0; i < searchOptions["travelCities"].length; i++) {
                  let deptTime = moment(
                    searchOptions["travelCities"][i]["departureTime"],
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("YYYY-MM-DD HH:mm:ss");
                  if (
                    res.details.bookingScreen.fromDate &&
                    res.details.bookingScreen.toDate
                  ) {
                    let fDate = Date.parse(
                      new Date(
                        res.details.bookingScreen.fromDate.replace(/-/g, "/")
                      )
                    );
                    let lDate = Date.parse(
                      new Date(
                        res.details.bookingScreen.toDate.replace(/-/g, "/")
                      )
                    );
                    let cDate = Date.parse(
                      new Date(deptTime.replace(/-/g, "/"))
                    );
                    if (cDate <= lDate && cDate >= fDate) {
                      setDateStatus(true);
                      break;
                    } else {
                      setDateStatus(false);
                    }
                  } else {
                    setDateStatus(false);
                  }
                }
              } else {
                setDateStatus(false);
              }
            }
          } else {
            setDateStatus(false);
          }
        } else {
          setDateStatus(false);
        }
      }
    );
    setLoader(false);
    window.onbeforeunload = function () {
      return "Data will be lost if you leave the page, are you sure?";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (Object.keys(data || {}).length === 0) history.push("/");
    else if (data.preferredCategory !== null) {
      setDisabled(false);
      setCurrentJet(data.preferredCategory);
      setSelectedAircraft(data.data[data.preferredCategory][0]);
      setSelectedAddOns([]);
    } else {
      if (data.data.TURBO_PROP[0].aboneSearchId === null) setDisabled(true);
      setCurrentJet("TURBO_PROP");
      setSelectedAircraft(data.data.TURBO_PROP[0]);
      setSelectedAddOns([]);
    }
  }, []);

  useEffect(() => {
    setJetInfo(jetInfoData[currentJet]);
  }, [jetInfoData, currentJet]);
  const onTabChange = (key) => {
    setCurrentJet(key);
    data.data[key][0].aboneSearchId === null
      ? setDisabled(true)
      : setDisabled(false);
    setSelectedAircraft(data.data[key][0]);
    setSelectedAddOns([]);
  };
  const onAddOnChange = (addOnId, value) => {
    let final = [];
    if (value) {
      final = [...selectedAddOns, addOnId];
    } else {
      final = selectedAddOns.filter((a) => a !== addOnId);
    }
    let updatedMaster = updateAddOnsOnMaster(appData, final);
    setSelectedAircraft(updatedMaster);
    setSelectedAddOns(final);
    setAddOns(final);
  };

  return (
    <PageContainer>
      <div className="BookAFlight">
        <BookAFlightHeader searchOptions={searchOptions} />
        <ApplicationTab
          tabs={tabsData}
          onTabChange={onTabChange}
          activeTab={data.preferredCategory}
        />
        {!disabled ? (
          <>
            <div className="book-flight-summary">
              <div style={{ marginRight: "30px" }}>
                <div
                  className="plane-meta"
                  style={{
                    justifyContent: `${
                      currentJet === data?.preferredCategory
                        ? "space-between"
                        : "flex-end"
                    }`,
                  }}
                >
                  {currentJet === data?.preferredCategory && (
                    <Button
                      label={"RECOMMENDED"}
                      type={"link-decoration"}
                      fullWidth={false}
                      onClick={() => setModalRecommended(true)}
                    />
                  )}
                  <Button
                    label={`${capitalizeFirstLetter(
                      appData?.dealType?.toLowerCase()
                    )} Deal`}
                    onClick={() => setModal(true)}
                    type={"blue"}
                    fullWidth={false}
                  />
                </div>
                {jetInfo?.webLogos?.length > 0 && (
                  <div className="planeCarousel">
                    <PlaneCarousel slides={[jetInfo?.webLogos[0]] ?? []} />
                    <Button
                      label="View All Photos"
                      icon={<AiFillAppstore />}
                      type={"white"}
                      fullWidth={false}
                      onClick={() => setGallery(true)}
                    />
                  </div>
                )}
              </div>
              {data?.international === false && config.message && dateStatus && (
                <div className="peak-mob">
                  <div className="peak-style">
                    <p className="peak-headline">Peak Travel Alert</p>
                    <div className="header">
                      <img className="icon" src={vector} alt="" />
                      <p className="body-font-p peak-msg">
                        Our Flight Concierge Team will reach-out to you to
                        confirm availability. Your itinerary price is
                        guaranteed.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="amount-containers">
                <CostSummary
                  priceBreakup={appData.priceBreakup}
                  addonIds={addOns}
                />
              </div>
            </div>
            

            <div className="details-container">
              <div className="info-container">
                {data?.international === true &&
                  <div className="peak-web" style={{marginBottom : "75px"}}>
                  <div className="peak-style">
                    <p className="peak-headline">International Travel Alert</p>
                    <div className="header">
                      <img className="icon" src={vector} alt="" />
                      <p className="body-font-p peak-msg">
                        Our Flight Concierge Team will reach-out to you to
                        confirm availability. Your itinerary price is
                        guaranteed.
                      </p>
                    </div>
                  </div>
                </div>
                }
                {data?.international === false && config.message && dateStatus && (
                  <div className="peak-web">
                    <div className="peak-style">
                      <p className="peak-headline">Peak Travel Alert</p>
                      <div className="header">
                        <img className="icon" src={vector} alt="" />
                        <p className="body-font-p peak-msg">
                          Our Flight Concierge Team will reach-out to you to
                          confirm availability. Your itinerary price is
                          guaranteed.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <Accordions title={"Itinerary"} collapse={true}>
                  {appData.itinerary?.legs.map((a, i) => (
                    <TripCard
                      key={i}
                      {...a}
                      itineryCount={i}
                      jetType={appData.aircraftCategory}
                      total={appData.itinerary?.legs.length}
                    />
                  ))}
                </Accordions>
                <Accordions title={"Jet Info"} collapse={false}>
                  {jetInfo && <PlanInfo info={jetInfo} />}
                  {(currentJet === "LIGHT_JET" ||
                    currentJet === "MIDSIZE_JET" ||
                    currentJet === "TURBO_PROP") && (
                    <div className="attention">
                      <img className="attention-logo" src={AttentionIcon} />
                      <h5 className="attention-info">
                        THIS JET TYPE HAS LIMITED GOLF CLUB SPACE
                      </h5>
                    </div>
                  )}
                </Accordions>
                <Accordions title={"Add-Ons"} collapse={false}>
                  {appData.addonResponse?.map((a, i) => (
                    <FlightAddOns onChange={onAddOnChange} {...a} key={i} />
                  ))}
                  <CarbonOffset />
                </Accordions>

                <Accordions title={"Have Questions?"} collapse={false}>
                  <p className="info-text">
                    Have any questions about booking or any other general
                    questions? We are here to help.
                  </p>
                  <Button
                    label={"Contact Us"}
                    onClick={() => history.push("/concierge")}
                  />
                </Accordions>
              </div>
            </div>
            <Modal close={() => setModal(false)} show={modal}>
              <div className="good-deal-modal">
                {jetInfo?.webLogos?.length > 0 && (
                  <img src={jetInfo?.webLogos[0]} alt="" />
                )}

                <p className="plane-type">{appData.displayName}</p>
                <Button
                  label={`${
                    appData.dealType
                  } DEAL ● $${appData.priceBreakup?.totalPrice?.toLocaleString()}`}
                  type={"blue"}
                  size={"large"}
                  fullWidth={false}
                />
                <p className="info-text-recommended">
                  Today’s market average for this itinerary is $
                  {appData?.abonePriceBreakup?.averagePrice?.toLocaleString()}
                  <br />
                  <br />
                  AIRBOOK ONE searches 1,000s of ‘locally sourced’ aircraft real
                  time to determine the actual Daily Market Average.
                </p>
              </div>
            </Modal>
            <Modal
              close={() => setModalRecommended(false)}
              show={modalRecommended}
            >
              <div className="good-deal-modal">
                {jetInfo?.webLogos?.length > 0 && (
                  <img src={jetInfo?.webLogos[0]} alt="" />
                )}
                <p className="info-text-recommended">
                  Recommended trips are based on several factors such as, how
                  much lower the selected trips' pricing is relative to the
                  real-time Daily Market Average, Fastest Trip Time, Customer
                  Reviews of Operator and other similar Key Performance
                  Indicators.
                  <br />
                  <br />
                  AIRBOOK ONE searches 1,000s of ‘locally sourced’ aircraft real
                  time to determine the actual Daily Market Average.
                </p>
              </div>
            </Modal>
          </>
        ) : (
          <div className="disabled-aircraft">
            <div className="book-flight-summary">
              <div style={{ marginRight: "30px" }}>
                {jetInfo?.webLogos?.length > 0 && (
                  <div className="planeCarousel">
                    <PlaneCarousel
                      disabled={true}
                      slides={[jetInfo?.webLogos[0]] ?? []}
                    />
                    <div className="details-container">
                      <div className="info-container">
                        <div className="unavailable">
                          <div className="header">
                            <BsFillExclamationTriangleFill
                              className="icon"
                              color={"#f2f2f2"}
                              size={25}
                            />
                            <p className="title">Unavailable</p>
                          </div>
                          <p className="info-text">
                            Based on your search criteria and availability,
                            there are currently no aircraft in this category.
                          </p>
                          <p className="info-text">
                            Please contact us if you have a specific
                            aircraft/category need for this trip or refine your
                            search.
                          </p>
                        </div>
                        <div className="amount-containers disable-amt-container show-phn-summary">
                          <CostSummary
                            disabled={true}
                            priceBreakup={appData.priceBreakup}
                            addonIds={addOns}
                          />
                        </div>
                        <p className="title-contact">Have Questions?</p>
                        <p className="info-text">
                          Have any questions about booking or any other general
                          questions? We are here to help.
                        </p>
                        <Button
                          label={"Contact Us"}
                          onClick={() => history.push("/concierge")}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="amount-containers disable-amt-container show-main-summary">
                <CostSummary
                  disabled={true}
                  priceBreakup={appData.priceBreakup}
                  addonIds={addOns}
                />
              </div>
            </div>
            {/* <div className="details-container">
              <div className="info-container">
                <div className="unavailable">
                  <div className="header">
                    <BsFillExclamationTriangleFill
                      className="icon"
                      color={"#f2f2f2"}
                      size={25}
                    />
                    <p className="title">Unavailable</p>
                  </div>
                  <p className="info-text">
                    Based on your search criteria and availability, there are
                    currently no aircraft in this category.
                  </p>
                  <p className="info-text">
                    Please contact us if you have a specific aircraft/category
                    need for this trip or refine your search.
                  </p>
                </div>
                <div className="amount-containers disable-amt-container show-phn-summary">
                <CostSummary
                  disabled={true}
                  priceBreakup={appData.priceBreakup}
                />
              </div>
                <p className="title-contact">Have Questions?</p>
                <p className="info-text">
                  Have any questions about booking or any other general
                  questions? We are here to help.
                </p>
                <Button
                  label={"Contact Us"}
                  onClick={() => history.push("/concierge")}
                />
              </div>

            </div> */}
          </div>
        )}
      </div>
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
      <Gallery
        status={gallery}
        onChange={setGallery}
        slides={jetInfo?.webLogos?.slice(1)}
        category={currentJet}
      />
    </PageContainer>
  );
}

function mapStateToProps({ aircraft }) {
  return {
    searchOptions: aircraft.searchOptions,
    searchResults: aircraft.searchResults.data,
    jetInfoData: aircraft.jetInfo.data,
    selectedAircraft: aircraft.selectedAircraft,
    selectedAddOns: aircraft.selectedAddOns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJetInfo: () => dispatch({ type: JET_INFO }),
    setSelectedAircraft: (data) =>
      dispatch({ type: SET_SELECTED_AIRCRAFT, payload: data }),
    setSelectedAddOns: (data) =>
      dispatch({ type: SET_SELECTED_ADDONS, payload: data }),
    updateAddOns: (data) => dispatch({ type: UPDATE_ADDONS, payload: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookAFlight);
