import React from 'react';
import IPhone from '../../assets/iphone-dark.png';
import AppStore from '../../assets/logos/app-store.png';
import PlayStore from '../../assets/logos/google-play.png';
import './DownloadRef.scss';

const DownloadRef = () => {
  return (
    <div>
      <div className="book-app">
        <h1>Easily book with our app.</h1>
        <h5>For faster, easier, and better deals.</h5>
        <div>
          <a href="https://apps.apple.com/us/app/airbook-one/id1553278305">
            <img className="app-img" src={AppStore} alt="app store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.airbookone">
            <img className="app-img" src={PlayStore} alt="google play store" />
          </a>
        </div>
        <img
          src={IPhone}
          className="iphone-img"
          alt="booking through our app"
        />
      </div>
    </div>
  );
};

export default DownloadRef;
