import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {Provider as ReduxProvider} from 'react-redux'
import store from './middleware/store'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(<ReduxProvider store={store}><App/></ReduxProvider>, document.getElementById('root'))
