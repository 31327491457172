import React,{useState} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import { setCookie} from "../../utils/common.utils";
//import GeneralModal from "../../containers/HomePage/Modal";


function ProtectedRoute({Component, ...rest}) {
  let history = useHistory();
  let [AuthStatus,setStatus] = useState(false)
  let [loader,setLoader] = useState(true)

  getIsUaAuthentication().then(({status}) => {
    setStatus(status);
    setLoader(false);
  })
  const redirect = () => {
    let currentPath=history?.location?.pathname;
    setCookie("PrevUrl", currentPath,15)
    history.push("/", {path : rest.path,loginFrom : "protectedPage"});
    
    //return <GeneralModal modaltype="login" />;
  }
  if(!loader){
    return (
      <Route {...rest} render={props => AuthStatus ? <Component {...props} /> : redirect()}/>
    );
  }
  else{
    return(
       null
    );
  }
}

export default ProtectedRoute;
