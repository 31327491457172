import React from 'react';

function MyAccountHeader(props) {
  return (
    <div className="MyAccountHeader">
      <h1>Account</h1>
    </div>
  );
}

export default MyAccountHeader;