import React from 'react';
import PropTypes from "prop-types";
import {BiCircle} from "react-icons/bi";
import {RiMapPinFill} from "react-icons/ri";
import {FaCircle} from "react-icons/fa";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";

import "./MyTripsCard.scss";


function MyTripsCard({data, jetInfoData}) {
  let history = useHistory();
  const TRIP = data.craftDetail?.itinerary.legs[0];
  const DEPART_AIRPORT = TRIP?.departureAirport;
  const ARRIVAL_AIRPORT = TRIP?.arrivalAirport;
  const PLANE_INFO = jetInfoData[data?.craftDetail?.aircraftCategory];
  const deptTime =  moment(
    TRIP.departureDateTime,
    'DD-MM-YYYY HH:mm:ss',
  ).format('h:mm A');
  const arrTime = TRIP.arrivalDateTime!==null? moment(
    TRIP.arrivalDateTime,
    'DD-MM-YYYY HH:mm:ss',
  ).format('h:mm A') : '';
  const deptDate = moment(TRIP.departureDateTime, 'DD-MM-YYYY').format(
    'ddd DD MMM',
  );
  return (
    <div className={`MyTripsCard ${data.status === "RESERVED" ? "active" : ""}`}
         onClick={() => {
           history.push(`/booking-details/${data.bookingId}`)
         }}>
      <div className="header">
        {<p className={`status ${data.status}`}>{data.status}</p>}
      </div>
      {PLANE_INFO?.webLogos.length > 0 && <img src={PLANE_INFO?.webLogos[0]} alt="PLANE_IMAGE"/>}
      <div className="details-container">
        <div className="main-info-container">
          <div className="title-container">
            <p className="booking-id">{data.bookingId}</p> <p
            className="legs">{data.craftDetail?.itinerary.legs.length} Legs</p>
          </div>
          {deptDate.toUpperCase()}
        </div>
        <div className="airport-container">
          <div className="icon-container">
            <BiCircle/>
            <div className="v-line"/>
            <RiMapPinFill/>
          </div>
          <div className="places-container">
            <div className="airport-detail">
              <p className="a-name">{DEPART_AIRPORT.icaoCode}</p>
              <FaCircle className={"dot"} size={6}/>
              <p className="city-name">{DEPART_AIRPORT.city}, {DEPART_AIRPORT.countryCode}</p>
              <p
                className="time">{deptTime}</p>
            </div>
            <div className="airport-detail">
              <p className="a-name">{ARRIVAL_AIRPORT.icaoCode}</p>
              <FaCircle className={"dot"} size={6}/>
              <p className="city-name">{ARRIVAL_AIRPORT.city}, {ARRIVAL_AIRPORT.countryCode}</p>
              <p
                className="time">{arrTime}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

MyTripsCard.propTypes = {
  active: PropTypes.bool,
}

function mapStateToProps({aircraft, payment}) {
  return {
    jetInfoData: aircraft.jetInfo.data,
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTripsCard)


