import {
    UPDATE_AUTH_DATA,
    HIDE_MODAL
  } from "../constants/auth.constants";
  
  const initialState = {
    loginStatus : false,
    userData : {},
    createAccount : false,
    user : {},
    hideModalStatus : false
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_AUTH_DATA: {
        return {...state, loginStatus: action.loginStatus, userData : action.userData, createAccount : action.createAccount,user : action.user}
      }
      case HIDE_MODAL: {
        return {...state, hideModalStatus: action.modalStatus}
      }
      default: {
        return state
      }
    }
  };
  