import React, { useEffect, useState } from "react";
import "./Login.scss";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import LoadingOverlay from "react-loading-overlay";
import SocialMediaLinks from "./SocialMediaLinks.js";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

const ResetPassword = ({ modalHandler, fieldValue }) => {
  const [loader, setLoader] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [codeValueError, setCodeValueError] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [passwordException, setPasswordException] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmErrorPassword, setConfirmErrorPassword] = useState(false);
  const [compareError, setCompareError] = useState(false);

  const isFormValidOnSubmit = () => {
    if (code === "") {
      setCodeValueError(true);
      setCompareError(false);
    }

    if (password === "") {
      setErrorPassword(true);
      setCompareError(false);
    }

    if (confirmPassword === "") {
      setConfirmErrorPassword(true);
      setCompareError(false);
    }
  };

  const isFieldValid = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      if (value === "") {
        setCodeValueError(true);
      } else {
        setCodeValueError(false);
        setCodeError(false);
      }
    }
    if (name === "password") {
      if (value === "") {
        setErrorPassword(true);
        setCompareError(false);
      } else {
        setErrorPassword(false);
      }
    }
    if (name === "confirmPassword") {
      if (value === "") {
        setConfirmErrorPassword(true);
        setCompareError(false);
      } else {
        setConfirmErrorPassword(false);
      }
    }
  };

  const forgotPasswordCode = (e) => {
    e.preventDefault();
    isFormValidOnSubmit();
    let email = fieldValue;
    if (code !== "" || password !== "" || confirmPassword !== "") {
      if (password === confirmPassword) {
        setLoader(true);
        Auth.forgotPasswordSubmit(email, code, password)
          .then(() => {
            Auth.signIn({ username: email, password: password })
              .then((res) => {
                setLoader(false);
                window.location.href = "/";
              })
              .catch((err) => {
                console.log(`Error signing up: ${JSON.stringify(err)}`);
                if (err.code === "UserNotConfirmedException") {
                  setLoader(true);
                  Auth.resendSignUp(email)
                    .then(() => {
                      setLoader(false);
                    })
                    .catch((err) => {
                      console.log(err, "err");
                    });
                }
                setLoader(false);
              });
            setLoader(false);
            setCode("");
            setPassword("");
          })
          .catch((err) => {
          if(err.code === "CodeMismatchException"){
             setCodeError(true)
          }
          if(err.code === "InvalidPasswordException"){
            setPasswordException(true)
         }
            setLoader(false);
          });
      } else {
        setCompareError(true);
      }
    } else {
    }
  };

  return (
    <div>
      <div className="login-root">
        <div className="login-wrapper">
          <LoadingOverlay active={loader} spinner text="Loading...">
            <h1 className="title">Reset</h1>
            <h1 className="title"> Password</h1>

            <form className="login-form forgot-form">
              <InputBox
                name="code"
                type="text"
                placeholder="Code"
                className={`input-grp ${
                  (codeError || codeValueError) && "error"
                }`}
                value={code}
                autoComplete="off"
                onBlur={isFieldValid}
                onChange={(e) => setCode(e.target.value)}
              />
              {codeValueError && <p className="danger">Field required</p>}
              {codeError && <p className="danger">Invalid Code.</p>}
              <InputBox
                type="password"
                name="password"
                placeholder="Enter Password"
                className={`input-grp ${errorPassword && "error"}`}
                value={password}
                autoComplete="off"
                onBlur={isFieldValid}
                onChange={(e) => setPassword(e.target.value)}
              />
              {errorPassword && <p className="danger">Field required</p>}
              <InputBox
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                className={`input-grp ${confirmErrorPassword && "error"}`}
                value={confirmPassword}
                onBlur={isFieldValid}
                autoComplete="off"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {confirmErrorPassword && <p className="danger">Field required</p>}
              {compareError && (
                <p className="danger">
                  Your password and confirmation password do not match
                </p>
              )}
              {passwordException && (
                <p className="danger">
                  Password is not strong enough
                </p>
              )}
              <Button
                btnAction={"submit"}
                label={"Login"}
                type={"yellow"}
                size={"large"}
                onClick={forgotPasswordCode}
              />
            </form>
            <SocialMediaLinks value="Login" />
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
