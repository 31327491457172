export const SET_SEARCH_OPTIONS = "SET_SEARCH_OPTIONS";

export const SEARCH_AIRCRAFT = "SEARCH_AIRCRAFT";
export const SEARCH_AIRCRAFT_SUCCESS = "SEARCH_AIRCRAFT_SUCCESS";
export const SEARCH_AIRCRAFT_ERROR = "SEARCH_AIRCRAFT_ERROR";

export const JET_INFO = "JET_INFO";
export const JET_INFO_SUCCESS = "JET_INFO_SUCCESS";
export const JET_INFO_ERROR = "JET_INFO_ERROR";

export const SET_SELECTED_AIRCRAFT = "SET_SELECTED_AIRCRAFT";
export const SET_SELECTED_ADDONS = "SET_SELECTED_ADDONS";

export const RESET_SEARCH_RESULTS = "RESET_SEARCH_RESULTS";

export const UPDATE_ADDONS = "UPDATE_ADDONS";
export const UPDATE_ADDONS_SUCCESS = "UPDATE_ADDONS_SUCCESS";
export const UPDATE_ADDONS_ERROR = "UPDATE_ADDONS_ERROR";