/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const REACT_APP_STAGE = process.env.REACT_APP_STAGE;

function getBaseUrl(env) {
  switch (env) {
    case "PROD": {
      return 'https://airbookone.com/';
    }
    case "STAGE": {
      return 'https://staging.airbookone.com/';
    }
    default : {
      return 'http://localhost:3000/';
    }
  }
}

const awsmobile = {
  "aws_project_region": "us-east-2",
  "aws_cognito_identity_pool_id": "us-east-2:faca1e51-d066-4de2-91e7-d6eec75e404d",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_NEjnSDPmV",
  "aws_user_pools_web_client_id": "59c6naal0sq84djk2eq56ckg7l",
  "oauth": {
    "domain": "auth.airbookone.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": getBaseUrl(REACT_APP_STAGE),
    "redirectSignOut": getBaseUrl(REACT_APP_STAGE),
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
