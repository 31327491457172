import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import "./CheckBox.scss";

function CheckBox({
                    checked: isSelected,
                    checkable = true,
                    onClick,
                    color = "blue",
                    disabled = false,
                    uncheck = false
}) {
  const [checked, setChecked] = useState(isSelected);
  
  useEffect(() => {
    if (uncheck) {
      setChecked(false)
    }
  },[uncheck])
  
  return (
    <div
      className={`CheckBox styled-checkbox ${checkable ? "checkable" : ""} ${disabled ? "disabled" : ""} ${color}-check`}
      onClick={() => {
        if (!disabled && checkable) {
          setChecked(!checked)
          onClick(!checked)
        }
      }
      }>
      <div className={checked ? "checked" : ""}/>
    </div>
  );
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  checkable: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["yellow", "blue"]),
  disabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  onClick: () => {
  }
}

export default CheckBox;