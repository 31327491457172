import React, {useEffect} from 'react';
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";
import {CarouselProvider, Slide, Slider, ButtonBack, ButtonNext} from 'pure-react-carousel';
import PropTypes from 'prop-types';

import "./PlaneCarousel.scss"

function PlaneCarousel({disabled = false, slides = [], changeState}) {
  useEffect(() => {
  }, [slides])
  return (
    <div className="PlaneCarousel">
      <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={slides.length} infinite={true} currentSlide={0}>
        <Slider>
          {slides.map((a, i) => {
            return(
              <Slide key={i} index={i}><img className={disabled?"image-slide-disabled":"image-slide"} src={a} alt=""/></Slide>
            )
          })}
        </Slider>
        {slides.length>1 &&
          <>
            <ButtonBack onClick={()=>changeState(-1)}><BsChevronLeft color={"white"} size={35}/></ButtonBack>
            <ButtonNext onClick={()=>changeState(1)}><BsChevronRight color={"white"} size={35}/></ButtonNext>
          </>
        }
      </CarouselProvider>
    </div>
  );
}

PlaneCarousel.propTypes = {
  slides: PropTypes.array.isRequired,
}

export default PlaneCarousel;