import React,{useState,useEffect} from 'react';
import PlaneCarousel from "../PlaneCarousel";
import Modal from "../Modal";
import './Gallery.scss'

const Details = {
    TURBO_PROP:[
        {head:"King Air C90",body:"*Image is one example of a Turbo-Prop jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"King Air 350i",body:"*Image is one example of a Turbo-Prop jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"King Air 200",body:"*Image is one example of a Turbo-Prop jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"}
    ],
    LIGHT_JET:[        
        {head:"Phenom 300",body:"*Image is one example of a Light jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Learjet 45",body:"*Image is one example of a Light jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Citation CJ525",body:"*Image is one example of a Light jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"}
    ],
    MIDSIZE_JET:[
        {head:"Citation XLS",body:"*Image is one example of a Midsize jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Citation Excel",body:"*Image is one example of a Midsize jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Hawker 900XP",body:"*Image is one example of a Midsize jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"}
    ],
    SUPER_MIDSIZE_JET:[
        {head:"Falcon 2000",body:"*Image is one example of a Super Midsize jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Citation X",body:"*Image is one example of a Super Midsize jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Challenger 300",body:"*Image is one example of a Super Midsize jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"}
    ],
    LARGE_JET:[
        {head:"Gulfstream V",body:"*Image is one example of a Large jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Global Express",body:"*Image is one example of a Large jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"},
        {head:"Challenger 605",body:"*Image is one example of a Large jet available by booking through AIRBOOK ONE; actual booked aircraft may differ"}
    ]
    }

const Gallery = ({status, onChange, slides,category}) => {
    const [state, setState] = useState(0)
    const changeState = (change) =>{
        if(change<0){
            setState((state+change+slides.length)%(slides.length))
        }
        else{
            setState((state+change)%(slides.length))
        }
    }
    useEffect(()=>{
        setState(0)
    },[])

    return(
        <div className="Gallery">
             <Modal gallery={true} close={() => onChange(false)} show={status}>
             <PlaneCarousel
                slides={
                    slides ?? []
                }
                changeState={changeState}
            />
            {Details[category] &&
                <div className="PlaneDetails">
                    <h4>{Details[category][state]?.head || ""}</h4>
                    <p>{Details[category][state]?.body || ""}</p>
                </div>
            }
             </Modal>
        </div>
    )
}

export default Gallery