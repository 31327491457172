import React, {useEffect, useState} from 'react';
import Accordions from "../../components/Accordions";
import TripCard from "../../components/TripCard";
import {JET_INFO} from "../../middleware/constants/aircarft.constants";
import {connect} from "react-redux";
import PlanInfo from "../../components/PlanInfo";
import FlightAddOns from "../../components/FlightAddOns";
import Button from "../../components/Button";
import PlaneCarousel from "../../components/PlaneCarousel";
import Gallery from "../../components/Gallery";
import WiringInfoModal from "../../components/WiringInfoModal";
import PayWithCreditCard from "../../components/PayWithCreditCard";
import EmptyIcon from "../../assets/icons/empty_icon.png"
import AttentionIcon from "../../assets/icons/attention.png"
import {useHistory} from "react-router-dom";
import { AiFillFileText } from "react-icons/ai";
import { AiFillAppstore } from "react-icons/ai";

function DetailsPanel({
                        jetInfoData,
                        data = {},
                        id,
                        getRefresh,
                        initPayment
                      }) {
  
  let history = useHistory();
  let addOnEmpty = false;
  let addOnListEmptyStatus = [];
  const [wireModal, setWireModal] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  // const handleAddonEmpty = () =>{
  //   let AddonEmptyCounter = 0;
  //   data.craftDetail?.addonResponse?.map((a, i) => {
  //     let lengthOfAddons = 0;
  //     a.addonList.forEach(addon =>{
  //       if(!addon.selected) {
  //         lengthOfAddons = lengthOfAddons+1;
  //         }
  //       }
  //     );
  //     const condition = lengthOfAddons==a.addonList.length;
  //     if(condition){
  //       addOnListEmptyStatus[i] = true
  //       AddonEmptyCounter = AddonEmptyCounter+1;
  //     }
  //   })
  //   if(AddonEmptyCounter===data.craftDetail?.addonResponse.length){
  //     addOnEmpty=true
  //   }
  // }
  // handleAddonEmpty();
  return (
    <div className="DetailsPanel">
      {data.status === "RESERVED" && (
        <div className="payment-instruction">
          <div className="info-container">
            <p className="pre-label">PAYMENT PENDING</p>
            <p className="label">
              {data.infoText || "Complete payment in 23 hours"}
            </p>
          </div>
          <div className="action-container">
            <Button
              label={"View wiring instructions"}
              onClick={() => setWireModal(true)}
              fullWidth={false}
              type={"blue"}
            />
            <Button
              label={"Pay now with credit card"}
              onClick={() => setPaymentModal(true)}
              fullWidth={false}
              type={"black"}
            />
          </div>
        </div>
      )}
      {jetInfoData?.[data?.craftDetail?.aircraftCategory]?.webLogos?.length >
        0 && (
          <div className="planeCarousel">
        <PlaneCarousel
          slides={
            [jetInfoData?.[data?.craftDetail?.aircraftCategory].webLogos[0]] ?? []
          }
        />
          <Button
            label="View All Photos"
            icon={<AiFillAppstore/>}
            type={'white'}
            fullWidth={false}
            onClick={()=>setGallery(true)}
          />
        </div>
      )}
      <div className="details-container">
        <Accordions title={"Itinerary"} collapse={true}>
          {data.craftDetail?.itinerary?.legs.map((a, i) => (
            <TripCard
              key={i} 
              edit={false}
              {...a}
              total={data.craftDetail?.itinerary?.legs.length}
              jetType={data.craftDetail?.aircraftCategory}
            />
          ))}
          <Button
          label={"View Full itinerary"}
          icon={<AiFillFileText/>}
          onClick={() => history.push("/itinerary/"+id)}
        />
        </Accordions>
        <Accordions title={"Jet Info"} collapse={true}>
          {jetInfoData[data.craftDetail?.aircraftCategory] && (
            <PlanInfo info={jetInfoData[data.craftDetail?.aircraftCategory]} />
          )}
          {(data.craftDetail?.aircraftCategory === "LIGHT_JET" ||
            data.craftDetail?.aircraftCategory === "MIDSIZE_JET" ||
            data.craftDetail?.aircraftCategory === "TURBO_PROP") && (
            <div className="attention">
              <img className="attention-logo" src={AttentionIcon} />
              <h5 className="attention-info">
                THIS JET TYPE HAS LIMITED GOLF CLUB SPACE
              </h5>
            </div>
          )}
        </Accordions>
        {/* {!addOnEmpty && ( */}
          <Accordions title={"Add-Ons"} collapse={true}>
            {data.craftDetail?.addonResponse?.map((a, i) => {
                return <FlightAddOns getRefresh={getRefresh} checkable={false} {...a} key={i} />;
            })}
          </Accordions>
        {/* )} */}
        {/* {addOnEmpty && (
          <Accordions title={"Add-Ons"} collapse={true}>
            <div className="FlightAddOns">
              <div className="Empty">
                <img src={EmptyIcon} />
                <h3>No Addons Chosen</h3>
              </div>
            </div>
          </Accordions>
        )} */}
        <Accordions title={"Have Questions?"} collapse={true}>
          <p className="info-text">
            Have any questions about booking or any other general questions? We
            are here to help.
          </p>
        </Accordions>
        <Button
          label={"Contact Us"}
          onClick={() => history.push("/concierge")}
        />
      </div>
      <WiringInfoModal
        status={wireModal}
        dueAmount={data?.dueAmountDetails?.dueAmount}
        onChange={setWireModal}
      />
      <PayWithCreditCard
        status={paymentModal}
        onChange={setPaymentModal}
        bookingId={data?.bookingId ?? ""}
      />
      <Gallery
        status={gallery}
        onChange={setGallery}
        slides={jetInfoData?.[data?.craftDetail?.aircraftCategory]?.webLogos.slice(1)}
        category={data?.craftDetail?.aircraftCategory}
      />
    </div>
  );
}

function mapStateToProps({aircraft, payment}) {
  return {
    jetInfoData: aircraft.jetInfo.data,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getJetInfo: () => dispatch({type: JET_INFO}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPanel)