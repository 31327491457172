import React, {useEffect, useState} from 'react';
import {GET_AUTH} from "../../utils/HTTP.utils";
import {API_GET_BOOKING_RECEIPT} from "../../middleware/constants/url.constants";
import useAuthentication from "../../hooks/useAuthentication";
import {AiFillDollarCircle} from "react-icons/ai";
import {capitalizeFirstLetter} from "../../utils/common.utils";
import AppleIconMark from "../../assets/icons/Apple_Pay_Mark.svg";
import CreditCardMark from "../../assets/icons/CreditCardMark.svg";
import ApplePayMark from "../../assets/icons/ApplePayMark.svg";

const getPaymentIcon = (paymentMethod) => {
  switch (paymentMethod) {
    case "WALLET_PAY": {
      return <img src={AppleIconMark} alt="AppleIconMark" className="option-icon"/>;
    }
    case "CREDIT_CARD": {
      return <img src={CreditCardMark} alt="CreditCardMark" className="option-icon"/>;
    }
    case "APPLE_PAY": {
      return <img src={AppleIconMark} alt="AppleIconMark" className="option-icon"/>;
    }
    case "WIRE_TRANSFER": {
      return <img src={ApplePayMark} alt="AppleIconMark" className="option-icon"/>;
    }
    case "ACH": {
      return "";
    }
    default: {
      return "";
    }
  }
}

function ReceiptPanel({id, status}) {
  const [state, setState] = useState({});
  let {token} = useAuthentication();
  useEffect(() => {
    token && GET_AUTH(API_GET_BOOKING_RECEIPT, token, {"booking-id": id}).then(setState)
  }, [token]);
  return (
    <div className="ReceiptPanel">
      <h2>Receipts</h2>
      {status === "RESERVED" && <div className="key-value">
        <div className="key">
          <p className="title big">Paid Amount</p>
        </div>
        <p className="value">0</p>
      </div>}
      {status !== "RESERVED" && <>
        <div className="key-value">
          <div className="key">
            <p className="title">Total</p>
          </div>
          <p className="value">${state.totalPrice?.toLocaleString()}</p>
        </div>
        <div className="key-value">
          <div className="key">
            <p className="title small">Your Trip</p>
            <p className="sub-title"><AiFillDollarCircle color="#00BBA0" size={20}/> You will earn
              ${state.priceBreakup?.rewardsInfo?.toLocaleString()} in AB1 rewards</p>
          </div>
          <p className="value">${state.priceBreakup?.aircraftPrice?.toLocaleString()}</p>
        </div>
        {/*<div className="key-value">*/}
        {/*  <div className="key">*/}
        {/*    <p className="title small">Insurance</p>*/}
        {/*  </div>*/}
        {/*  <p className="value">${state.priceBreakup?.insurance || 0}</p>*/}
        {/*</div>*/}
        <div className="key-value">
          <div className="key">
            <p className="title small">Taxes</p>
          </div>
          <p className="value">${state.priceBreakup?.taxes?.toLocaleString()}</p>
        </div>
        {state?.paidPaymentDetails?.map((a, i) => <div className="key-value">
          <div className="key">
            <p className="title big">Payment Method</p>
            <p className="sub-title big">
              {getPaymentIcon(a.paymentMethod)}
              {capitalizeFirstLetter(a.paymentMethod.replace("_", " ").toLowerCase())}
            </p>
          </div>
          <p className="value">${state.totalPrice?.toLocaleString()}</p>
        </div>)}
      </>}
    </div>
  );
}

export default ReceiptPanel;
