import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { Auth } from "aws-amplify";
import Modal from "../../components/Modal";
import Login from "./Login";
import "./Login.scss";
import { toast } from "react-toastify";
import SocialMediaLinks from "./SocialMediaLinks.js";

function CreatePassword({ modalHandler, fieldValue, close }) {
  const [loader, setLoader] = useState(false);
  //const [enterPassword, setEnterPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmErrorPassword, setConfirmErrorPassword] = useState(false);
  const [compareError, setCompareError] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [modal, setModal] = useState(false);

  const handleClose = () => {
    setModal(false);
  };

  const isFieldValid = (e) => {
    const { name, value } = e.target;
  
    if (name === 'password' ) {
      if(value === ""){
        setErrorPassword(true)
        setCompareError(false);
        
      }else{
        setErrorPassword(false)
      }
    }
    if (name === 'confirmPassword' ) {
      if(value === ""){
        setConfirmErrorPassword(true)
        setCompareError(false);
      }else{
        setConfirmErrorPassword(false)
      }
    }
  }

  const saveDatas = () => {
    setEmail(fieldValue.email);
    setLoader(true);

   const phone = fieldValue.phoneNumber;
   
   console.log(phone); // return false;

   //const phone =`${fieldValue.telCode}${fieldValue.phoneNumber}`

   //const phone = fieldValue.phoneNumber;
    Auth.signUp({
      username: fieldValue.email,
      password,
      attributes: {
        email,
        given_name: fieldValue.firstName,
        family_name: fieldValue.lastName,
        phone_number: phone,
      },
    })

      .then((response) => {
        // close();
        //if (noPassword === false) {
        setModal(true);
        // modalHandler("confirmEmail");
        // }
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
        toast.error(err.message);
        modalHandler("login");
      });
  };

  const confirmSignUpCode = (e) => {
    e.preventDefault();
    if (code !== "") {
      setLoader(true);
      Auth.confirmSignUp(email, code)
        .then((response) => {
          setModal(false);
          setLoader(false);
          // history.push("/login", { success: true });
          modalHandler("login");
        })
        .catch((err) => {
          setLoader(false);
          console.log(err)
          toast.error(err.message);
          //close()
          modalHandler("login");
        });
    } else {
      toast.error("Please type the code.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorPassword(false);
    setConfirmErrorPassword(false);
    setCompareError(false);
    if (password === "") {
      setErrorPassword(true);
    }
    if (confirmPassword === "") {
      setConfirmErrorPassword(true);
      setCompareError(false);
    }

    if (password !== "" && confirmPassword !== "") {
      console.log("setCompareError",compareError)
      console.log("ifff")
      if (password !== confirmPassword) {
        console.log("setCompareErrorifffffff",compareError)
        setCompareError(true);
      } else {
        saveDatas();
      }
    }
  };
  return (
    <div className="login-root">
      <div className="login-wrapper">
      <LoadingOverlay active={loader} spinner text="Loading...">
        <h1 className="title">Create</h1>
        <h1 className="title"> Password</h1>
        <form className="login-form">
          <InputBox
            type="password"
            name="password"
            placeholder="Enter Password"
            className={`input-grp ${errorPassword && "error"}`}
            value={password}
            autoComplete="off" 
            onBlur={isFieldValid}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorPassword && <p className="danger">Field required</p>}
          <InputBox
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            className={`input-grp ${confirmErrorPassword && "error"}`}
            value={confirmPassword}
            onBlur={isFieldValid}
            autoComplete="off" 
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {confirmErrorPassword && <p className="danger">Field required</p>}
          {compareError && (
            <p className="danger">
              Your password and confirmation password do not match
            </p>
          )}
           <div style={{ marginTop: '20px' }}>
          <Button
            btnAction={"submit"}
            label={"Login"}
            type={"yellow"}
            size={"large"}
            onClick={(e) => handleSubmit(e)}
          />
          </div>
        </form>
        <SocialMediaLinks value="Register" fieldValue={fieldValue} />
        <div className="confirm-mail">
          <Modal show={modal} close={handleClose}>
            <p>A confirmation code has been sent to your email {email}</p>
            <form>
              <InputBox
                theme={"light"}
                type="text"
                title="Please type the Code"
                placeholder="Verification Code"
                className="input-grp"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <Button
                btnAction={"submit"}
                label={"Confirm"}
                type={"yellow"}
                size={"small"}
                onClick={(e) => confirmSignUpCode(e)}
              />
            </form>
          </Modal>
        </div>
      </LoadingOverlay>
      </div>
    </div>
  );
}

export default CreatePassword;
