import React, {useEffect, useState} from 'react';
import {getCardType} from '../../utils/common.utils';
import './CreditCardDetails.scss';

function CreditCardDetails({setData, data, reset = false, setReset}) {
  const [ccNumber, setCCNumber] = useState('');
  const [ccExpiry, setCCExpiry] = useState('');

  useEffect(() => {
    setData({ number: "", name: "", expiry: "", ccv: "" });
  },[])

  useEffect(() => {
    setData({
      ...data,
      number: parseInt(ccNumber.split('-').join('')),
    });
  }, [ccNumber]);

  useEffect(() => {
    if (reset) {
      setData({ number: "", name: "", expiry: "", ccv: "" });
      setCCNumber('')
      setCCExpiry('')
      setReset(false);
    }
  }, [reset]);

  const handleClick = (key) => (e) => {
    if (key === 'number') {
      let ccNo = e.target.value.split('-').join('');
      if (ccNo.length > 4) {
        let res = [];
        var output = '';
        if (getCardType(ccNo) === 'AMEX') {
          res.push(ccNo.substr(0, 4));
          res.push(ccNo.substr(4, 6));
          if (ccNo.length > 10) res.push(ccNo.substr(10, 5));
          output = res.join('-');
        } else {
          for (let i = 0; i <= 12; i = i + 4) {
            let temp = ccNo.substr(i, 4);
            if (temp !== '') res.push(temp);
            output = res.join('-');
          }
        }
        setCCNumber(output);
      } else {
        setCCNumber(e.target.value);
      }
    } else if (key === 'expiry') {
      let ccExp = e.target.value.split('/').join('');
      if (ccExp.length > 2) {
        let res = [];
        for (let i = 0; i <= ccExp.length; i = i + 2) {
          var exp = '';
          let temp = ccExp.substr(i, 2);
          if (temp !== '') res.push(temp);
          exp = res.join('/');
          setCCExpiry(exp);
        }
      } else {
        setCCExpiry(e.target.value);
      }
      setData({...data, [key]: e.target.value});
    } else {
      setData({...data, [key]: e.target.value});
    }
  };
  return (
    <div className="CreditCardDetails">
      <p className="title">Payment information</p>
      <input
        type="text"
        value={data.name}
        className="cc-input"
        onChange={handleClick("name")}
        placeholder="Name on Card*"
      />
      <input
        type="text"
        className="cc-input"
        onChange={handleClick("number")}
        value={ccNumber}
        placeholder="Card Number*"
      />
      <div className="flex-container">
        <div className="input-container">
          <input
            type="text"
            className="cc-input"
            onChange={handleClick("expiry")}
            value={ccExpiry}
            placeholder="Exp Date*"
            maxLength={5}
            size={5}
          />
          <p className="sub-label">Format: MM/YY</p>
        </div>
        <div className="input-container">
          <input
            type="password"
            value={data.ccv}
            className="cc-input"
            onChange={handleClick("ccv")}
            placeholder="CVV*"
            maxLength={getCardType(ccNumber) === "AMEX" ? 4 : 3}
            size={getCardType(ccNumber) === "AMEX" ? 4 : 3}
          />
        </div>
      </div>
    </div>
  );
}

export default CreditCardDetails;
