import React from 'react';
import PropTypes from 'prop-types';
import "./LightTextPanel.scss"

LightTextPanel.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  icon: PropTypes.node,
  size: PropTypes.oneOf(["large", "small"])
};

function LightTextPanel({title, body, icon, size = "large"}) {
  return (
    <div className="LightTextPanel">
      {icon}
      <p className={`title ${size}-size`}>{title}</p>
      <p className="body">{body}</p>
    </div>
  );
}

export default LightTextPanel;