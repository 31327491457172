import React, { useEffect, useState } from "react";
import MyTripsHeader from "./MyTripsHeader";
import ApplicationTab from "../../components/ApplicationTab";
import MyTripsCard from "../../components/MyTripsCard";
import useAuthentication, {
  getIsUaAuthentication,
} from "../../hooks/useAuthentication";
import { GET_AUTH } from "../../utils/HTTP.utils";
import { API_GET_TRIPS } from "../../middleware/constants/url.constants";
import EmptyIcon from "../../assets/icons/empty_icon.png";

import "./MyTrips.scss";
import LoadingOverlay from "react-loading-overlay";

const TABS = [
  { label: "Upcoming", value: "UPCOMING" },
  { label: "Past", value: "PAST" },
];

function MyTrips({}) {
  const [loader, setLoader] = useState(true);

  const [state, setState] = useState({
    response: [],
    data: [],
    currentTab: TABS[0].value,
  });

  useEffect(() => {
    getIsUaAuthentication().then(({ token }) => {
      GET_AUTH(API_GET_TRIPS, token, {}).then((data) => {
        if (data.error) {
          setLoader(false);
        } else {
          setState({
            ...state,
            response: data,
            data: data.filter(({ tripStatus }) => tripStatus === TABS[0].value),
          });
          setLoader(false);
        }
      });
    });
  }, []);

  const onTabChange = (value) => {
    setState({
      ...state,
      data: state.response.filter(({ tripStatus }) => tripStatus === value),
    });
  };
  return (
    <div className="MyTrips">
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
      <MyTripsHeader />
      <ApplicationTab
        tabs={TABS}
        onTabChange={onTabChange}
        activeTab={TABS[0].value}
      />
      <div className="body-wrapper">
        <div className="body">
          {state.data.map((a, i) => (
            <MyTripsCard key={i} data={a} />
          ))}
          {state.data.length === 0 && (
            <div className="Empty">
              <img src={EmptyIcon} />
              <h3>No trips to display</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyTrips;
