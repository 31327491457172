import React, { useEffect, useState } from "react";
import "./ItineraryLeg.scss";
import EmptyIcon from "../../assets/icons/empty_icon.png";
import moment from "moment";
const lookup = require('country-code-lookup')

function ItineraryLeg({ data, status, tailNumber }) {
  const [isDesktop, setDesktop] = useState(true);
  const [crewData, setCrewData] = useState([]);

  const arrTime = data.arrivalDateTime !==null? moment(
    data.arrivalDateTime,
    "dddd DD/MM/YYYY hh:mm A"
  ).format("dddd MM/DD/YYYY hh:mm A") : '';

  const deptTime = data.departureDateTime!== null ? moment(
    data.departureDateTime,
    "dddd MM-DD-YYYY hh:mm A"
  ).format("dddd MM/DD/YYYY hh:mm A") : '';

  function camelize(str) {
    const strOrgin = [];
    if (str) {
      const strVal = str.toLowerCase(str);
      const strArray = strVal.split('_');
      for (let i = 0; i < strArray.length; i++) {
        if (strArray[i]) {
          strOrgin.push(strArray[i].trim());
        }
      }
      return strOrgin
        .map(a => a.trim())
        .map(a => a[0].toUpperCase() + a.substring(1))
        .join("  ")
    } else {
      return ' ';
    }

  }

  function initCamelize(str) {
    const strOrgin = [];
    if (str) {
      const strVal = str.toLowerCase(str);
      const strArray = strVal.split(' ');
      for (let i = 0; i < strArray.length; i++) {
        if (strArray[i]) {
          strOrgin.push(strArray[i].trim());
        }
      }
      return strOrgin
        .map(a => a.trim())
        .map(a => a[0].toUpperCase() + a.substring(1))
        .join("  ")
    } else {
      return ' ';
    }

  }

  function splitCrewName(str) {
    if (str) {
      const strArray = str.split(' ');
      let strVal;
      for (let i = 0; i < strArray.length; i++) {
        if (i === 0) {
          strVal = strArray[i];
        } else {
          strVal = strVal + '\n' + strArray[i];
        }
      }
      return strVal;
    } else {
      return 'N/A';
    }

  }


  function getCountryCode(country) {
    if (country) {
      const countrySelected = lookup.byCountry(country);
      if (countrySelected) {
        return countrySelected.iso3;
      } else {
        return 'N/A'
      }
    } else {
      return 'N/A'
    }

  }

  let leadPassenger = ""
  if (status) {
    leadPassenger = status.id;
  }
  let tailNumbers = ""
  if (tailNumber === null || tailNumber === "" || tailNumber === undefined) {
    tailNumbers = "N/A"
  } else {
    tailNumbers = tailNumber
  }
  useEffect(() => {
    setTimeout(function () {
      setCrewData(data.crews)
    }, 1000)

    const updateMedia = () => {
      if (window.innerWidth > 550) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [window.innerWidth]);

  return (
    <div className="leg-info">
      <div className="header-container">
        <div className="trip-leg-header">
          <h1 className="leg-number">LEG #{data.legOrder}</h1>
          <div>
            <h4 className="category">Booked Aircraft</h4>
            <h4 className="category">Category: {data.aircraftCategory}</h4>
          </div>
        </div>
      </div>
      {isDesktop && (
        <div className="info">
          <div className="flight-info-1">
            <div className="departure">
              <p className="Heading">Departure</p>
              <div className="place">
                <p className="code">{data.departureAirport.icaoCode}</p>
                <p className="display">
                  <span title={data.departureAirport.displayName}>
                    {data.departureAirport.displayName}
                  </span>
                </p>
              </div>
              {deptTime !== '' ?<div className="date-time">{deptTime.toUpperCase() }</div>: ''}
            </div>
            <div className="destination">
              <p className="Heading">Destination</p>
              <div className="place">
                <p className="code">{data.arrivalAirport.icaoCode}</p>
                <p className="display">
                  <span title={data.arrivalAirport.displayName}>
                    {data.arrivalAirport.displayName}
                  </span>
                </p>
              </div>
              {arrTime !== '' ?<div className="date-time">{arrTime.toUpperCase() }</div>: ''}
            </div>
            <div className="Duration">
              <p className="Heading">Duration</p>
              <p className="time">{data.flightTime}</p>
            </div>
            <div className="TailNumber">
              <p className="Heading">Tail #</p>
              <p className="number">{data.tailNumber || tailNumbers}</p>
            </div>
          </div>
          <div className="flight-info-2">
            <div className="FBO">
              <p className="Heading">*FBO</p>
              <p className="airport">
                {data.fboDetails?.originFbo?.name || ""}
              </p>
              <p className="fbo-text">
                {data.fboDetails?.originFbo?.address || ""}
                <br />
                {data.fboDetails?.originFbo?.contact || ""}
              </p>
            </div>
            <div className="FBO">
              <p className="airport-2">
                {data.fboDetails?.destinationFbo?.name || ""}
              </p>
              <p className="fbo-text">
                {data.fboDetails?.destinationFbo?.address || ""}
                <br />
                {data.fboDetails?.destinationFbo?.contact || ""}
              </p>
            </div>
            <div className="crew-container">
              <div className="crew">
                <p className="head head-1">Crew</p>
                <p className="head-mid">Position</p>
                <p className="head head-3">Cell Phone</p>
              </div>
              {crewData.map((crew, i) => {

                return (
                  <div className="crew" key={i}>
                    <p className="value-1">{splitCrewName(crew.name)}</p>
                    <p className="value-2">{crew.position || "N/A"}</p>
                    <p className="value-3">{crew.contact || "N/A"}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Passengers-itinerary">
            <h5>PASSENGERS</h5>
            <table>
              <thead>
                <tr>
                  <th className="number"></th>
                  <th className="start">First Name/MI</th>
                  <th>Last Name</th>
                  <th>DOB</th>
                  <th>Passport #</th>
                  <th>Expiration</th>
                  <th className="end">Country Issued</th>
                </tr>
              </thead>
              <tbody>

                {data.travellers.map((traveller, i) => {
                  return (
                    <tr key={i}>
                      <td className="number">{i + 1}</td>
                      {leadPassenger == traveller.id ?
                        <td className="start">
                          {!traveller.firstName
                            ? "N/A"
                            : initCamelize(traveller.firstName)}
                          {
                            !traveller.middleName
                              ? " "
                              : "/" + initCamelize(traveller.middleName)}
                          <br></br>
                          {"(Lead Passenger)"}
                        </td>
                        :
                        <td className="start">
                          {!traveller.firstName
                            ? "N/A"
                            : initCamelize(traveller.firstName)}
                          {!traveller.middleName
                            ? " "
                            : "/" + initCamelize(traveller.middleName)}
                        </td>

                      }


                      <td>{initCamelize(traveller.lastName) || "N/A"}</td>
                      <td>{traveller.dob || "N/A"}</td>
                      <td>{traveller.passportNumber || "N/A"}</td>
                      <td>{traveller.passportExpiry || "N/A"}</td>
                      <td>
                      {getCountryCode(traveller.passportCountry)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {data.travellers.length === 0 && (
              <div className="Empty">
                <img src={EmptyIcon} />
                <h3>No Passengers on the leg</h3>
              </div>
            )}
          </div>
          <div className="Additional">
            <h5>ADDITIONAL SERVICES</h5>

            {data.selectedAddOns.map((p) =>
              p.disabled === false ? (
                <div className="info-box">
                  <div>
                    <div className="addon-sub">
                      {camelize(p.addOnEntity.addonType)}{" "}
                    </div>
                    <div className="addon-sub-symbol">-</div>
                    <div>{p.addOnEntity.displayName}</div>
                  </div>
                </div>
              ) : (
                ""
              )
            )}
          </div>
          {data.groundTransportationNotes ? (
            <div>
              <p className="info-text note-text">Ground Transportation Notes</p>
              <textarea
                value={data.groundTransportationNotes}
                name="groundTransportationNotes"
                className="notes-style"
                readOnly
              ></textarea>
            </div>
          ) : (
            ""
          )}
          {data.cateringNotes ? (
            <div>
              <p className="info-text note-text">Catering Notes</p>
              <textarea
                value={data.cateringNotes}
                name="cateringNotes"
                className="notes-style"
                readOnly
              ></textarea>
            </div>
          ) : (
            ""
          )}
          {data.miscellaneousNotes ? (
            <div>
              <p className="info-text note-text">Miscellaneous Notes</p>
              <textarea
                value={data.miscellaneousNotes}
                name="miscellaneousNotes"
                className="notes-style"
                readOnly
              ></textarea>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {!isDesktop && (
        <div className="info-phone">
          <p className="mobile-header">Departure</p>
          <div className="travel-container">
            <div className="departure">
              <div className="place">
                <p className="code">{data.departureAirport.icaoCode}</p>
                <p className="display">
                  <span title={data.departureAirport.displayName}>
                    {data.departureAirport.displayName}
                  </span>
                </p>
              </div>
              <div className="date-time">{deptTime.toUpperCase()}</div>
            </div>
            <div className="FBO">
              <p className="Heading">*FBO</p>
              <p className="airport">
                {data.fboDetails?.originFbo?.name || ""}
              </p>
              <p className="fbo-text">
                {data.fboDetails?.originFbo?.address || ""}
                <br />
                {data.fboDetails?.originFbo?.contact || ""}
              </p>
            </div>
          </div>
          <p className="mobile-header">Destination</p>
          <div className="travel-container">
            <div className="destination">
              <div className="place">
                <p className="code">{data.arrivalAirport.icaoCode}</p>
                <p className="display">
                  <span title={data.arrivalAirport.displayName}>
                    {data.arrivalAirport.displayName}
                  </span>
                </p>
              </div>
              <div className="date-time">{arrTime.toUpperCase()}</div>
            </div>
            <div className="FBO">
              <p className="airport-2">
                {data.fboDetails?.destinationFbo?.name || ""}
              </p>
              <p className="fbo-text">
                {data.fboDetails?.destinationFbo?.address || ""}
                <br />
                {data.fboDetails?.destinationFbo?.contact || ""}
              </p>
            </div>
          </div>
          <p className="mobile-header">Duration</p>
          <div className="info-container">
            <p className="time">{data.flightTime}</p>
          </div>
          <p className="mobile-header">Tail #</p>
          <div className="info-container">
            <p className="number">{data.tailNumber || tailNumbers}</p>
          </div>
          <p className="mobile-header">Crew</p>
          <div className="table-container">
            {crewData.map((crew, i) => {
              return (
                <div className="crew" key={i}>
                  <div className="row">
                    <p className="key">Name</p>
                    <p>{splitCrewName(crew.name)}</p>
                  </div>
                  <div className="row">
                    <p className="key">Position</p>
                    <p>{crew.position}</p>
                  </div>
                  <div className="row">
                    <p className="key">Phone</p>
                    <p>{crew.contact}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {data.travellers.map((traveller, i) => {
            return (
              <div key={i}>
                <p className="mobile-header">Passenger {i + 1}</p>
                <div className="table-container">

                  {leadPassenger == traveller.id ?
                    <div className="row">
                      <p className="key">First Name/MI </p>
                      <p>
                        {traveller.firstName === null ||
                          traveller.middleName === null
                          ? "N/A"
                          : traveller.firstName + "/" + traveller.middleName}
                      </p>
                      <p>(Lead Passenger)</p>
                    </div>
                    :
                    <div className="row">
                      <p className="key">First Name/MI </p>
                      <p>
                        {traveller.firstName === null ||
                          traveller.middleName === null
                          ? "N/A"
                          : traveller.firstName + "/" + traveller.middleName}
                      </p>
                    </div>}
                  <div className="row">
                    <p className="key">Last Name</p>
                    <p>{traveller.lastName || "N/A"}</p>
                  </div>
                  <div className="row">
                    <p className="key">DOB</p>
                    <p>{traveller.dob || "N/A"}</p>
                  </div>
                  <div className="row">
                    <p className="key">Passport #</p>
                    <p>{traveller.passportNumber || "N/A"}</p>
                  </div>
                  <div className="row">
                    <p className="key">Expiration</p>
                    <p>{traveller.passportExpiry || "N/A"}</p>
                  </div>
                  <div className="row">
                    <p className="key">Country Issued</p>
                    <p>{getCountryCode(traveller.passportCountry)}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {data.travellers.length === 0 && (
            <div>
              <p className="mobile-header">Passengers</p>
              <div className="travel-container">
                <div className="Empty">
                  <img src={EmptyIcon} />
                  <h3>No Passengers on the leg</h3>
                </div>
              </div>
            </div>
          )}
          <p className="mobile-header">Additonal Services</p>

          <div>
            {data.selectedAddOns.map((p) =>
              p.disabled === false ? (
                <div>
                  <div className="addon-sub">
                    {camelize(p.addOnEntity.addonType)}{" "}
                  </div>
                  <div className="addon-sub-symbol">-</div>
                  <div>{p.addOnEntity.displayName}</div>
                </div>
              ) : (
                ""
              )
            )}
          </div>
          {data.groundTransportationNotes ? (
            <div>
              <p className="info-text mobile-header">
                Ground Transportation Notes
              </p>
              <div className="info-container">
                <textarea
                  value={data.groundTransportationNotes}
                  name="groundTransportationNotes"
                  className="mob-notes-style"
                  readOnly
                ></textarea>
              </div>
            </div>
          ) : (
            ""
          )}
          {data.cateringNotes ? (
            <div>
              <p className="info-text mobile-header">Catering Notes</p>
              <div className="info-container">
                <textarea
                  value={data.cateringNotes}
                  name="cateringNotes"
                  className="mob-notes-style"
                  readOnly
                ></textarea>
              </div>
            </div>
          ) : (
            ""
          )}
          {data.miscellaneousNotes ? (
            <div>
              <p className="info-text mobile-header">Miscellaneous Notes</p>
              <div className="info-container">
                <textarea
                  value={data.miscellaneousNotes}
                  name="miscellaneousNotes"
                  className="mob-notes-style"
                  readOnly
                ></textarea>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default ItineraryLeg;
