import React from 'react';
import "./WiringInfoModal.scss";
import Modal from "../Modal";
import Button from "../Button";
import {useHistory} from "react-router-dom";

function WiringInfoModal({status, onChange, dueAmount = 0 }) {
  let history = useHistory();
  return (
    <div className="WiringInfoModal">
      <Modal close={() => onChange(false)} show={status}>
        <p className="title">Wire Details</p>
        {dueAmount > 0 && (
          <p className="info">
            Please complete payment by wire transfer within the next 24 hours.
            We’ve also emailed you these instructions.{" "}
          </p>
        )}
        {dueAmount === 0 && <p></p>}
        <div className="account-info">
          {dueAmount > 0 && (
            <div className="tile">
              <p className="label">Amount due</p>
              <p className="value">${dueAmount.toLocaleString()}</p>
            </div>
          )}
          <div className="tile">
            <p className="label">Bank</p>
            <p className="value">First American Bank</p>
          </div>
          <div className="tile">
            <p className="label">Routing Number</p>
            <p className="value">071922777</p>
          </div>
          <div className="tile">
            <p className="label">Account Number</p>
            <p className="value">11985462102</p>
          </div>
        </div>
        <Button
          label={"Contact Us"}
          type={"black"}
          onClick={() => history.push("/concierge")}
        />
      </Modal>
    </div>
  );
}

export default WiringInfoModal;
