import React from 'react';
import './ServiceCard.scss';

const ServiceCard = ({image, title, reference, html = false}) => {
  return (
    <div className='service-block'>
      <img src={image} alt={title}/>
      <h4>{title}</h4>
      {!html && <p>{reference}</p>}
      {html && <p dangerouslySetInnerHTML={{__html:reference}}/>}
    </div>
  );
}
;

export default ServiceCard;
