import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {BsPlusCircleFill} from "react-icons/bs";
import {IoMdClose} from "react-icons/io";
import "./Accordions.scss";

function Accordions({title, collapse = false, children}) {
  const [state, setState] = useState(collapse);
  return (
    <div className="Accordions">
      <div className="head">
        <h3 className="headline-h3">{title}</h3>
        <div className="icon" onClick={() => setState(!state)}>
          {!state && <BsPlusCircleFill size={30}/>}
          {state && <IoMdClose size={30}/>}
        </div>
      </div>
      <div className={`body ${state ? "active" : ""}`}>
        {children}
      </div>
    </div>
  );
}

Accordions.propTypes = {
  title: PropTypes.string.isRequired,
  collapse: PropTypes.bool,
  children: PropTypes.node,
}

export default Accordions;