import React from 'react';
import {Link} from 'react-router-dom';
import './GenTempImageView.scss';

const GenTempImageView = ({
                            img1,
                            img2,
                            title1,
                            title2,
                            subtitle1,
                            subtitle2,
                            link1=null,
                            link2=null,
                          }) => {
  return (
    <div>
      <div className="gen-temp-left" id="HowItWorks">
        <img src={img1} alt={title1} />
        <div className="content">
          <h2>{title1}</h2>
          <p>{subtitle1}</p>
          {link1 && 
          <Link to={link1}>
            <button>Learn More</button>
          </Link>
          }
        </div>
      </div>
      <div className="gen-temp-right" id="WhyFlyPrivate">
        <div className="content">
          <h2>{title2}</h2>
          <p>{subtitle2}</p>
          {link2 &&
          <Link to={link2}>
            <button>Learn More</button>
          </Link>
          }
        </div>
        <img src={img2} alt={title2} />
      </div>
    </div>
  );
};

export default GenTempImageView;
