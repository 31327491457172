import { v4 as uuidv4 } from 'uuid';

const SESSION_ID = "air-book-session-id";

export const setSessionID = () => {

  let existing = getSessionID();
  if (!existing || existing === null || existing === undefined) {
    const newSessionID = uuidv4();
    window.sessionStorage.setItem(SESSION_ID, newSessionID);
    return newSessionID;
  }

}

export function getSessionID () {
  let sessionId = window.sessionStorage.getItem(SESSION_ID);
  return sessionId;
};

