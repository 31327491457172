const {REACT_APP_STAGE, REACT_APP_STAGE_URL, REACT_APP_PROD_URL, REACT_APP_DEV_URL} = process.env;
export const BASE_URL = getBaseUrl(REACT_APP_STAGE);
export const API_SEARCH_AIRPORT = "airport/search";
export const API_SEARCH_AIRCRAFT = "aircraft/search-results-v2";
export const API_JET_INFO = "content?componentId=jet_info&platform=APP";
export const API_UPDATE_ADDONS = "book-aircraft/update-addons";
export const API_DELETE_PASSENGER = "address-book/remove-passenger";
export const API_GET_TRIPS = "trips/all";
export const API_GET_BOOKING_DETAILS ="book-aircraft/details";
export const API_GET_BOOKING_RECEIPT = "payment/receipt";
export const API_GET_BANK_DETAILS = "payment/pending-amount-details";
export const API_INITIATE_PAYMENT = "payment/initiate";
export const API_PROCESS_TOKEN = "payment/web-process-token";
export const API_GET_WALLET_SUMMERY = "wallet/wallet-history";
export const API_REWARD_HISTORY = "rewards/reward-history";
export const API_GET_ALL_TRAVELLERS = "address-book/all";
export const API_POST_WALLET_PAY = "payment/wallet-pay";
export const API_POST_ADD_TRAVELLER = "address-book/add-traveller";
export const API_POST_ADD_EMERGENCY_CONTACT = "address-book/emergency-contact";
export const API_GET_ADD_EMERGENCY_CONTACT = "address-book/emergency-contact";
export const API_POST_ASSIGN_TRAVELLER = "address-book/assign-traveller";
export const API_POST_CHANGE_TRAVELLER_LEG = "address-book/change-traveller-leg";
export const API_POST_ADD_LEAD_PASSENGER = "address-book/add-lead-passenger";
export const API_GET_TRAVELLER_BOOKING_DETAILS = "address-book/traveller-booking-details";
export const API_PROFILE = "profile/details";
export const API_GET_TRIP_ITINERARY_DETAILS = "trips/itinerary";
export const API_GET_AIRCRAFT_MODELS = "craft-categories/all";
export const API_GET_USER_CRAFT_PREF = "user/pref/get";
export const API_UPDATE_USER_CRAFT_PREF = "user/pref/update";
export const API_GET_NOTIFICATIONS_PREFERENCES = "device/communication-status";
export const API_POST_NOTIFICATIONS_PREFERENCES = "device/enable-communication";
export const API_GET_APPLE_MERCHANT_SESSION = "payment/apple-merchant-session";
export const API_POST_VENDOR_LIMOLINK = "vendor/limo/submit";
export const API_GET_CONFIGURATION ="public/configurations";
export const API_PROFILE_DELETE = "profile/user/delete";


function getBaseUrl(env) {
  switch (env) {
    case "PROD": {
      return REACT_APP_PROD_URL;
    }
    case "STAGE": {
      return REACT_APP_STAGE_URL;
    }
    default : {
      return REACT_APP_DEV_URL;
    }
  }
}
