import React, { useState } from "react";
import "./Login.scss";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import LoadingOverlay from "react-loading-overlay";
import SocialMediaLinks from "./SocialMediaLinks.js";
import Modal from '../../components/Modal';
import { Auth } from 'aws-amplify';
import {toast} from 'react-toastify';

const ForgotPassword = ({ modalHandler }) => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [resetLimitError, setResetLimitError] = useState(false);
  const [emailValueError, setEmailValueError] = useState(false);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [reconfirmModal, setReconfirmModal] = useState(false);
  const [RMcode, setRMCode] = useState('');

  const handleRMClose = () => {
    setReconfirmModal(false);
  };

  const confirmSignUpCode = (e) => {
    e.preventDefault();
    if (RMcode !== '') {
      setLoader(true);
      Auth.confirmSignUp(email, RMcode)
        .then(() => {
          setReconfirmModal(false);
          setLoader(false);
          //toast.success('Awesome! Successfully Verified.');
          Auth.forgotPassword(email)
            .then(() => {
              //setModal(true);
              modalHandler("resetPassword", email);
              setLoader(false);
            })
            .catch((err) => {
              console.error(err);
              toast.error(err.message);
            });
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
         toast.error(err.message);
        });
    } else {
      toast.error('Please type the code sent to your email.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   // setModal(true);
      setCode('');
      setPassword('');
      if (email !== '') {
        setLoader(true);
        Auth.forgotPassword(email)
          .then(() => {
            //setModal(true);
            console.log(email,"email")
            modalHandler("resetPassword", email);
            setLoader(false);
          })
          .catch((err) => {
            console.error(err);
            if(err.code === 'UserNotFoundException'){
              setEmailError(false)
              setEmailValueError(true)
              setLoader(false);
            }
            else if (
              err.code === 'UserNotConfirmedException' ||
              err.code === 'InvalidParameterException'
            ) {
              setLoader(true);
              Auth.resendSignUp(email)
                .then(() => {
                  setReconfirmModal(true);
                  setLoader(false);
                })
                .catch((err) => {
                  console.error(err);
                  setEmailValueError(true)
                  //toast.error(err.message);
                  setLoader(false);
                });
            } else {
             // toast.error(err.message);
              setLoader(false);
            }
            // if(err.message === "Username/client id combination not found."){
            //   setEmailError(true)
            // }
          });
      } else {
       setEmailError(true)
      }
    };


  const isFieldValid = (e) => {
    const { name, value } = e.target;
      if(value === ""){
        setEmailError(true)
        setResetLimitError(false)
        setEmailValueError(false)
      }else{
        setEmailError(false)
      }
 
  }

  return (
    <div>
    <div className="login-root">
      <div className="login-wrapper">
        <LoadingOverlay active={loader} spinner text="Loading...">
          <h1 className="title">Forgot</h1>
          <h1 className="title"> Password</h1>

          <form className="login-form forgot-form">
            <InputBox
              type="email"
              name="email"
              placeholder="Email"
              className={`input-grp ${emailError && "error"}`}
              value={email}
              onBlur= {isFieldValid}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
            />
            { emailError&& <p className="danger">Field required</p>}
            {emailValueError && (
              <p className="danger">
                We don’t have an account with that username.
              </p>
            )}
            {resetLimitError && (
              <p className="danger">
                Attempt limit exceeded, please try after some time.
              </p>
            )}

            <Button
              btnAction={"submit"}
              label={"Submit"}
              type={"yellow"}
              size={"large"}
              onClick={handleSubmit}
            />
          </form>
          <SocialMediaLinks value="Login" />
        </LoadingOverlay>
      </div>

    </div>
    <div className="confirm-mail">
    <Modal show={reconfirmModal} close={handleRMClose}>
        <div>
          <p>
            Before resetting your password, your account needs reverification.
            Please type the verification code sent to {email}
          </p>
          <form>
            <InputBox
              theme={'light'}
              type='text'
              placeholder='Verification Code'
              className='input-grp'
              value={RMcode}
              onChange={(e) => setRMCode(e.target.value)}
            />
            <Button
              btnAction={'submit'}
              label={'Confirm'}
              type={'yellow'}
              size={'small'}
              onClick={(e) => confirmSignUpCode(e)}
            />
          </form>
        </div>
      </Modal>
      </div>
      {/* <div className="confirm-mail">
    <Modal show={modal} close={handleClose}>
        <div>
          <p className="modal-heading">A confirmation code has been sent to
           your email {email}</p>
          <form>
            <InputBox
              type='text'
              title='Please type the Code'
              placeholder='Verification Code'
              className='input-grp'
              value={code}
              onChange={(e) => setCode(e.target.value)}
              theme={'light'}
            />
            <InputBox
              type='password'
              title='Please enter new Password'
              placeholder='New Password'
              className='input-grp'
              theme={'light'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
             <InputBox
              type='password'
              title='Please re-enter new Password'
              placeholder='Re-Enter Password'
              className='input-grp'
              theme={'light'}
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              btnAction={'submit'}
              label={'Confirm'}
              type={'yellow'}
              size={'small'}
              onClick={(e) => forgotPasswordCode(e)}
            />
          </form>
        </div>
      </Modal>
      </div> */}
  </div>
  );
};

export default ForgotPassword;
