export const footerSectionLinks = [
  {
    title: 'About',
    links: [
      {
        itemName: 'about us',
        href: '/about_us',
      },
      {
        itemName: "how it works",
        href: "/how_it_works",
      },
      {
        itemName: 'safety ratings',
        href: '/safety-ratings',
      },
      {
        itemName: "Popular Routes",
        href: "/popular-routes"
      },
      {
        itemName: 'Jet Info',
        href: '/jet-info',
      },
      {
        itemName: "why private",
        href: "/#WhyFlyPrivate",
        external: true
      },
       // {
      //   itemName: 'accessibility',
      //   href: '',
      // },
      // {
      //   itemName: 'careers',
      //   href: '',
      // },
    ],
  },
  {
    title: 'Support',
    links: [
      // {
      //   itemName: 'receipts and refunds',
      //   href: '',
      // },
      {
        itemName: 'faq',
        href: '/faq',
      },
      // {
      //   itemName: 'site map',
      //   href: '',
      // },
   
      {
        itemName: 'contact us',
        href: '/concierge',
      },
      {
        itemName: 'cancellation policy',
        href: '/terms-of-sale#CANCELLATION-AND-REFUND-POLICY',
        external: true,
      },
      {
        itemName: "testimonials",
        href: "/testimonials"
      },
    ],
  },
  {
    title: 'More',
    links: [
   
      {
        itemName: 'privacy policy',
        href: '/privacy-policy',
      },
      {
        itemName: 'terms of sale',
        href: '/terms-of-sale',
      },
      {
        itemName: 'terms of service',
        href: '/terms-of-service',
      },
      {
        itemName: 'diversity & inclusion',
        href: '/diversity_inclusion',
      },
     
    ],
  },
];

export const navAuthLinks = [
  {
    name: "book",
    route: "/",
  },
  {
    name: "aircraft",
    route: "/aircraft"
  },
  // {
  //   name: "testimonials",
  //   route: "/testimonials"
  // },
  {
    name: "my trips",
    route: "/my-trips",
  },
  {
    name: "concierge",
    route: "/concierge",
  },
  // {
  //   name: "why private",
  //   route: "/#WhyFlyPrivate",
  // },
  // {
  //   name: "how it works",
  //   route: "/how_it_works",
  // },

  {
    name: "our apps",
    route: "/#downloads",
  },

  {
    name: "for operators",
    route: "/iq-platform",
  },
];

export const navUnauthLinks = [
  {
    name: "book",
    route: "/",
  },
  {
    name: "aircraft",
    route: "/aircraft"
  },
  // {
  //   name: "testimonials",
  //   route: "/testimonials"
  // },
  {
    name: "concierge",
    route: "/concierge",
  },
  // {
  //   name: "why private",
  //   route: "/#WhyFlyPrivate",
  // },
  // {
  //   name: "how it works",
  //   route: "/how_it_works",
  // },
  
  // {
  //   name: "iq platform",
  //   route: "/iq-platform",
  // },

  {
    name: "our apps",
    route: "/#downloads",
  },

  {
    name: "for operators",
    route: "/iq-platform",
  },

];

export const navMobileUnauthLinks = [
  {
    name: "book",
    route: "/",
  },
  {
    name: "aircraft",
    route: "/aircraft"
  },
  // {
  //   name: "testimonials",
  //   route: "/testimonials"
  // },
  {
    name: "how it works",
    route: "/how_it_works",
  },
  {
    name: "concierge",
    route: "/concierge",
  },

  // {
  //   name: "iq platform",
  //   route: "/iq-platform",
  // },
  // {
  //   name: "why private",
  //   route: "/#WhyFlyPrivate",
  // },

  {
    name: "our apps",
    route: "/#downloads",
  },
  {
    name: "for operators",
    route: "/iq-platform",
  },

  // {
  //   name: "sign up",
  //   route: "/create-account",
  // },


];

export const navMobileAuthLinks = [
  {
    name: "book",
    route: "/",
  },
  {
    name: "aircraft",
    route: "/aircraft"
  },
  // {
  //   name: "testimonials",
  //   route: "/testimonials"
  // },
  {
    name: "my trips",
    route: "/my-trips",
  },
  // {
  //   name: "why private",
  //   route: "/#WhyFlyPrivate",
  // },
  {
    name: "concierge",
    route: "/concierge",
  },
  // {
  //   name: "how it works",
  //   route: "/how_it_works",
  // },

  // {
  //   name: "iq platform",
  //   route: "/iq-platform",
  // },

  {
    name: "our apps",
    route: "/#downloads",
  },

  {
    name: "for operators",
    route: "/iq-platform",
  },
];
