import React from 'react';
import PropTypes from 'prop-types';
import {FaCircle} from "react-icons/fa";

AirportMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number
};

function AirportMarker({selected, data, onSelect}) {
  return (
    <div className={`AirportMarker ${data.icaoCode === selected.icaoCode && "active"}`}
         onClick={() => onSelect(data)}
         style={{position: "absolute", top: "-100px"}}>
      <div className="info-container">
        <div className="airport-code">{data.icaoCode}</div>
        <div className="airport-name">{data.airportName.substring(0, 25)}</div>
      </div>
      <div className="marker">
        <FaCircle color={"white"} size={15}/>
      </div>
    </div>
  );
}

export default AirportMarker;